import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface FrameDuartionState {
  hour: string;
  min: string;
  sec: string;
}

interface FrameInfoState {
  frameId: string;
  frameDuration: FrameDuartionState;
  fixYn: string;
  frameNoYn: string;
  primaryYn: string;
  naviYn: string;
  hideYn: string;
  autoNextYn: string;
  frameOrder: string;
  playlistId: string;
  frameNm: string;
}

interface FrameState {
  frameList: FrameInfoState[];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

// 초기 상태
const FrameInitialState: FrameState = {
  frameList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'frame',
  initialState: FrameInitialState,
  reducers: {
    stateClear(state: FrameState) {
      state.frameList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    setFrameList(state: FrameState, { payload: { frameList } }: PayloadAction<FrameState>) {
      state.frameList = frameList;
    },
  },
});

const selectFrameList = createDraftSafeSelector(
  (state: FrameState) => state.frameList,
  frameList => frameList,
);

const selectStatus = createDraftSafeSelector(
  (state: DeviceState) => state.actionResult,
  (state: FrameState) => state.isLoading,
  (state: FrameState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const frameSelector = {
  frameList: state => selectFrameList(state[FRAME]),
  status: state => selectStatus(state[FRAME]),
};

export const FRAME = slice.name;
export const frameReducer = slice.reducer;
export const frameAction = slice.actions;
