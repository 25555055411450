import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as spaceApiLib from '../lib/spaceApi';
import {spaceAction} from "./spaceSlice";

function* spaceList( { payload } ) {

    try {
      const spaceList = yield call(spaceApiLib.getSpaceLists, payload);

      yield put(spaceAction.spaceListSuccess({ spaceList }));
    } catch (err) {
      yield put(spaceAction.spaceListFailure('space List Error'));
    }
  }

  export function* watchSpaceList() {
    yield takeLatest(spaceAction.spaceList, spaceList);
  }

  function* rootSaga() {
    yield all([fork(watchSpaceList)]);
  }

  export default rootSaga;
