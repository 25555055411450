import * as apiLib from './api';

export const list = async ({ spaceId, customerType }) => {
  try {
    const data = {
      spaceId: spaceId,
      customerType: customerType,
    };

    const result = await apiLib.fetchStoreApi('/customer/list', data);
    if (result.resultFlag) {
      return result.customerList;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ customerId }) => {
  try {
    const data = {
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/customer/detail', data);
    if (result.resultFlag) {
      return result.customerInfo;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({
  spaceId,
  customerId,
  customerType,
  customerNo,
  customerNm,
  rfidList,
}) => {
  try {
    const data = {
      spaceId: spaceId,
      customerType: customerType,
      customerNo: customerNo,
      customerNm: customerNm,
      rfidList: rfidList,
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/customer/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({
  customerId,
  customerNo,
  customerNm,
  rfidList,
}) => {
  try {
    const data = {
      customerId: customerId,
      customerNo: customerNo,
      customerNm: customerNm,
      rfidList: rfidList,
    };

    const result = await apiLib.fetchStoreApi('/customer/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ customerIdList }) => {
  try {
    const data = {
      customerIdList,
    };

    const result = await apiLib.fetchStoreApi('/customer/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const update = async ({ customerId, updateInfo }) => {
  try {
    const data = {
      customerId,
      updateInfo
    };

    const result = await apiLib.fetchStoreApi('/customer/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('customer error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};