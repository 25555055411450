import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as goodsOptionApiLib from '../lib/goodsOptionApi';

import { goodsOptionAction } from './goodsOptionSlice';
import { shopSelector } from './shopSlice';

function* optionList({ payload: brandId }) {
  try {
    const optionList = yield call(goodsOptionApiLib.optionList, brandId);

    yield put(goodsOptionAction.optionListSuccess({ optionList }));
  } catch (err) {
    yield put(goodsOptionAction.optionListFailure('Option List Error'));
  }
}

function* optionInfo({ payload: optionId }) {
  try {
    const optionInfo = yield call(goodsOptionApiLib.optionInfo, optionId);

    yield put(goodsOptionAction.optionInfoSuccess({ optionInfo }));
  } catch (error) {
    yield put(goodsOptionAction.optionInfoFailure('Option Info Error'));
  }
}

function* optionAdd({ payload }) {
  try {
    yield call(goodsOptionApiLib.optionAdd, payload);

    yield put(goodsOptionAction.optionAddSuccess());
  } catch (err) {
    yield put(goodsOptionAction.optionAddFailure('Option Add Error'));
  }
}

function* optionUpdate({ payload }) {
  try {
    const shopInfo = yield select(shopSelector.shopInfo);
    const brandId = { brandId: shopInfo.brandId };

    yield call(goodsOptionApiLib.optionUpdate, payload);

    const optionList = yield call(goodsOptionApiLib.optionList, brandId);

    yield put(goodsOptionAction.optionUpdateSuccess({ optionList }));
  } catch (err) {
    yield put(goodsOptionAction.optionUpdateFailure('Option Update Error'));
  }
}

function* optionRemove({ payload }) {
  try {
    const shopInfo = yield select(shopSelector.shopInfo);
    const brandId = { brandId: shopInfo.brandId };

    yield call(goodsOptionApiLib.optionRemove, payload);

    const optionList = yield call(goodsOptionApiLib.optionList, brandId);

    yield put(goodsOptionAction.optionRemoveSuccess({ optionList }));
  } catch (err) {
    yield put(goodsOptionAction.optionRemoveFailure('Option Remove Error'));
  }
}

function* optionReorder({ payload: { updateInfoList }}) {
  try {
    yield call(goodsOptionApiLib.optionReorder, { updateInfoList });

    yield put(goodsOptionAction.optionReorderSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsOptionAction.optionReorderFailure('Goods ReOrder Error'));
  }
}

export function* watchOptionList() {
  yield takeLatest(goodsOptionAction.optionList, optionList);
}

export function* watchOptionInfo() {
  yield takeLatest(goodsOptionAction.optionInfo, optionInfo);
}

export function* watchOptionAdd() {
  yield takeLatest(goodsOptionAction.optionAdd, optionAdd);
}

export function* watchOptionUpdate() {
  yield takeLatest(goodsOptionAction.optionUpdate, optionUpdate);
}

export function* watchOptionRemove() {
  yield takeLatest(goodsOptionAction.optionRemove, optionRemove);
}

export function* watchCategoryReorder() {
  yield takeLatest(goodsOptionAction.optionReorder, optionReorder);
}

function* rootSaga() {
  yield all([
    fork(watchOptionList),
    fork(watchOptionInfo),
    fork(watchOptionAdd),
    fork(watchOptionUpdate),
    fork(watchOptionRemove),
    fork(watchCategoryReorder),
  ]);
}

export default rootSaga;
