import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/overlay-contents/list', data);
    if (result.resultFlag) {
      return result.overlayContentsList;
    } else {
      throw Object.assign(new Error('overlayContents list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/overlay-contents/detail', data);
    if (result.resultFlag) {
      return result.overlayContentsInfo;
    } else {
      throw Object.assign(new Error('overlayContents detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/overlay-contents/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('overlayContents add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/overlay-contents/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('overlayContents edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
