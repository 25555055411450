import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { customerAction } from './customerSlice';

import * as customerApiLib from '../lib/customerApi';
import * as customerPaymentApiLib from '../lib/customerPaymentApi';
import { shopSelector } from './shopSlice';

function* customerList({ payload }) {
  try {
    const customerList = yield call(customerApiLib.list, payload);

    yield put(customerAction.customerListSuccess({ customerList }));
  } catch (err) {
    yield put(customerAction.customerListFailure('Customer List Error'));
  }
}

function* customerInfo({ payload }) {
  try {
    const customerInfo = yield call(customerApiLib.detail, payload);

    yield put(customerAction.customerInfoSuccess({ customerInfo }));
  } catch (err) {
    yield put(customerAction.customerInfoFailure('Customer Info Error'));
  }
}

function* customerSave({ payload }) {
  try {
    const shopInfo = yield select(shopSelector.shopInfo);
    yield call(customerApiLib.edit, payload);

    yield call(customerPaymentApiLib.edit, { ...payload, paymentKeyList: payload.rfidList, spaceId: shopInfo.spaceId });

    yield put(customerAction.customerSaveSuccess());
  } catch (err) {
    yield put(customerAction.customerSaveFailure('Customer Save Error'));
  }
}

function* customerAdd({ payload }) {
  try {
    yield call(customerApiLib.add, payload);

    for (const paymentKey of payload.rfidList) {
      yield call(customerPaymentApiLib.add, { ...payload, paymentKey });
    }

    yield put(customerAction.customerAddSuccess());
  } catch (err) {
    yield put(customerAction.customerAddFailure('Customer Add Error'));
  }
}

function* customerRemove({ payload }) {
  try {
    const shopInfo = yield select(shopSelector.shopInfo);
    yield call(customerPaymentApiLib.remove, payload);

    yield call(customerApiLib.remove, payload);

    const customerList = yield call(customerApiLib.list, { spaceId: shopInfo.spaceId, customerType: 'GUEST' });

    yield put(customerAction.customerRemoveSuccess({ customerList }));
  } catch (err) {
    yield put(customerAction.customerRemoveFailure('Customer Remove Error'));
  }
}

function* customerUpdate({ payload }) {
  try {
    yield call(customerApiLib.update, payload);

    yield put(customerAction.customerUpdateSuccess());
  } catch (err) {
    yield put(customerAction.customerUpdateFailure('Customer Update Error'));
  }
}

export function* watchCustomerList() {
  yield takeLatest(customerAction.customerList, customerList);
}

export function* watchCustomerInfo() {
  yield takeLatest(customerAction.customerInfo, customerInfo);
}

export function* watchCustomerSave() {
  yield takeLatest(customerAction.customerSave, customerSave);
}

export function* watchCustomerAdd() {
  yield takeLatest(customerAction.customerAdd, customerAdd);
}

export function* watchCustomerRemove() {
  yield takeLatest(customerAction.customerRemove, customerRemove);
}

export function* watchCustomerUpdate() {
  yield takeLatest(customerAction.customerUpdate, customerUpdate);
}

function* rootSaga() {
  yield all([
    fork(watchCustomerList),
    fork(watchCustomerInfo),
    fork(watchCustomerSave),
    fork(watchCustomerAdd),
    fork(watchCustomerRemove),
    fork(watchCustomerUpdate),
  ]);
}

export default rootSaga;
