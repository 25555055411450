import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const SubTab = ({ tabList }) => {
  const navigate = useNavigate();

  return (
    <Box>
      {tabList.map((tap, i) => (
        <Tab
          key={i}
          isSelected={tap.isSelected}
          onClick={e => navigate(tap.url)}
        >
          {tap.title}
        </Tab>
      ))}
    </Box>
  );
};

const Box = styled.div`
  margin-left: auto;
  display: flex;
  gap: 10px;
`;

const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  background-color: #ffffff;
  border-radius: 5px;
  width: 69px;
  border: 0;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #2a91df;
      border: 1px solid #dddddd;
    `}
`;

export default React.memo(SubTab);
