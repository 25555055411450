import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import * as excelFileApiLib from '../lib/excelFileApi';

import {excelFileAction} from "./excelFileSlice";
import { arraySlice } from '../utils/commonUtils';
import { MANUAL_PROGRESS_STATE } from '../constants/types';

function* excelFileSave({ payload }) {
  try {

    //yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.DATA_SETTING });
    const result = yield call(excelFileApiLib.getExcelData, {filePath:payload.filePath, excelFileId: payload.excelFileId});

    const sendArr = arraySlice(result.excelDataList, 20);
    let sendCount = 0;
    let isManualStop = false;

    while(sendArr.length > sendCount) {
      const state = yield select();
      if(state.excelFile.actionResult === MANUAL_PROGRESS_STATE.PROCESS_STOP) {
        isManualStop = true;
        break;
      }

      const params = {
        excelFileId: payload.excelFileId,
        excelDataList: sendArr[sendCount]
      }

      const result = yield call(excelFileApiLib.excelInitData, params);
      sendCount++;
    }

    if(isManualStop) {
      yield put(excelFileAction.excelFileSaveStop())
    } else {
      yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.MATCHED_STAY });

      yield put(excelFileAction.excelFileSaveSuccess());
    }

  } catch (err) {
    console.log(err);
    yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.ERROR });
    yield put(excelFileAction.excelFileSaveFailure('File save Error'));
  }
}

function* excelMatching({ payload }) {
  try {

    const sendArr = arraySlice(payload.excelDataList, 10);
    let sendCount = 0;

    // yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.MATCHING });

    while(sendArr.length > sendCount) {
      const params = {
        shopId: payload.shopId,
        excelFileId: payload.excelFileId,
        excelDataList: sendArr[sendCount]
      }

      const result = yield call(excelFileApiLib.excelServerMatching, params);
      sendCount++;
    }

    const excelFile = yield call(excelFileApiLib.ExcelOrderListDirectApi, {
      shopId: payload.shopId,
      excelFileId: payload.excelFileId});

    const matchCount = excelFile.excelOrderList.filter(list => list.isMatching === 'SUCCESS');

    yield call(excelFileApiLib.ExcelFileStateDirectApi, {
      excelFileId: payload.excelFileId,
      fileState: MANUAL_PROGRESS_STATE.MATCHED,
      successCount: matchCount.length });

    yield put(excelFileAction.excelMatchingSuccess());
  } catch (err) {
    console.log(err);
    yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.ERROR });
    yield put(excelFileAction.excelMatchingFailure('excel Matching Error'));
  }
}


function* excelCalculation({ payload }) {
  try {

    const sendArr = arraySlice(payload.excelDataList, 20);
    let sendCount = 0;

    // yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.CALCULATING });

    while(sendArr.length > sendCount) {
      const params = {
        shopId: payload.shopId,
        excelFileId: payload.excelFileId,
        excelDataList: sendArr[sendCount]
      }

      const result = yield call(excelFileApiLib.excelCalculating, params);
      sendCount++;
    }

    const excelFile = yield call(excelFileApiLib.ExcelOrderListDirectApi, {
      shopId: payload.shopId,
      excelFileId: payload.excelFileId});

    const calcCount = excelFile.excelOrderList.filter(list => list.isCalcApply);

    yield call(excelFileApiLib.ExcelFileStateDirectApi, {
      excelFileId: payload.excelFileId,
      fileState: MANUAL_PROGRESS_STATE.CALC_COMPLETE,
      orderCalcCount: calcCount.length });

    yield put(excelFileAction.excelCalculatingSuccess());
  } catch (err) {
    console.log(err);
    yield call(excelFileApiLib.ExcelFileStateDirectApi, {excelFileId: payload.excelFileId, fileState: MANUAL_PROGRESS_STATE.ERROR });
    yield put(excelFileAction.excelCalculatingFailure('excel calculating Error'));
  }
}

export function* watchExcelFileSave() {
  yield takeLatest(excelFileAction.excelFileSave, excelFileSave);
}

export function* watchExcelMatching() {
  yield takeLatest(excelFileAction.excelMatching, excelMatching);
}

export function* watchExcelCalculating() {
  yield takeLatest(excelFileAction.excelCalculating, excelCalculation);
}

function* rootSaga() {
  yield all([
    fork(watchExcelFileSave),
    fork(watchExcelMatching),
    fork(watchExcelCalculating)
  ]);
}

export default rootSaga;
