import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useCustomSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchAsObject = useMemo(() => Object.fromEntries(new URLSearchParams(searchParams)), [searchParams]);

  return [searchAsObject, setSearchParams];
};

export default useCustomSearchParams;
