import * as apiLib from './api';

export const getSpaceLists = async ( { spaceType } ) => {
    try {

        const data = {
            spaceType: spaceType
        };

        const result = await apiLib.fetchStoreApi('/space/list', data);
        console.log(result);
        if (result.resultFlag) {
            return result.spaceList;
        } else {
            throw Object.assign(new Error('space lists error'), { status: 401 });
        }
    } catch (err) {
        throw err;
    }
};
