// import CryptoJS from 'crypto-js';

// dataURL to File
const dataURLtoFile = (dataurl, fileName) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

// 썸네일 생성
const createThumb = (fileEl, file) => {
  let width = fileEl.width;
  let height = fileEl.height;

  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');

  var maxSize = 350;
  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }

  canvas.width = width;
  canvas.height = height;

  context.drawImage(fileEl, 0, 0, width, height);
  let dataURL = canvas.toDataURL();

  const thumbFile = dataURLtoFile(dataURL, file.name);
  return thumbFile;
};

// 동영상 2초 시점의 썸네일 구하기
export const getVideoThumb = file =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.addEventListener('loadedmetadata', function () {
        const time = Math.floor(video.duration / 2); //비디오의 영상길이 중 절반의 시간
        video.currentTime = time; //해당 시간으로 이동
        setTimeout(() => {
          video.width = video.videoWidth;
          video.height = video.videoHeight;
          resolve(createThumb(video, file));
        }, 200);
      });

      video.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };
    } catch (e) {
      reject(e);
    }
  });

// 이미지 썸네일 구하기
export const getImageThumb = file =>
  new Promise((resolve, reject) => {
    try {
      let image = document.createElement('img');
      image.src = URL.createObjectURL(file);

      image.onload = function () {
        resolve(createThumb(image, file));
      };
    } catch (e) {
      reject(e);
    }
  });

/**
 * 파일객체만 넘겨줘서 썸네일 만들기
 * @param {File} file file 객체
 * @return 동영상 재생시간 or  0
 */
export const getDuration = file =>
  file.type.includes('video')
    ? new Promise((resolve, reject) => {
        try {
          let video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = function () {
            resolve(parseInt(video.duration));
          };

          video.onerror = function () {
            reject('Invalid video. Please select a video file.');
          };

          video.src = URL.createObjectURL(file);
        } catch (e) {
          reject(e);
        }
      })
    : 0;

/**
 * 파일 객체 넘겨줘서 binary로 해쉬값 만들기
 * @param {File} file file 객체
 * @returns fileHash값
 */
// export const getFileHash = file =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onload = function (e) {
//       const binary = e.target.result;
//       const md5 = CryptoJS.MD5(binary).toString();
//       resolve(md5);
//     };

//     reader.readAsBinaryString(file);
//   });

/**
 * 파일객체만 넘겨줘서 썸네일 만들기
 * @param {File} file file 객체
 * @return thumbnail 이미지 or null
 */
export const createThumbnailFile = async file => {
  if (file.type.includes('audio')) {
    return null;
  }

  const thumb = file.type.includes('image') ? await getImageThumb(file) : await getVideoThumb(file);
  return file.size > thumb.size ? thumb : null;
};

/**
 * byte 를 근접한 B로 변환
 * @param {string | number} bytes 변환할 byte
 * @param {number} decimals 소수점 단위
 * @return 변환된 byte
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
