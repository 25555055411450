import {
    createSlice,
    createDraftSafeSelector,
    PayloadAction,
  } from '@reduxjs/toolkit';
import dayjs from 'dayjs';


/*
interface OpenLogInfoPayload{
  shopId : string;
}
*/
  interface OpenLogSelectPayload {
    shopId: string;
    openStartDt: string;
    openEndDt: string;
  }

  interface OpenLogInsertPayload {
    deviceId: string;
    shopId: string;
    logDt: string;
    shopOpenState: string;
    endAutoState: string;
    managerId: string;
    regDt: String;
  }

  /*
  interface OpenLogInfo {
    todayOpenInfo: string;
    todayEndInfo: string;
    beforeOpenInfo: string;
    beforeEndInfo: string;
  }
  */

  interface FilterInfo {
    openStartDt: string;
    openEndDt: string;
  }

  interface PageInfo {
    rowsPerPage: number;
    curPage: number;
  }

  interface OpenLogState{
      // openLogInfo : OpenLogInfo;
      openLogList : Array<OpenLogInfo>;
      filterInfo : FilterInfo;
      pageInfo: PageInfo;
      actionResult : string;
      isLoading: boolean;
      error: string | null;
  }

  const openLogInitialState: OpenLogState = {
    /*
    openLogInfo : {
        todayOpenInfo : '',
        todayEndInfo : '',
        beforeOpenInfo : '',
        beforeEndInfo : ''
    },
    */
    openLogList : [],
    filterInfo : { openStartDt : dayjs().set('date',1).format('YYYYMMDD'), openEndDt:dayjs().format('YYYYMMDD') },
    pageInfo: { rowsPerPage: 10, curPage: 1 },
    actionResult : '',
    isLoading: false,
    error: null,
  };

  const reducers = {

    /*
    openLogInfo : (state:OpenLogState,{payload}:PayloadAction<OpenLogInfoPayload>) => {
      state.actionResult = 'OPENINFO_REQ';
      state.isLoading = true;
      state.error = null;
    },

    openLogInfoSuccess: (state: OpenLogState, { payload }: PayloadAction<OpenLogState>) => {
      let sortOpenLogList = [];
      sortOpenLogList = [...payload.openLogList].sort((l, r) => l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1);

      let latelyOpenInfo = sortOpenLogList.find(openLog => openLog.shopOpenState ==='Y');
      let latelyEndInfo = sortOpenLogList.find(openLog => openLog.shopOpenState ==='N');

      let openLogInfo  = {};

      if(latelyOpenInfo === undefined || latelyEndInfo === undefined){  // 마감 한적 없음
        openLogInfo = {
          todayOpenInfo : latelyOpenInfo,
          todayEndInfo : latelyEndInfo,
          beforeOpenInfo : undefined,
          beforeEndInfo : undefined,
        }
      }else if(latelyOpenInfo.regDt>latelyEndInfo.regDt){      // 오픈만 한거
        openLogInfo= {
          todayOpenInfo : latelyOpenInfo,
          todayEndInfo :  undefined,
          beforeOpenInfo : sortOpenLogList.find(openLog => openLog.regDt !== latelyOpenInfo.regDt && openLog.shopOpenState ==='Y'),
          beforeEndInfo : latelyEndInfo,
        }
      }else{          // 마감한거
        openLogInfo = {
          todayOpenInfo : undefined,
          todayEndInfo :  undefined,
          beforeOpenInfo : latelyOpenInfo,
          beforeEndInfo : latelyEndInfo,
        }
      }

      //////////////////////////////////

      state.openLogInfo = openLogInfo;
        
      state.actionResult = 'OPENINFO_OK';
      state.isLoading = false;
      state.error = null;
    },
    openLogInfoFailure: (state: OpenLogState, action: PayloadAction<string>) => {
      state.actionResult = 'OPENINFO_ERR';
      state.isLoading = false;
      state.error = action.payload;
    },
    */

    actionResultClear: (state: AuthState) => {
      state.actionResult = '';
    },

    openLogList :(state:OpenLogState, { payload }: PayloadAction<OpenLogSelectPayload>) => {
      state.filterInfo.openStartDt = payload.openStartDt;
      state.filterInfo.openEndDt = payload.openEndDt;
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
      
    openLogListSuccess: (state: OpenLogState, { payload }: PayloadAction<OpenLogState>) => {
      // console.log('openLogListSuccess');
      let sortOpenLogList = [];
      sortOpenLogList = [...payload.openLogList].sort((l, r) => l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1);

      /*
      let latelyOpenInfo = sortOpenLogList.find(openLog => openLog.shopOpenState ==='Y');
      let latelyEndInfo = sortOpenLogList.find(openLog => openLog.shopOpenState ==='N');

      let openLogInfo  = {};

      if(latelyOpenInfo === undefined || latelyEndInfo === undefined){  // 마감 한적 없음
        openLogInfo = {
          todayOpenInfo : latelyOpenInfo,
          todayEndInfo : latelyEndInfo,
          beforeOpenInfo : undefined,
          beforeEndInfo : undefined,
        }
      }else if(latelyOpenInfo.regDt>latelyEndInfo.regDt){      // 오픈만 한거
        openLogInfo= {
          todayOpenInfo : latelyOpenInfo,
          todayEndInfo :  undefined,
          beforeOpenInfo : sortOpenLogList.find(openLog => openLog.regDt !== latelyOpenInfo.regDt && openLog.shopOpenState ==='Y'),
          beforeEndInfo : latelyEndInfo,
        }
      }else{          // 마감한거
        openLogInfo = {
          todayOpenInfo : undefined,
          todayEndInfo :  undefined,
          beforeOpenInfo : latelyOpenInfo,
          beforeEndInfo : latelyEndInfo,
        }
      }

      state.openLogInfo = openLogInfo;
      */

      //////////////////////////////////

      state.openLogList = sortOpenLogList;
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    openLogListFailure: (state: OpenLogState, action: PayloadAction<string>) => {
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = action.payload;
    },
    openLogLogAdd :(state:OpenLogState, { payload }: PayloadAction<OpenLogInsertPayload>) => {
      // state.filterInfo.openStartDt = payload.openStartDt;
      // state.filterInfo.openEndDt = payload.openEndDt;
      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    openLogLogAddSuccess: (state: OpenLogState, { payload }: PayloadAction<OpenLogState>) => {
      // state.openLogList.push(payload.openLog);
      // state.openLogList = payload.openLogList;
      // state.openLog = payload.openLog;
      // console.log('add_OK 들어옴');
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    openLogLogAddFailure: (state: OpenLogState, action: PayloadAction<string>) => {
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = action.payload;
    },
    openLogPaging: (state: ReportState, { payload: {key, value} }: PayloadAction<ReportState>) => {
      state.pageInfo[key] = value;
    },
    clearOpenLogState: (state: OpenLogState) => {
      state.openLogList = [];
    },
  }

  const slice = createSlice({
    name: 'openLog',
    initialState : openLogInitialState,
    reducers: reducers,
  });

  /*
  const selectOpenLogInfo = createDraftSafeSelector(
    (state : OpenLogState)  => state.openLogInfo,
    openLogInfo => openLogInfo,
  );
  */

  const selectOpenLogList = createDraftSafeSelector(
    (state : OpenLogState)  => state.openLogList,
    openLogList => openLogList,
  );

  const selectOpenLogFilter  = createDraftSafeSelector(
    (state : OpenLogState)  => state.filterInfo,
    filterInfo => filterInfo,
  );

/*
  const selectTodayOpenLogInfo = createDraftSafeSelector(
    (state : OpenLogState)  => state.openLogInfo,
    
    openLogInfo => openLogInfo,
    
  );
  */

  const selectFilterOpenLogList = createDraftSafeSelector(
    (state: any) => state.openLog.openLogList,
    (state: any) => state.openLog.filterInfo,
    (state: any) => state.openLog.pageInfo,

    (openLogList, filterInfo, pageInfo) => {
      // 총 페이지 수
      // 한 페이지에 들어가는 list
      // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
      // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

      let totalPageCnt = openLogList.length % pageInfo.rowsPerPage > 0 ? Math.floor(openLogList.length / pageInfo.rowsPerPage) + 1 : openLogList.length / pageInfo.rowsPerPage;
      let startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
      let endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
      let sliceList = openLogList.slice(startIndex, endIndex);

      return {
        totalPageCnt,
        openLogList: sliceList,
        startIndex,
      }
    },
  );

  const selectOpenLogPageInfo = createDraftSafeSelector(
    (state: any) => state.openLog.pageInfo,
    pageInfo => pageInfo,
  );

  const selectStatus = createDraftSafeSelector(
    (state: OpenLogState) => state.actionResult,
    (state: OpenLogState) => state.isLoading,
    (state: OpenLogState) => state.error,
    (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
  );

  

export const openLogSelector = {
    // openLogSelect : state => selectOpenLogInfo(state[OPENLOG]),
    openLogList : state => selectOpenLogList(state[OPENLOG]),
    openLogFilter: state => selectOpenLogFilter(state[OPENLOG]),
    // openLogInfo: state => selectTodayOpenLogInfo(state[OPENLOG]),
    filterOpenLogList: state => selectFilterOpenLogList(state),
    openLogPageInfo: state => selectOpenLogPageInfo(state),
    status: state => selectStatus(state[OPENLOG]),

}

export const OPENLOG = slice.name;
export const openLogReducer = slice.reducer;
export const openLogAction = slice.actions;




