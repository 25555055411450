import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import Loading from '../../components/Loading';
import SelectInput from '../../components/SelectInput';

import { Col, PaginationArea, TableBody, TableBodyEmpty, TableContainer, TableHead, TableRow } from '../../assets/style/TableStyle';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import DateSearch from '../../components/DateSearch';
import Pagination from '../../components/Pagination';
import useCustomSearchParams from '../../components/useCustomSearchParams';
import { shopSelector } from '../../module/shopSlice';
import { useCategoryListQuery } from '../../redux/categoryApi';
import { useSurveyMenuListQuery } from '../../redux/surveyMenuApi';
import SubTab from './common/SubTab';
import { useCategoryGroupListQuery } from '../../redux/categoryGroupApi';
import Input from '../../components/Input';

const INIT_STARTDT = dayjs().subtract(7, 'days').format('YYYYMMDD');
const INIT_ENDDT = dayjs().format('YYYYMMDD');

const FoodSurveyMenuList = () => {
  const shopInfo = useSelector(shopSelector.shopInfo);
  const [searchParams, setSearchParams] = useCustomSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.customerNm || '');
  const qsCurPage = Number(searchParams['curPage']) - 1 || 0;
  const qsRowsPerPage = Number(searchParams['rowsPerPage']) || 10;
  const qsStartDt = searchParams['startDt'] || INIT_STARTDT;
  const qsEndDt = searchParams['endDt'] || INIT_ENDDT;

  const { currentData: surveyMenuList, status: surveyMenuListQueryStatus } = useSurveyMenuListQuery(
    {
      shopId: shopInfo.shopId,
      brandId: shopInfo.brandId,
      startDt: dayjs(qsStartDt).startOf('date').format('YYYYMMDD'),
      endDt: dayjs(qsEndDt).endOf('date').format('YYYYMMDD'),
      categoryId: searchParams.categoryId || '',
      categoryGroupId: searchParams.categoryGroupId || '',
      ratingValue: searchParams.ratingValue || '',
      customerNm: searchParams.customerNm || '',
    },
    { skip: !shopInfo.shopId },
  );
  const { currentData: categoryList, status: categoryQueryStatus } = useCategoryListQuery(
    { brandId: shopInfo.brandId, categoryType: 'MEAL', delYn: 'N' },
    { skip: !shopInfo.shopId },
  );

  const { currentData: categoryGroupList, status: categoryGroupQueryStatus } = useCategoryGroupListQuery(
    { brandId: shopInfo.brandId, categoryType: 'MEAL', delYn: 'N' },
    { skip: !shopInfo.brandId },
  );

  const [filterCategoryId, setFilterCategoryId] = useState('ALL');

  const isLoading = useMemo(
    () => surveyMenuListQueryStatus === 'uninitialized' || surveyMenuListQueryStatus === 'pending' || categoryQueryStatus === 'uninitialized' || categoryQueryStatus === 'pending',
    [categoryQueryStatus, surveyMenuListQueryStatus],
  );

  const [categoryOptionList, categoryNmObj] = useMemo(() => {
    return (
      categoryList?.reduce(
        ([optionList, categoryNmObj], category) => {
          if (category.categoryPid !== shopInfo.brandId) {
            const { categoryId, categoryNm } = category;
            optionList.push({ value: categoryId, title: categoryNm });
            categoryNmObj[categoryId] = categoryNm;
          }
          return [optionList, categoryNmObj];
        },
        [[{ value: 'ALL', title: '카테고리 전체' }], {}],
      ) || [[{ value: 'ALL', title: '카테고리 전체' }], {}]
    );
  }, [categoryList, shopInfo.brandId]);

  const categoryGroupOptionList = useMemo(() => {
    const optionItems = categoryGroupList?.map(ctg => ({ value: ctg.categoryGroupId, title: ctg.categoryGroupNm })) || [];
    optionItems.unshift({ value: 'ALL', title: '운영시간 전체' });
    return optionItems;
  }, [categoryGroupList]);

  const ratingOptionList = [
    { value: 'ALL', title: '만족도 전체' },
    { value: '1', title: '불만족' },
    { value: '3', title: '보통' },
    { value: '5', title: '만족' },
  ];

  const searchTypeOptionList = [{ value: 'customerNm', title: '결재정보' }];

  const handleChagneCategoryId = useCallback(
    value => {
      setSearchParams({ ...searchParams, categoryId: value });
    },
    [searchParams, setSearchParams],
  );

  const handleChangeCategoryGroupId = useCallback(
    value => {
      setSearchParams({ ...searchParams, categoryGroupId: value });
    },
    [searchParams, setSearchParams],
  );

  const handleChangeSearch = useCallback(e => {
    setSearchQuery(e.target.value);
  }, []);

  const handleChangeRatingValue = useCallback(
    value => {
      setSearchParams({ ...searchParams, ratingValue: value });
    },
    [searchParams, setSearchParams],
  );

  const handleSearch = useCallback(() => {
    setSearchParams({ ...searchParams, customerNm: searchQuery });
  }, [searchParams, searchQuery, setSearchParams]);

  return (
    <Wrap>
      <Header>
        <DateSearch initSatrtDt={INIT_STARTDT} initEndDt={INIT_ENDDT} disableToday />
        <SelectInput selectedValue={searchParams.categoryGroupId || 'ALL'} optionList={categoryGroupOptionList} onSelectChange={handleChangeCategoryGroupId} width="120" />
        <SelectInput selectedValue={searchParams.categoryId || 'ALL'} optionList={categoryOptionList} onSelectChange={handleChagneCategoryId} width="120" />
        <SelectInput selectedValue={searchParams.ratingValue || 'ALL'} optionList={ratingOptionList} onSelectChange={handleChangeRatingValue} width="120" />
        <SelectInput selectedValue={'customerNm'} optionList={searchTypeOptionList} width="120" />
        <StyledInput placeholder="검색어를 입력하세요" value={searchQuery} onChange={handleChangeSearch} onKeyPress={e => e.key === 'Enter' && handleSearch()} />
        <SearchButton onClick={() => handleSearch()}>조회</SearchButton>
        <SubTab
          tabList={[
            { title: '항목별', url: '/report/food/survey', isSelected: false },
            { title: '리스트', url: '/report/food/survey/list', isSelected: true },
            { title: '평점순위', url: '/report/food/survey/rank', isSelected: false },
          ]}
        />
      </Header>
      <Container>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <TableArea>
              <Table customerSurveyMenuList={surveyMenuList} qsCurPage={qsCurPage} qsRowsPerPage={qsRowsPerPage} />
            </TableArea>
          </>
        )}
      </Container>
    </Wrap>
  );
};

const Table = React.memo(({ qsCurPage, qsRowsPerPage, customerSurveyMenuList }) => {
  const [totalCount, setTotalCount] = useState(0);
  console.log(customerSurveyMenuList);

  const pagingCustomerSurveyMenuList = useMemo(() => {
    setTotalCount(customerSurveyMenuList?.length || 0);
    const pagingCustomerSurveyMenuList = customerSurveyMenuList?.slice(qsCurPage * qsRowsPerPage, (qsCurPage + 1) * qsRowsPerPage) || [];

    return pagingCustomerSurveyMenuList;
  }, [customerSurveyMenuList, qsCurPage, qsRowsPerPage]);

  return (
    <>
      <TableContainer>
        <TableHead>
          <Col type="s">No</Col>
          <Col type="m">날짜</Col>
          <Col type="m">운영시간</Col>
          <Col type="m">카테고리</Col>
          <Col type="l">메뉴명</Col>
          <Col type="m">결제정보</Col>
          <Col type="m">만족도</Col>
        </TableHead>
        <TableBody>
          {pagingCustomerSurveyMenuList.length === 0 ? (
            <TableBodyEmpty>등록된 데이터가 없습니다.</TableBodyEmpty>
          ) : (
            pagingCustomerSurveyMenuList.map((customerSurveyDailyLog, index) => {
              return (
                <TableRow key={customerSurveyDailyLog.logId}>
                  <Col type="s">{qsCurPage * qsRowsPerPage + index + 1}</Col>
                  <Col type="m">{dayjs(customerSurveyDailyLog.regDt).format('YYYY.MM.DD')}</Col>
                  <Col type="m">{customerSurveyDailyLog.categoryGroupNm}</Col>
                  <Col type="m">{customerSurveyDailyLog.categoryNm}</Col>
                  <Col type="l">{customerSurveyDailyLog.goodsNm}</Col>
                  <Col type="m">{customerSurveyDailyLog.customerNm}</Col>
                  <Col type="m">{customerSurveyDailyLog.ratingType}</Col>
                </TableRow>
              );
            })
          )}
        </TableBody>
        <PaginationArea>
          <Pagination totalCount={totalCount} />
        </PaginationArea>
      </TableContainer>
    </>
  );
});

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  flex-shrink: 0;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const SearchButton = styled.button`
  height: 2.5rem;
  padding: 0 0.625rem;
  background: #e3f0fa;
  border: 0.0625rem solid #2a91df;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 1rem;
  color: #2a91df;
`;

const TableArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledInput = styled(Input)`
  min-width: 120px;
  max-width: 200px;

  &::placeholder {
    font-size: 12px;
  }
`;

export default React.memo(FoodSurveyMenuList);
