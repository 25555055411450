import React, {useEffect} from 'react';
import { Reset } from 'styled-reset';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { injectStyle } from 'react-toastify/dist/inject-style';

import "react-datepicker/dist/react-datepicker.css";

import Router from './router';
import {useDispatch} from "react-redux";
import {spaceAction} from "../module/spaceSlice";

const theme = {
  white: '#fff',
  black: '#000',
  grayBackground: '#dfdfdf',
  disabled: '#f7f7f7',
  gray100: '#f1f1f1',
  gray200: '#eee',
  gray300: '#ccc',
  gray400: '#aaa',
  gray500: '#999',
  gray600: '#777',
  gray700: '#555',
  gray800: '#333',
  gray900: '#111',
  blue: '#41a1ea',
  indigo: '#727cf5',
  purple: '#6b5eae',
  pink: '#ff679b',
  red: '#f05b5b',
  orange: '#fd7e14',
  yellow: '#ffbc00',
  green: '#0acf97',
  teal: '#02a8b5',
  cyan: '#39afd1',
  bodyBg: '#f4f5f6',
};

const GlobalStyle = createGlobalStyle`
  @charset "utf-8";

  @font-face {
  font-family: 'NanumSquare';
  font-weight: 400;
  src: url(${require('../assets/fonts/NanumSquareR.eot')});
  src: url(${require('../assets/fonts/NanumSquareR.woff')}) format('woff'),
        url(${require('../assets/fonts/NanumSquareR.ttf')}) format('truetype');
  }
  @font-face {
  font-family: 'NanumSquare';
  font-weight: 700;
  src: url(${require('../assets/fonts/NanumSquareB.eot')});
  src: url(${require('../assets/fonts/NanumSquareB.woff')}) format('woff'),
        url(${require('../assets/fonts/NanumSquareB.ttf')}) format('truetype');
  }
  @font-face {
  font-family: 'NanumSquare';
  font-weight: 800;
  src: url(${require('../assets/fonts/NanumSquareEB.eot')});
  src: url(${require('../assets/fonts/NanumSquareEB.woff')}) format('woff'),
        url(${require('../assets/fonts/NanumSquareEB.ttf')}) format('truetype');
  }
  @font-face {
  font-family: 'NanumSquare';
  font-weight: 300;
  src: url(${require('../assets/fonts/NanumSquareL.eot')});
  src: url(${require('../assets/fonts/NanumSquareL.woff')}) format('woff'),
        url(${require('../assets/fonts/NanumSquareL.ttf')}) format('truetype');
  }

  html {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', 'Noto Sans KR', sans-serif !important;
    box-sizing: border-box;

    #root {
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;

    &:focus {
      outline: none;
    }
  }

  button, input, textarea {
    font: inherit;
  }

  * {
    box-sizing: inherit;
  }

  *:not(input, textarea) {
    /* 드래그 방지 css */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  /* 포커스 시 placeholder 숨기기 in <input>, <textarea> */
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder { /* WebKit browsers */
    color: transparent;
  } 
  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:transparent;
  }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:transparent;
  }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:transparent;
  }

  .designed-scroll::-webkit-scrollbar {
    width: 6px;
  }
  .designed-scroll::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 2.5px;
  }
  .designed-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }

  .viewer {
    overflow: hidden;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select:none;
  }
  .viewer::-webkit-scrollbar {
    display: none;
  }

  .label-vertical-center {
    padding-top: calc(.45rem + 1px);
    padding-bottom: calc(.45rem + 1px);
    line-height: 1.5;
  }

 // ReactHtmlParser 시 reset css 때문에 적용 안되는 태그 및 클래스 재설정
 .htmlparser {
    h1 {
      font-size: 2em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.5em;
      font-weight: bold;
    }
    h3 {
      font-size: 1.17em;
      font-weight: bold;
    }
    h4 {
      font-size: 1.33em;
      font-weight: bold;
    }
    h5 {
      font-size: .83em;
      font-weight: bold;
    }
    h6 {
      font-size: .67em;
      font-weight: bold;
    }
    strong { 
      font-weight : bold; 
    }
    em { 
      font-style : italic; 
    }
    u { 
      text-decoration : underline; 
    }
    s { 
      text-decoration : line-through; 
    }
    blockquote {
      border-left : 4px solid #aaaaaa;
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left : 16px;
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
     }
    ul {
      display: block;
      list-style-type: disc;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
    }
    // 들여쓰기
    .ql-indent-1 {
      padding-left : 3em;
    }
    .ql-indent-2 {
      padding-left : 6em;
    }
    .ql-indent-3 {
      padding-left : 9em;
    }
    .ql-indent-4 {
      padding-left : 12em;
    }
    .ql-indent-5 {
      padding-left : 15em;
    }
    .ql-indent-6 {
      padding-left : 18em;
    }
    .ql-indent-7 {
      padding-left : 21em;
    }
    .ql-indent-8 {
      padding-left : 24em;
    }

    //가운데 정렬
    .ql-align-center {
      text-align : center;
    }
    //오른쪽 정렬
    .ql-align-right {
      text-align : right;
    }
    //일반 정렬
    .ql-align-justify {
      text-align : justify;
    }
  }
`;

const App = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(spaceAction.initSpaceState());
    dispatch(spaceAction.spaceList({ spaceType: 'STORE' }));
  }, [dispatch])
  injectStyle();

  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
};

export default App;
