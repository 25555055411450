import { fork, call, put, takeLatest, all, select } from 'redux-saga/effects';

import * as playlistApiLib from '../lib/playlistApi';
import * as frameApiLib from '../lib/frameApi';
import * as layerApiLib from '../lib/layerApi';
import * as layerContentsApiLib from '../lib/layerContentsApi';
import * as overlayApiLib from '../lib/overlayApi';
import * as overlayContentsApiLib from '../lib/overlayContentsApi';
import * as storageApiLib from '../lib/storageApi';
import * as deviceApiLib from '../lib/deviceApi';
import * as playApiLib from '../lib/playApi';

import { playlistAction } from './playlistSlice';
import { frameAction } from './frameSlice';
import { layerContentsAction } from './layerContentsSlice';
import { layerAction } from './layerSlice';
import { overlayContentsAction } from './overlayContentsSlice';
import { overlayAction } from './overlaySlice';
import { shopSelector } from './shopSlice';
import { storageSelector } from './storageSlice';

function* list({ payload }) {
  try {
    const playlistList = yield call(playlistApiLib.list, payload);

    yield put(playlistAction.listSuccess({ playlistList }));
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.listFailure('list failure'));
  }
}

function* detail({ payload }) {
  try {
    const playlistInfo = yield call(playlistApiLib.detail, payload);
    const frameList = yield call(frameApiLib.list, payload);
    const layerList = yield call(layerApiLib.list, payload);
    const layerContentsList = yield call(layerContentsApiLib.list, payload);
    const overlayList = yield call(overlayApiLib.list, payload);
    const overlayContentsList = yield call(overlayContentsApiLib.list, payload);

    yield put(overlayContentsAction.setOverlayContentsList({ overlayContentsList }));
    yield put(overlayAction.setOverlayList({ overlayList }));
    yield put(layerContentsAction.setLayerContentsList({ layerContentsList }));
    yield put(layerAction.setLayerList({ layerList }));
    yield put(frameAction.setFrameList({ frameList }));
    yield put(playlistAction.detailSuccess({ playlistInfo }));
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.listFailure('list failure'));
  }
}

function* add({ payload: { placeId, playlist, frameList, layerList, layerContentsList, overlayList, overlayContentsList, handleUploadProgress, isPlay, isUpdate = false } }) {
  try {
    if (isUpdate) {
      yield call(frameApiLib.remove, { playlistId: playlist.playlistId });
    }

    yield call(playlistApiLib.add, playlist);
    yield call(frameApiLib.add, { frameList });
    yield call(layerApiLib.add, { layerList });

    const storageFileList = yield select(storageSelector.fileList);

    for (let layerContents of layerContentsList) {
      if (
        layerContents.contentsType === 'media' ||
        layerContents.contentsType === 'scheduledMedia' ||
        layerContents.contentsType === 'pickupBoard' ||
        layerContents.contentsType === 'menuboard' ||
        layerContents.contentsType === 'customerSurvey'
      ) {
        const fileList = layerContents.contentsFileList.filter(file => !storageFileList.find(storageFlie => storageFlie.fileId === file.fileId));

        if (fileList.length > 0) {
          const resultFileList = yield call(storageApiLib.s3Upload, { fileList, placeId, handleUploadProgress });
          yield call(storageApiLib.add, { fileList: resultFileList });
          layerContents.contentsFileList = layerContents.contentsFileList.map(contentsFile => resultFileList.find(file => file.fileId === contentsFile.fileId) || contentsFile);
        }
      }

      // 식당키오스크 성공 실패 알람 추가 20220906
      if (layerContents.contentsType === 'foodcourtOrder') {
        const successFileId = layerContents.contentsData.successFileId;
        const failFileId = layerContents.contentsData.failFileId;
        layerContents.contentsFileList = storageFileList.filter(storageFile => storageFile.fileId === successFileId || storageFile.fileId === failFileId);
      }
    }

    if (layerContentsList.length) {
      yield call(layerContentsApiLib.add, { layerContentsList });
    }

    if (overlayList.length) {
      yield call(overlayApiLib.add, { overlayList });

      for (let overlayContents of overlayContentsList) {
        if (
          overlayContents.contentsType === 'media' ||
          overlayContents.contentsType === 'scheduledMedia' ||
          overlayContents.contentsType === 'pickupBoard' ||
          overlayContents.contentsType === 'menuboard'
        ) {
          const fileList = overlayContents.contentsFileList.filter(file => !storageFileList.find(storageFlie => storageFlie.fileId === file.fileId));
          if (fileList.length > 0) {
            const resultFileList = yield call(storageApiLib.s3Upload, { fileList, placeId, handleUploadProgress });
            yield call(storageApiLib.add, { fileList: resultFileList });
            overlayContents.contentsFileList = overlayContents.contentsFileList.map(
              contentsFile => resultFileList.find(file => file.fileId === contentsFile.fileId) || contentsFile,
            );
          }
        }
      }

      if (overlayContentsList.length) {
        yield call(overlayContentsApiLib.add, { overlayContentsList });
      }
    }

    if (isPlay) {
      const { spaceId, shopId, brandId, placeId } = yield select(shopSelector.shopInfo);
      const deviceList = yield call(deviceApiLib.list, { placeId });

      const { playlistId, playlistNm } = playlist;
      const filterDeviceList = deviceList.filter(device => device.playlistId === playlistId);
      let deviceIdList = [];

      for (const device of filterDeviceList) {
        const { deviceId, groupId } = device;
        deviceIdList.push({ deviceId, groupId });
      }

      yield call(deviceApiLib.play, { playlistId, deviceList: deviceIdList });
      yield call(playApiLib.add, { placeId, playlistId, playNm: playlistNm, deviceList: deviceIdList, spaceId, brandId, shopId });
    }

    yield put(playlistAction.addSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.addFailure('add failure'));
  }
}

function* remove({ payload }) {
  try {
    yield call(playlistApiLib.remove, payload);

    yield put(playlistAction.removeSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.removeFailure('remove failure'));
  }
}

function* edit({ payload: { placeId, playlistId, updateInfo, originMediaFileList, updateLayerContentsList, updateOverlayContentsList, handleUploadProgress, isPlay } }) {
  try {
    yield call(playlistApiLib.edit, { playlistId, updateInfo });

    const storageFileList = yield select(storageSelector.fileList);

    for (let layerContents of updateLayerContentsList) {
      if (
        layerContents.contentsType === 'media' ||
        layerContents.contentsType === 'scheduledMedia' ||
        layerContents.contentsType === 'pickupBoard' ||
        layerContents.contentsType === 'menuboard'
      ) {
        const fileList = layerContents.contentsFileList.filter(
          file => !originMediaFileList.find(originFile => originFile.fileId === file.fileId) && !storageFileList.find(storageFlie => storageFlie.fileId === file.fileId),
        );

        if (fileList.length > 0) {
          const resultFileList = yield call(storageApiLib.s3Upload, { fileList, placeId, handleUploadProgress });
          yield call(storageApiLib.add, { fileList: resultFileList });
          layerContents.contentsFileList = layerContents.contentsFileList.map(contentsFile => resultFileList.find(file => file.fileId === contentsFile.fileId) || contentsFile);
        }
      }

      // 식당키오스크 성공 실패 알람 추가 20220906
      if (layerContents.contentsType === 'foodcourtOrder') {
        const successFileId = layerContents.contentsData.successFileId;
        const failFileId = layerContents.contentsData.failFileId;
        layerContents.contentsFileList = storageFileList.filter(storageFile => storageFile.fileId === successFileId || storageFile.fileId === failFileId);
      }
    }

    if (updateLayerContentsList.length) {
      yield call(layerContentsApiLib.edit, { layerContentsList: updateLayerContentsList });
    }

    for (let overlayContents of updateOverlayContentsList) {
      if (
        overlayContents.contentsType === 'media' ||
        overlayContents.contentsType === 'scheduledMedia' ||
        overlayContents.contentsType === 'pickupBoard' ||
        overlayContents.contentsType === 'menuboard'
      ) {
        const fileList = overlayContents.contentsFileList.filter(
          file => !originMediaFileList.find(originFile => originFile.fileId === file.fileId) && !storageFileList.find(storageFlie => storageFlie.fileId === file.fileId),
        );
        if (fileList.length > 0) {
          const resultFileList = yield call(storageApiLib.s3Upload, { fileList, placeId, handleUploadProgress });
          yield call(storageApiLib.add, { fileList: resultFileList });
          overlayContents.contentsFileList = overlayContents.contentsFileList.map(contentsFile => resultFileList.find(file => file.fileId === contentsFile.fileId) || contentsFile);
        }
      }
    }

    if (updateLayerContentsList.length) {
      yield call(overlayContentsApiLib.edit, { overlayContentsList: updateOverlayContentsList });
    }

    if (isPlay) {
      const { spaceId, shopId, brandId, placeId } = yield select(shopSelector.shopInfo);
      const playlistInfo = yield call(playlistApiLib.detail, { playlistId });
      const deviceList = yield call(deviceApiLib.list, { placeId });

      const filterDeviceList = deviceList.filter(device => device.playlistId === playlistId);
      let deviceIdList = [];

      for (const device of filterDeviceList) {
        const { deviceId, groupId } = device;
        deviceIdList.push({ deviceId, groupId });
      }

      yield call(deviceApiLib.play, { playlistId, deviceList: deviceIdList });
      yield call(playApiLib.add, { placeId, playlistId, playNm: playlistInfo.playlistNm, deviceList: deviceIdList, spaceId, brandId, shopId });
    }

    yield put(playlistAction.editSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(playlistAction.editFailure('edit failure'));
  }
}

export function* watchList() {
  yield takeLatest(playlistAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(playlistAction.detail, detail);
}

export function* watchAdd() {
  yield takeLatest(playlistAction.add, add);
}

export function* watchRemove() {
  yield takeLatest(playlistAction.remove, remove);
}

export function* watchEdit() {
  yield takeLatest(playlistAction.edit, edit);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchDetail), fork(watchAdd), fork(watchRemove), fork(watchEdit)]);
}

export default rootSaga;
