import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

export const UploadProgressContainer = React.memo(() => {
  const domRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [loadedList, setLoadedList] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);

  const [position, setPosition] = useState('bottom-right');

  const handleUploadStart = useCallback(e => {
    const { totalSize, totalCnt, position } = e.detail;

    if (totalCnt > 0) {
      setTotalSize(totalSize);
      setTotalCnt(totalCnt);
      setIsOpen(true);
      setPosition(position);
    }
  }, []);

  const handleUploadEnd = useCallback(() => {
    setIsOpen(false);
    setLoadedList([]);
    setTotalSize(0);
    setTotalCnt(0);
  }, []);

  const handleUploadProgress = useCallback(e => {
    // const { file, total } = e.detail;
    const { loaded, id } = e.detail;

    setLoadedList(loadedList => {
      const index = loadedList.findIndex(loadInfo => loadInfo.id === id);
      if (index > -1) {
        return loadedList.map((loadInfo, mapIndex) => (mapIndex === index ? { ...loadInfo, loaded } : loadInfo));
      } else {
        return loadedList.concat({ id, loaded });
      }
    });
  }, []);

  useEffect(() => {
    if (domRef.current) {
      domRef.current.addEventListener('onUploadProgressStart', handleUploadStart);
      domRef.current.addEventListener('onUploadProgressEnd', handleUploadEnd);
      domRef.current.addEventListener('onUploadProgress', handleUploadProgress);
    }
  }, [handleUploadEnd, handleUploadProgress, handleUploadStart]);

  const percent = useMemo(() => {
    const totalLoaded = loadedList.reduce((sum, loadedInfo) => sum + loadedInfo.loaded, 0);
    return Number((totalLoaded / totalSize) * 100 || 5).toFixed(0);
  }, [totalSize, loadedList]);

  return (
    <div ref={domRef} id="UploadProgress">
      {isOpen && (
        <DarkBackground>
          <ProgressModal position={position}>
            <Container>
              <Title>
                <span>{totalCnt}개 새로운 콘텐츠 업로드</span> <span>{percent}% 완료</span>
              </Title>
              <Progress value={percent} min="0" max="100" />
              <Warn>업로드 도중 종료시 정상적으로 저장이 안됩니다</Warn>
            </Container>
          </ProgressModal>
        </DarkBackground>
      )}
    </div>
  );
});

export const uploadProgress = {
  start: (totalSize, totalCnt, position: 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' = 'bottom-right') => {
    const dom = document.getElementById('UploadProgress');
    return dom.dispatchEvent(new CustomEvent('onUploadProgressStart', { detail: { totalSize, totalCnt, position } }));
  },
  progress: (file, loaded, total, id) => {
    const dom = document.getElementById('UploadProgress');
    return dom.dispatchEvent(new CustomEvent('onUploadProgress', { detail: { file, loaded, total, id } }));
  },
  end: () => {
    const dom = document.getElementById('UploadProgress');
    return dom.dispatchEvent(new CustomEvent('onUploadProgressEnd'));
  },
};

const DarkBackground = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

const ProgressModal = styled.div`
  ${({ position }) => {
    switch (position) {
      case 'top-left':
        return css`
          margin-right: auto;
          margin-bottom: auto;
        `;
      case 'top-center':
        return css`
          margin-right: auto;
          margin-left: auto;
          margin-bottom: auto;
        `;
      case 'top-right':
        return css`
          margin-left: auto;
          margin-bottom: auto;
        `;
      case 'bottom-left':
        return css`
          margin-left: auto;
          margin-top: auto;
        `;
      case 'bottom-center':
        return css`
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
        `;
      case 'bottom-right':
        return css`
          margin-left: auto;
          margin-top: auto;
        `;
      default:
        return css`
          margin-left: auto;
          margin-top: auto;
        `;
    }
  }}
  position: relative;
  width: 500px;
  padding: 15px;
  background: white;
  border-radius: 10px;
  color: #333333;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

const Warn = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  color: #f05b5b;
  font-size: 12px;
  font-weight: 500;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px 5px 10px;
`;

const Progress = styled.progress`
  width: 100%;
  height: 20px;
  border-radius: 16px;

  &[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Get rid of default border in Firefox. */
    border: none;
  }

  &[value]::-webkit-progress-value {
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)), -webkit-linear-gradient(left, #dde4ff, #6a8bff);
    transition: 1s ease;
    border-radius: 16px;
  }

  &[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }
`;
