import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { storageAction } from './storageSlice';

import * as storageApiLib from '../lib/storageApi';

function* list({ payload }) {
  try {
    const fileList = yield call(storageApiLib.list, payload);

    yield put(storageAction.listSuccess({ fileList }));
  } catch (error) {
    yield put(storageAction.listFailure({ error }));
  }
}

function* add({ payload }) {
  try {
    const fileList = payload.fileList;
    const placeId = payload.placeId;

    const resultFileList = yield call(storageApiLib.s3Upload, { fileList, placeId });
    yield call(storageApiLib.add, { fileList: resultFileList });

    yield put(storageAction.addSuccess());
  } catch (error) {
    yield put(storageAction.addFailure({ error }));
  }
}

function* edit({ payload }) {
  try {
    yield call(storageApiLib.edit, payload);

    yield put(storageAction.editSuccess());
  } catch (error) {
    yield put(storageAction.editFailure({ error }));
  }
}

function* remove({ payload }) {
  try {
    const filePathList = payload.fileList.map(file => file.thumbFilePath ? [file.filePath, file.thumbFilePath] : [file.filePath]).flat();
    const fileIdList = payload.fileList.map(file => file.fileId);

    yield call(storageApiLib.s3Remove, { filePathList });
    yield call(storageApiLib.remove, { fileIdList });

    yield put(storageAction.removeSuccess());
  } catch (error) {
    console.log('error', error);
    yield put(storageAction.removeFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(storageAction.list, list);
}

export function* watchAdd() {
  yield takeLatest(storageAction.add, add);
}

export function* watchEdit() {
  yield takeLatest(storageAction.edit, edit);
}

export function* watchRemove() {
  yield takeLatest(storageAction.remove, remove);
}

function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchAdd),
    fork(watchEdit),
    fork(watchRemove),
  ]);
}

export default rootSaga;
