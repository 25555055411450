import * as apiLib from './api';

export const getDailyLogList = async ({ shopId, startDt, endDt }) => {

  try {
    const data = {
      shopId,
      startDt,
      endDt,
    };

    const result = await apiLib.fetchStoreApi('/report/daily-log-list', data);
    if (result.resultFlag) {
      return result.logList;
    } else {
      throw Object.assign(new Error('report error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getDailyLogCalc = async ({ shopId, orderDt }) => {
    try {
      const data = {
        shopId,
        orderDt
      };
  
      const result = await apiLib.fetchStoreApi('/report/daily-log-calc', data);
      if (result.resultFlag) {
        return result.logItem;
      } else {
        throw Object.assign(new Error('report error'), { status: 401 });
      }
    } catch (err) {
      throw err;
    }
  };
