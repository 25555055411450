import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { salesMonthLogAction} from './salesMonthLogSlice';

import * as salesMonthLogApi from '../lib/salesMonthLogApi';


function* salesMonthLogList({ payload }) {
  try {
    const salesMonthList = yield call(salesMonthLogApi.list, payload);
    yield put(salesMonthLogAction.salesMonthLogListSuccess({ salesMonthList }));
  } catch (err) {
    yield put(salesMonthLogAction.salesMonthLogListFailure('salesMonthLogList List Error'));
  }
}

function* salesMonthLogAdd({ payload }) {
  try {
    const salesMonthInfo = yield call(salesMonthLogApi.logAdd, payload);
    yield put(salesMonthLogAction.salesMonthLogAddSuccess({ salesMonthInfo }));
  } catch (err) {
    yield put(salesMonthLogAction.salesMonthLogAddFailure('salesMonthLogAdd List Error'));
  }
}

export function* watchSalesMonthLogList() {
  yield takeLatest(salesMonthLogAction.salesMonthLogList, salesMonthLogList);
}


export function* watchSalesMonthLogAdd() {
  yield takeLatest(salesMonthLogAction.salesMonthLogAdd, salesMonthLogAdd);
}


function* rootSaga() {
  yield all([
    fork(watchSalesMonthLogList),
    fork(watchSalesMonthLogAdd),
  ]);
}

export default rootSaga;
  