import * as apiLib from './api';

export const add = async ({ spaceId, paymentKey, customerId }) => {
  try {
    const data = {
      spaceId,
      paymentKey,
      customerId,
    };

    const result = await apiLib.fetchStoreApi('/customer-payment/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ customerIdList }) => {
  try {
    const data = {
      customerIdList
    };

    const result = await apiLib.fetchStoreApi('/customer-payment/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};


export const edit = async ({ customerId, spaceId, paymentKeyList }) => {
  try {
    const data = {
      customerId,
      spaceId,
      paymentKeyList,
    };

    const result = await apiLib.fetchStoreApi('/customer-payment/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};


export const checkRfidDuplicate = async ({ spaceId, paymentKey }) => {
  try {
    const data = {
      spaceId,
      paymentKey,
    };

    const result = await apiLib.fetchStoreApi('/customer-payment/rfid-duplicate-check', data);
    if (result.resultFlag) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    throw err;
  }
};
