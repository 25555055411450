import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/frame/list', data);
    if (result.resultFlag) {
      return result.frameList;
    } else {
      throw Object.assign(new Error('frame list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/frame/detail', data);
    if (result.resultFlag) {
      return result.frameInfo;
    } else {
      throw Object.assign(new Error('frame detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/frame/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/frame/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/frame/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('frame remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
