import dayjs from 'dayjs';
import React, { useCallback, useState, forwardRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

import ClickOutside from './ClickOutside';

import { ReactComponent as SearchCalendarIcon } from '../assets/images/common/icon-calendar.svg';
// import { ReactComponent as SearchIcon } from '../assets/images/common/icon-search.svg';

import useCustomSearchParams from './useCustomSearchParams';
import { useMediaQuery } from 'react-responsive';

const DateSearch = ({ initSatrtDt, initEndDt, disableToday = false, disableWeek = false, disableMonth = false, disableRange = true }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 950px)' });

  const [searchParams, setSearchParams] = useCustomSearchParams();
  const qsStartDt = searchParams['startDt'] || initSatrtDt;
  const qsEndDt = searchParams['endDt'] || initEndDt;

  const [searchType, setSearchType] = useState('CUSTOM');
  const [startDate, setStartDate] = useState(dayjs(qsStartDt).startOf('date').toDate());
  const [endDate, setEndDate] = useState(dayjs(qsEndDt).endOf('date').toDate());
  const [inputDate, setInputDate] = useState('');
  const [isValidate, setIsValidate] = useState(true);
  const [isEditMode, setIsEditMode] = useState({
    start: false,
    end: false,
  });

  useEffect(() => {
    if (endDate) {
      if (dayjs(startDate).isSame(dayjs(), 'date') && dayjs(endDate).isSame(dayjs(), 'date') && !disableToday) {
        setSearchType('TODAY');
      } else if (dayjs(startDate).isSame(dayjs().subtract(1, 'week'), 'date') && dayjs(endDate).isSame(dayjs(), 'date') && !disableWeek) {
        setSearchType('WEEK');
      } else if (dayjs(startDate).isSame(dayjs(endDate).subtract(1, 'month'), 'date') && dayjs(endDate).isSame(dayjs(), 'date') && !disableMonth) {
        setSearchType('MONTH');
      } else {
        setSearchType('CUSTOM');
      }
    }
  }, [disableMonth, disableToday, disableWeek, endDate, startDate]);

  // 조회
  const handleSearch = useCallback(
    (startDt, endDt) => {
      let dayjsStartDate = dayjs(startDt).startOf('date');
      let dayjsEndDate = dayjs(endDt).endOf('date');

      if (!dayjsStartDate || !dayjsEndDate || dayjsStartDate.isAfter(dayjsEndDate, 'date') || dayjsEndDate.isBefore(dayjsStartDate, 'date')) {
        toast.error('검색 기간을 재입력하세요.');
        setIsValidate(false);
        return;
      } else if (dayjsStartDate.isAfter(dayjs(), 'date') || dayjsEndDate.isAfter(dayjs(), 'date')) {
        if (disableRange) {
          toast.error('데이터는 현재 날짜까지 조회 가능합니다.');
          setIsValidate(false);
          return;
        }
      } else if (dayjsStartDate.isBefore(dayjs().subtract(1, 'year')) || dayjsEndDate.isBefore(dayjs().subtract(1, 'year'))) {
          toast.error('데이터는 최대 1년까지 조회 가능합니다.');
          setIsValidate(false);
          return;
      } else if (!(dayjsEndDate.diff(dayjsStartDate, 'day') <= 31 && dayjsStartDate.isBefore(dayjsEndDate))) {
          toast.error('데이터는 한 달 단위로 조회 가능합니다.');
          setIsValidate(false);
          return;
      }
      setIsValidate(true);
      setStartDate(dayjsStartDate.toDate());
      setEndDate(dayjsEndDate.toDate());

        setSearchParams({ ...searchParams, startDt: dayjs(dayjsStartDate).format('YYYYMMDD'), endDt: dayjs(dayjsEndDate).format('YYYYMMDD'), curPage: 0 });
      /*if (searchParams['curPage']) {

      } else {
        setSearchParams({ ...searchParams, startDt: dayjs(dayjsStartDate).format('YYYYMMDD'), endDt: dayjs(dayjsEndDate).format('YYYYMMDD') });
      }*/
    },
    [setSearchParams, searchParams],
  );

  const handleChangeDateInput = useCallback(target => {
    const idx = target.value.indexOf('.');
    if (idx > -1) {
      target.value = target.value.slice(0, idx);
    } else if (target.value.length > target.maxLength) {
      target.value = target.value.slice(0, target.maxLength);
    }
    setInputDate(target.value);
  }, []);

  const handleClickOutside = useCallback(
    e => {
      const inputDt = dayjs(inputDate);

      if (inputDate !== '') {
        if (inputDt.isValid() && inputDate.length === 8 && !inputDt.isBefore(dayjs().subtract(1, 'year')) && !inputDt.isAfter(dayjs())) {
          if (isEditMode.start) {
            setStartDate(startDate => inputDt.toDate());
            if (e && e.key === 'Enter') {
              handleSearch(inputDt.toDate(), endDate);
            }
          } else {
            setEndDate(endDate => inputDt.toDate());
            if (e && e.key === 'Enter') {
              handleSearch(startDate, inputDt.toDate());
            }
          }
        }
      }

      setInputDate('');
      setIsEditMode({ start: false, end: false });

      if (!endDate || !startDate) {
        setIsValidate(false);
      }
    },
    [startDate, endDate, inputDate, isEditMode, handleSearch],
  );

  const handleEditMode = useCallback(
    type => {
      // 날짜 입력 후 바로 다른 날짜 수정으로 넘어가는 경우
      if (inputDate !== '') {
        const inputDt = dayjs(inputDate);
        if (type === 'start') {
          if (isEditMode.end) {
            if (inputDt.isValid() && inputDate.length === 8 && !inputDt.isBefore(dayjs().subtract(1, 'year')) && !inputDt.isAfter(dayjs())) {
              setEndDate(dayjs(inputDate).toDate());
            }
            setInputDate('');
            setIsEditMode({ start: true, end: false });
            return;
          }
        } else {
          if (isEditMode.start) {
            if (inputDt.isValid() && inputDate.length === 8 && !inputDt.isBefore(dayjs().subtract(1, 'year')) && !inputDt.isAfter(dayjs())) {
              setStartDate(dayjs(inputDate).toDate());
            }
            setInputDate('');
            setIsEditMode({ start: false, end: true });
            return;
          }
        }
      } else {
        if (type === 'start') {
          if (isEditMode.end) {
            setIsEditMode({ start: true, end: false });
            return;
          }
        } else {
          if (isEditMode.start) {
            setIsEditMode({ start: false, end: true });
            return;
          }
        }
      }

      const newMode = {
        ...isEditMode,
        [type]: true,
      };
      setIsEditMode(newMode);
    },
    [isEditMode, inputDate],
  );

  const handleChangeDate = useCallback(
    dates => {
      const [start, end] = dates;

      if (start && end) {
        handleSearch(start, end);
      }

      setStartDate(start);
      setEndDate(end);
    },
    [handleSearch],
  );

  const CustomInput = forwardRef(({ value, onClick, flag, isValidate, index }, ref) => (
    <StyledButton
      onClick={e => {
        setInputDate('');
        setIsEditMode({ start: false, end: false });
        onClick();
      }}
      ref={ref}
    >
      <SearchCalendarIcon />
    </StyledButton>
  ));

  return (
    <ToolbarArea isMobile={isMobile}>
      {!(disableToday && disableWeek && disableMonth) && (
        <ButtonArea isMobile={isMobile}>
          {!disableToday && (
            <SquareButton isSelected={searchType === 'TODAY'} onClick={() => handleSearch(dayjs().startOf('date').toDate(), dayjs().endOf('date').toDate())}>
              오늘
            </SquareButton>
          )}
          {!disableWeek && (
            <SquareButton isSelected={searchType === 'WEEK'} onClick={() => handleSearch(dayjs().subtract(1, 'week').startOf('date').toDate(), dayjs().endOf('date').toDate())}>
              1주
            </SquareButton>
          )}
          {!disableMonth && (
            <SquareButton isSelected={searchType === 'MONTH'} onClick={() => handleSearch(dayjs().subtract(1, 'month').startOf('date').toDate(), dayjs().endOf('date').toDate())}>
              1개월
            </SquareButton>
          )}
          <SquareButton isSelected={searchType === 'CUSTOM'}>기간조회</SquareButton>
        </ButtonArea>
      )}
      <DatePickerArea isMobile={isMobile}>
        <SquareDiv isValidate={isValidate} onClickOutside={() => handleClickOutside()}>
          {isEditMode.start && (
            <input
              type="number"
              className="date-input start"
              value={inputDate || ''}
              maxLength="8"
              placeholder="YYYYMMDD"
              onChange={e => handleChangeDateInput(e.target)}
              onKeyPress={e => e.key === 'Enter' && handleClickOutside(e)}
              autoFocus
            />
          )}
          {!isEditMode.start && (
            <label className="date-label" onClick={() => handleEditMode('start')}>
              {startDate === null ? 'YYYYMMDD' : dayjs(startDate).format('YYYY-MM-DD')}
            </label>
          )}
          <span>~</span>
          {isEditMode.end && (
            <input
              type="number"
              className="date-input end"
              value={inputDate || ''}
              maxLength="8"
              placeholder="YYYYMMDD"
              onChange={e => handleChangeDateInput(e.target)}
              onKeyPress={e => e.key === 'Enter' && handleClickOutside(e)}
              autoFocus
            />
          )}
          {!isEditMode.end && (
            <label className="date-label" onClick={() => handleEditMode('end')}>
              {endDate === null ? 'YYYYMMDD' : dayjs(endDate).format('YYYY-MM-DD')}
            </label>
          )}
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={date => handleChangeDate(date)}
            minDate={dayjs().subtract(1, 'year').toDate()}
            maxDate={dayjs().add(1, 'year').toDate()}
            selectsRange
            customInput={<CustomInput />}
          />
        </SquareDiv>
      </DatePickerArea>
      {/* <SearchButton onClick={() => handleSearch(startDate, endDate)}>
        <SearchIcon />
        조회
      </SearchButton> */}
    </ToolbarArea>
  );
};

const ToolbarArea = styled.div`
  display: flex;
  gap: 10px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      width: 100%;
    `};
`;

// const SearchButton = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 75px;
//   height: 38px;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 1.5;
//   color: #ffffff;
//   background-color: #41a1ea;
//   border: 1px solid #cccccc;
//   border-radius: 5px;
//   gap: 2px;

//   svg {
//     path {
//       fill: #ffffff;
//     }
//   }
// `;

const ButtonArea = styled.div`
  display: flex;
  gap: 10px;

  justify-content: ${({ isMobile }) => (isMobile ? 'flex-end' : 'center')};
  width: 100%;

  & button {
    ${({ isMobile }) =>
      isMobile &&
      css`
        font-size: 10px;
        height: 34px;
      `};
  }
`;

const DatePickerArea = styled.div`
  display: flex;
  align-items: center;

  justify-content: ${({ isMobile }) => (isMobile ? 'flex-end' : 'center')};
`;

const SquareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 38px;
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: #2a91df;
      border: 2px solid rgb(65, 161, 234);
    `}
`;

const StyledButton = styled.button`
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin-left: 10px;
  padding: 0;
`;

const SquareDiv = styled(ClickOutside)`
  display: flex;
  align-items: center;
  width: 245px;
  height: 38px;
  border: 1px solid ${({ isValidate }) => (isValidate ? '#cccccc' : '#FF3F3F')};
  border-radius: 5px;
  padding: 0 12px;

  & > input {
    outline: none;
  }

  & > input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > span {
    margin: 0 15px;
  }

  & .date-input,
  .date-label {
    width: 75px;
    border: 0;
    font-size: 14px;
    color: #000000;
    padding: 0;
  }

  & .date-input::placeholder {
    font-size: 13px;
  }
`;

export default React.memo(DateSearch);
