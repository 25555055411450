import { baseApi } from './baseApi';

const categoryApi = baseApi.injectEndpoints({
  endpoints: build => ({
    categoryList: build.query({
      query: data => ({ url: '/category/list', data }),
      transformResponse: (response, meta, arg) => {
        let categoryList = response.categoryList || [];

        if (arg.delYn === 'N') {
          categoryList = categoryList.filter(category => category.delYn === 'N');
        } else if (arg.delYn === 'Y') {
          categoryList = categoryList.filter(category => category.delYn === 'Y');
        }

        return [...categoryList].sort((a, b) => a.categoryOrder - b.categoryOrder);
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useCategoryListQuery } = categoryApi;
