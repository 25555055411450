import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface OverlayDataState {
  overlayId: string;
  overlayOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
  lockYn: string;
}

interface OverlayState {
  overlayList: Array<OverlayDataState>;
  isLoading: boolean;
  error: string | null;
}

const initialState: OverlayState = {
  overlayList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    stateClear(state: OverlayState, { payload }: PayloadAction<String>) {
      state.overlayList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    setOverlayList(state: OverlayState, { payload: { overlayList } }: PayloadAction<OverlayState>) {
      state.overlayList = overlayList;
    },
  },
});

const selectOverlayList = createDraftSafeSelector(
  (state: OverlayState) => state.overlayList,
  overlayList => [...overlayList].sort((a, b) => a.overlayOrder - b.overlayOrder),
);

const selectStatus = createDraftSafeSelector(
  (state: OverlayState) => state.actionResult,
  (state: OverlayState) => state.isLoading,
  (state: OverlayState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const overlaySelector = {
  overlayList: state => selectOverlayList(state[OVERLAY]),
  status: state => selectStatus(state[OVERLAY]),
};

export const OVERLAY = slice.name;
export const overlayReducer = slice.reducer;
export const overlayAction = slice.actions;
