import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { accountAction } from './accountSlice';

import * as accountApiLib from '../lib/accountApi';

function* handleDetail({ payload }) {
  try {
    const { accountInfo, memberInfo } = yield call(
      accountApiLib.detail,
      payload,
    );

    yield put(accountAction.detailSuccess({ accountInfo, memberInfo }));
  } catch (err) {
    yield put(accountAction.detailFailure('Detail Error'));
  }
}

function* handleEdit({ payload }) {
  try {
    yield call(accountApiLib.edit, payload);

    yield put(accountAction.editSuccess(payload));
  } catch (err) {
    yield put(accountAction.editFailure('Edit Error'));
  }
}

function* handleRemove({ payload }) {
  try {
    yield call(accountApiLib.remove, payload);

    yield put(accountAction.removeSuccess());
  } catch (err) {
    yield put(accountAction.removeFailure('Remove Error'));
  }
}

function* handlePasswordChange({ payload }) {
  try {
    yield call(accountApiLib.passwordChange, payload);

    yield put(accountAction.passwordChangeSuccess());
  } catch (err) {
    yield put(accountAction.passwordChangeFailure('Password Change Error'));
  }
}

function* handleImageUpload({ payload }) {
  try {
    const accountInfo = yield call(accountApiLib.upload, payload);

    yield put(accountAction.imageUploadSuccess({ accountInfo }));
  } catch (err) {
    yield put(accountAction.imageUploadFailure('Image Upload Error'));
  }
}

export function* watchDetail() {
  yield takeLatest(accountAction.detail, handleDetail);
}

export function* watchEdit() {
  yield takeLatest(accountAction.edit, handleEdit);
}

export function* watchRemove() {
  yield takeLatest(accountAction.remove, handleRemove);
}

export function* watchPasswordChange() {
  yield takeLatest(accountAction.passwordChange, handlePasswordChange);
}

export function* watchImageUpload() {
  yield takeLatest(accountAction.imageUpload, handleImageUpload);
}

function* rootSaga() {
  yield all([
    fork(watchDetail),
    fork(watchEdit),
    fork(watchRemove),
    fork(watchPasswordChange),
    fork(watchImageUpload),
  ]);
}

export default rootSaga;
