import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CustomerPayload {
  placeId: string;
  customerId: string;
  customerNo: string;
  customerNm: string;
  customerList: Array<CustomerInfo>;
}

interface FilterInfo {
  groupNm: string;
  customerNm: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface CustomerState {
  filterInfo: FilterInfo;
  pageInfo: PageInfo;
  customerInfo: CustomerInfo;
  customerList: Array<CustomerInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const customerInitialState: CustomerState = {
  filterInfo: {},
  pageInfo: { rowsPerPage: 10, curPage: 1 },
  customerInfo: {},
  customerList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  customerList: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerListSuccess: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerState>,
  ) => {
    state.actionResult = 'LIST_OK';
    state.customerList = payload.customerList;
    state.isLoading = false;
    state.error = null;
  },
  customerListFailure: (
    state: CustomerState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  customerInfo: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerInfoSuccess: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerState>,
  ) => {
    state.customerInfo = payload.customerInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  customerInfoFailure: (
    state: CustomerState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  customerSave: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'SAVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerSaveSuccess: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerState>,
  ) => {
    state.actionResult = 'SAVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  customerSaveFailure: (
    state: CustomerState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'SAVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  customerAdd: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerAddSuccess: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerState>,
  ) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  customerAddFailure: (state: CustomerState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  customerRemove: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerRemoveSuccess: (state: CustomerState, { payload }: PayloadAction<any>) => {
    state.customerList = payload.customerList;
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  customerRemoveFailure: (
    state: CustomerState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  customerUpdate: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerPayload>,
  ) => {
    state.actionResult = 'UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  customerUpdateSuccess: (
    state: CustomerState,
    { payload }: PayloadAction<CustomerState>,
  ) => {
    state.actionResult = 'UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  customerUpdateFailure: (
    state: CustomerState,
    action: PayloadAction<string>,
  ) => {
    state.actionResult = 'UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: CustomerState) => {
    state.actionResult = '';
  },
  customerStateClear: (state: GoodsState) => {
    state.filterInfo = {};
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
    state.customerInfo = {};
    state.customerList = [];
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  customerPage: (
    state: DeviceState,
    { payload }: PayloadAction<DevicePayload>,
  ) => {
    state.pageInfo.rowsPerPage = payload.rowsPerPage;
    state.pageInfo.curPage = payload.curPage;
  },
  customerFilter(state: DeviceState, { payload: { useYn, customerNm, customerNo } }: PayloadAction<PlaylistInfoState>) {
    state.filterInfo.useYn = useYn;
    state.filterInfo.customerNm = customerNm;
    state.filterInfo.customerNo = customerNo;
  },
};

const slice = createSlice({
  name: 'customer',
  initialState: customerInitialState,
  reducers: reducers,
});

const selectFilterInfo = createDraftSafeSelector(
  (state: BoardState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectPageInfo = createDraftSafeSelector(
  (state: BoardState) => state.pageInfo,
  pageInfo => {
    return {
      rowsPerPage: pageInfo.rowsPerPage,
      curPage: pageInfo.curPage,
    };
  },
);

const selectCustomerInfo = createDraftSafeSelector(
  (state: CustomerState) => state.customerInfo,
  customerInfo => customerInfo,
);

const selectCustomerList = createDraftSafeSelector(
  (state: CustomerState) => state.customerList,
  customerList => customerList,
);

const selectPageCustomerList = createDraftSafeSelector(
  (state: CustomerState) => state.filterInfo,
  (state: CustomerState) => state.pageInfo,
  (state: CustomerState) => state.customerList,
  (filterInfo, pageInfo, customerList) => {
    if (
      (filterInfo.useYn && filterInfo.useYn.trim() !== '') ||
      (filterInfo.customerNm && filterInfo.customerNm.trim() !== '') ||
      (filterInfo.customerNo && filterInfo.customerNo.trim() !== '')
    ) {
      const filterCustomerList = customerList.filter(customer => {
        let customerFilter = true;
        let useYnFilter = true;

        if (filterInfo.customerNm && filterInfo.customerNm !== '') {
          customerFilter = customer.customerNm.indexOf(filterInfo.customerNm) >= 0 || customer.customerNo === filterInfo.customerNo;
        }

        if (filterInfo.useYn && filterInfo.useYn !== '') {
          useYnFilter = customer.useYn === filterInfo.useYn;
        }

        return useYnFilter && customerFilter;
      });

      const totalCount = filterCustomerList.length;
      const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
      const endIndex = totalCount - startIndex;
      const totalPages =
        filterCustomerList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(filterCustomerList.length / pageInfo.rowsPerPage) + 1
          : Math.floor(filterCustomerList.length / pageInfo.rowsPerPage);

      const sliceList = filterCustomerList.slice(
        pageInfo.rowsPerPage * (pageInfo.curPage - 1),
        pageInfo.rowsPerPage * pageInfo.curPage,
      );

      return {
        totalCount,
        startIndex,
        endIndex,
        totalPages,
        customerList: sliceList,
      };
    }

    const totalCount = customerList.length;
    const startIndex = pageInfo.rowsPerPage * (pageInfo.curPage - 1);
    const endIndex = totalCount - startIndex;
    const totalPages =
      customerList.length % pageInfo.rowsPerPage > 0
        ? Math.floor(customerList.length / pageInfo.rowsPerPage) + 1
        : Math.floor(customerList.length / pageInfo.rowsPerPage);

    const sliceList = customerList.slice(
      pageInfo.rowsPerPage * (pageInfo.curPage - 1),
      pageInfo.rowsPerPage * pageInfo.curPage,
    );

    return {
      totalCount,
      startIndex,
      endIndex,
      totalPages,
      customerList: sliceList,
    };
  },
);

const selectStatus = createDraftSafeSelector(
  (state: CustomerState) => state.actionResult,
  (state: CustomerState) => state.isLoading,
  (state: CustomerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const customerSelector = {
  filterInfo: state => selectFilterInfo(state[CUSTOMER]),
  pageInfo: state => selectPageInfo(state[CUSTOMER]),
  customerInfo: state => selectCustomerInfo(state[CUSTOMER]),
  customerList: state => selectCustomerList(state[CUSTOMER]),
  pageCustomerList: state => selectPageCustomerList(state[CUSTOMER]),
  status: state => selectStatus(state[CUSTOMER]),
};

export const CUSTOMER = slice.name;
export const customerReducer = slice.reducer;
export const customerAction = slice.actions;
