import axios from 'axios';
import produce from 'immer';

const developmentUrl = 'https://faas.eligaws.com/store';
const productionUrl = 'https://faas.eligaspace.com/store';
const localUrl = 'http://localhost:8005/store';

export const fetchStoreApi = async (url, data, options = {}) => {
  try {
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = sessionStorage.getItem('accessToken');

    data = produce(data, draft => {
      draft.accessToken = accessToken;
    });

    let apiUrl = '';
    // if (process.env.NODE_ENV !== 'production') {
    if (window.location.href.includes('eligaws')) {
      apiUrl = developmentUrl;
    } else if (window.location.href.includes('eligaspace')) {
      apiUrl = productionUrl;
    } else {
      apiUrl = localUrl;
    }

    const response = await axios({
      method: 'post',
      url: apiUrl + url,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'application/json',
        ...options.headers,
      },
      data: data,
    }).catch(err => {
      if (err.response.status === 401) {
        window.location.href = '/auth/login';
      } else {
        throw err;
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchStoreMultipartApi = async (url, data, options = {}) => {
  try {
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = sessionStorage.getItem('accessToken');

    let apiUrl = '';
    if (window.location.href.includes('eligaws')) {
      apiUrl = developmentUrl;
    } else if (window.location.href.includes('eligaspace')) {
      apiUrl = productionUrl;
    } else {
      apiUrl = localUrl;
    }

    const response = await axios({
      method: 'post',
      url: apiUrl + url + `?accessToken=${accessToken}`,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'multipart/form-data',
        ...options.headers,
      },
      data: data,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};

export const fetchUploadApi = async (url, file, onUploadProgress, i, fileId) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, i, fileId);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};

// 2023.09.08 - swhong
// 수기입력 (엑셀파일 업로드) 시 처리 시간이 길어 타임아웃 설정이 없으면 프로세스가 정상적으로 종료가 안되기 때문에 타임아웃 설정 추가
export const fetchStoreMultipartApiTimeout = async (url, data, options, timeout = {}) => {
  try {
    // const accessToken = localStorage.getItem('accessToken');
    const accessToken = sessionStorage.getItem('accessToken');

    let apiUrl = '';
    if (window.location.href.includes('eligaws')) {
      apiUrl = developmentUrl;
    } else if (window.location.href.includes('eligaspace')) {
      apiUrl = productionUrl;
    } else {
      apiUrl = localUrl;
    }

    const response = await axios({
      method: 'post',
      url: apiUrl + url + `?accessToken=${accessToken}`,
      headers: {
        'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
        'content-type': 'multipart/form-data',
        ...options.headers,
      },
      data: data,
      timeout: timeout,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw Object.assign(new Error('api error'), {
        status: response.status,
      });
    }
  } catch (err) {
    throw err;
  }
};