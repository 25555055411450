import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { shopAction } from './shopSlice';

import * as shopApiLib from '../lib/shopApi';

function* shopInfo({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.detail, payload);

    yield put(shopAction.shopInfoSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopInfoFailure('Shop Info Error'));
  }
}

function* shopSameChk({ payload }) {
  try {
    const result = yield call(shopApiLib.sameChk, payload);
    console.log('result : ', result);

    yield put(shopAction.shopSameChkSuccess({ result }));
  } catch (err) {
    yield put(shopAction.shopSameChkFailure('Shop Info Error'));
  }
}

function* shopList({ payload }) {
  try {
    const { permissionInfo, shopInfo, shopList } = yield call(shopApiLib.list, payload);

    yield put(shopAction.shopListSuccess({ permissionInfo, shopInfo, shopList }));
  } catch (err) {
    yield put(shopAction.shopListFailure('Shop List Error'));
  }
}

function* shopAdd({ payload }) {
  try {
    yield call(shopApiLib.add, payload);

    yield put(shopAction.shopAddSuccess());
  } catch (err) {
    yield put(shopAction.shopAddFailure('Shop Add Error'));
  }
}

function* shopEdit({ payload }) {
  try {
    yield call(shopApiLib.edit, payload);

    yield put(shopAction.shopEditSuccess(payload));
  } catch (err) {
    yield put(shopAction.shopEditFailure('Shop Edit Error'));
  }
}

function* shopRemove({ payload }) {
  try {
    yield call(shopApiLib.remove, payload);

    yield put(shopAction.shopRemoveSuccess());
  } catch (err) {
    yield put(shopAction.shopRemoveFailure('Shop Remove Error'));
  }
}

function* shopChange({ payload }) {
  try {
    const { permissionInfo, shopInfo } = yield call(shopApiLib.change, payload);

    yield put(shopAction.shopChangeSuccess({ permissionInfo, shopInfo }));
  } catch (err) {
    yield put(shopAction.shopChangeFailure('Place Change Error'));
  }
}

function* shopImageUpload({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.upload, payload);

    yield put(shopAction.shopImageUploadSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopImageUploadFailure('Shop Image Upload Error'));
  }
}

function* memberList({ payload }) {
  try {
    const { memberList, accountList } = yield call(shopApiLib.memberList, payload);

    yield put(shopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(shopAction.memberListFailure('Member List Error'));
  }
}

function* memberDetail({ payload }) {
  try {
    const memberInfo = yield call(shopApiLib.memberDetail, payload);

    yield put(shopAction.memberDetailSuccess({ memberInfo }));
  } catch (err) {
    yield put(shopAction.memberDetailFailure('Member Detail Error'));
  }
}

function* memberAdd({ payload }) {
  try {
    yield call(shopApiLib.memberAdd, payload);
    yield put(shopAction.memberAddSuccess());

    const { memberList, accountList } = yield call(shopApiLib.memberList, payload);
    yield put(shopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(shopAction.memberAddFailure('Member Add Error'));
  }
}

function* memberEdit({ payload }) {
  try {
    yield call(shopApiLib.memberEdit, payload);
    yield put(shopAction.memberEditSuccess());

    const { memberList, accountList } = yield call(shopApiLib.memberList, payload);
    yield put(shopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(shopAction.memberEditFailure('Member Edit Error'));
  }
}

function* memberRemove({ payload }) {
  try {
    yield call(shopApiLib.memberRemove, payload);
    yield put(shopAction.memberRemoveSuccess());

    const { memberList, accountList } = yield call(shopApiLib.memberList, payload);
    yield put(shopAction.memberListSuccess({ memberList, accountList }));
  } catch (err) {
    yield put(shopAction.memberRemoveFailure('Member Remove Error'));
  }
}

function* playerInfo() {
  try {
    const playerInfo = yield call(shopApiLib.player);

    yield put(shopAction.playerInfoSuccess({ playerInfo }));
  } catch (err) {
    yield put(shopAction.playerInfoFailure('Player Info Error'));
  }
}

function* shopTotalUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.totalUpdate, payload);
    yield put(shopAction.shopTotalUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopTotalUpdateFailure('shopTotalUpdate Error'));
  }
}

function* shopAutoOpenUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.autoOpenUpdate, payload);
    yield put(shopAction.shopAutoOpenUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopAutoOpenUpdateFailure('shopAutoOpenUpdate Error'));
  }
}

function* shopDeliveryOrderCntUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.deliveryOrderCntUpdate, payload);
    yield put(shopAction.shopDeliveryOrderCntUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopDeliveryOrderCntUpdateFailure('shopDeliveryOrderCntUpdate Error'));
  }
}
function* shopRobotDeliveryOrderCntUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.robotDeliveryOrderCntUpdate, payload);
    yield put(shopAction.shopRobotDeliveryOrderCntUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopRobotDeliveryOrderCntUpdateFailure('shopRobotDeliveryOrderCntUpdate Error'));
  }
}

function* shopDeliveryZoneUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.deliveryZoneUpdate, payload);
    yield put(shopAction.shopDeliveryZoneUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopDeliveryZoneUpdateFailure('shopDeliveryZoneUpdate Error'));
  }
}
function* shopRobotDeliveryZoneUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.robotDeliveryZoneUpdate, payload);
    yield put(shopAction.shopRobotDeliveryZoneUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopRobotDeliveryZoneUpdateFailure('shopRobotDeliveryZoneUpdate Error'));
  }
}
function* shopRobotDeliveryTimeoutUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.robotDeliveryTimeoutUpdate, payload);
    yield put(shopAction.shopRobotDeliveryTimeoutUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopRobotDeliveryTimeoutUpdateFailure('shopRobotDeliveryTimeoutUpdate Error'));
  }
}
function* shopPickupTypeListUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.pickupTypeListUpdate, payload);
    yield put(shopAction.shopPickupTypeListUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopPickupTypeListUpdateFailure('shopPickupTypeListUpdate Error'));
  }
}

function* shopDeliveryTimeListUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.deliveryTimeListUpdate, payload);
    yield put(shopAction.shopDeliveryTimeListUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopDeliveryTimeListUpdateFailure('shopDeliveryTimeListUpdate Error'));
  }
}

function* shopAlarmUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.alarmUpdate, payload);
    yield put(shopAction.shopAlarmUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopAlarmFailure('shopAlarmUpdateFailure'));
  }
}

export function* watchShopAlarm() {
  yield takeLatest(shopAction.shopAlarmUpdate, shopAlarmUpdate);
}

function* shopPickupLedUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.pickupLedUpdate, payload);
    yield put(shopAction.shopPickupLedUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopPickupLedFailure('shopPickupLedUpdateFailure'));
  }
}

function* shopMenuSurveyUpdate({ payload }) {
  try {
    const shopInfo = yield call(shopApiLib.menuSurveyUpdate, payload);
    yield put(shopAction.shopMenuSurveyUpdateSuccess({ shopInfo }));
  } catch (err) {
    yield put(shopAction.shopMenuSurveyUpdateFailure('shopMenuSurveyUpdateFailure'));
  }
}

export function* watchShopMenuSurvey() {
  yield takeLatest(shopAction.shopMenuSurveyUpdate, shopMenuSurveyUpdate);
}

export function* watchShopPickupLed() {
  yield takeLatest(shopAction.shopPickupLedUpdate, shopPickupLedUpdate);
}

export function* watchShopInfo() {
  yield takeLatest(shopAction.shopInfo, shopInfo);
}

export function* watchShopSameChk() {
  yield takeLatest(shopAction.shopSameChk, shopSameChk);
}

export function* watchShopList() {
  yield takeLatest(shopAction.shopList, shopList);
}

export function* watchShopAdd() {
  yield takeLatest(shopAction.shopAdd, shopAdd);
}

export function* watchShopEdit() {
  yield takeLatest(shopAction.shopEdit, shopEdit);
}

export function* watchShopRemove() {
  yield takeLatest(shopAction.shopRemove, shopRemove);
}

export function* watchShopChange() {
  yield takeLatest(shopAction.shopChange, shopChange);
}

export function* watchShopImageUpload() {
  yield takeLatest(shopAction.shopImageUpload, shopImageUpload);
}

export function* watchMemberList() {
  yield takeLatest(shopAction.memberList, memberList);
}

export function* watchMemberDetail() {
  yield takeLatest(shopAction.memberDetail, memberDetail);
}

export function* watchMemberAdd() {
  yield takeLatest(shopAction.memberAdd, memberAdd);
}

export function* watchMemberEdit() {
  yield takeLatest(shopAction.memberEdit, memberEdit);
}

export function* watchMemberRemove() {
  yield takeLatest(shopAction.memberRemove, memberRemove);
}

export function* watchPlayerInfo() {
  yield takeLatest(shopAction.playerInfo, playerInfo);
}

export function* watchShopTotalUpdate() {
  yield takeLatest(shopAction.shopTotalUpdate, shopTotalUpdate);
}

export function* watchShopAutoOpenUpdate() {
  yield takeLatest(shopAction.shopAutoOpenUpdate, shopAutoOpenUpdate);
}
export function* watchShopDeliveryOrderCntUpdate() {
  yield takeLatest(shopAction.shopDeliveryOrderCntUpdate, shopDeliveryOrderCntUpdate);
}
export function* watchShopRobotDeliveryOrderCntUpdate() {
  yield takeLatest(shopAction.shopRobotDeliveryOrderCntUpdate, shopRobotDeliveryOrderCntUpdate);
}

export function* watchShopDeliveryZoneUpdate() {
  yield takeLatest(shopAction.shopDeliveryZoneUpdate, shopDeliveryZoneUpdate);
}
export function* watchShopRobotDeliveryZoneUpdate() {
  yield takeLatest(shopAction.shopRobotDeliveryZoneUpdate, shopRobotDeliveryZoneUpdate);
}
export function* watchShopPickupTypeListUpdate() {
  yield takeLatest(shopAction.shopPickupTypeListUpdate, shopPickupTypeListUpdate);
}
export function* watchShopRobotDeliveryTimeoutUpdate() {
  yield takeLatest(shopAction.shopRobotDeliveryTimeoutUpdate, shopRobotDeliveryTimeoutUpdate);
}

export function* watchShopDeliveryTimeListUpdate() {
  yield takeLatest(shopAction.shopDeliveryTimeListUpdate, shopDeliveryTimeListUpdate);
}

function* rootSaga() {
  yield all([
    fork(watchShopInfo),
    fork(watchShopSameChk),
    fork(watchShopList),
    fork(watchShopAdd),
    fork(watchShopEdit),
    fork(watchShopRemove),
    fork(watchShopChange),
    fork(watchShopImageUpload),
    fork(watchMemberList),
    fork(watchMemberDetail),
    fork(watchMemberAdd),
    fork(watchMemberEdit),
    fork(watchMemberRemove),
    fork(watchPlayerInfo),
    fork(watchShopAutoOpenUpdate),
    fork(watchShopTotalUpdate),
    fork(watchShopDeliveryOrderCntUpdate),
    fork(watchShopRobotDeliveryOrderCntUpdate),
    fork(watchShopDeliveryZoneUpdate),
    fork(watchShopRobotDeliveryZoneUpdate),
    fork(watchShopPickupTypeListUpdate),
    fork(watchShopRobotDeliveryTimeoutUpdate),
    fork(watchShopDeliveryTimeListUpdate),
    fork(watchShopAlarm),
    fork(watchShopPickupLed),
    fork(watchShopMenuSurvey),
  ]);
}

export default rootSaga;
