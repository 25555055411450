import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  flex: 1;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  gap: 55px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const TableHead = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-weight: 500;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;

  flex-basis: 45px;
  font-size: 15px;

  @media screen and (min-width: 768px) and (max-width: 1210px) {
    flex-basis: 40px;
    font-size: 13px;
  }

  @media only screen and (max-width: 787px) {
    flex-basis: 35px;
    font-size: 10px;
  }
`;
export const TableBody = styled.div`
  background: #ffffff;
  height: 100%;
  font-size: 14px;

  @media screen and (min-width: 768px) and (max-width: 1210px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 787px) {
    font-size: 10px;
  }
`;
export const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  width: 100%;
  height: 45px;
  background: #ffffff;
  color: ${({ isError }) => isError && '#ff3f3f'};

  @media screen and (min-width: 768px) and (max-width: 1210px) {
    height: 40px;
  }

  @media only screen and (max-width: 787px) {
    height: 35px;
  }

  & + & {
    border-top: 1px solid #eeeeee;
  }

  &:active {
    background-color: rgba(65, 161, 234, 0.2);
  }
`;
export const Col = styled.span`
  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;

  ${({ type }) => {
    switch (type) {
      case 'xs':
        return css`
          flex: 1;
        `;
      case 's':
        return css`
          flex: 2;
        `;
      case 'm':
        return css`
          flex: 3;
        `;
      case 'l':
        return css`
          flex: 4;
        `;
      case 'xl':
        return css`
          flex: 5;
        `;
      case 'xxl':
        return css`
          flex: 9;
        `;
      case 'btn':
        return css`
          flex-basis: 95px;
          flex-shrink: 0;
        `;
      case 'no':
        return css`
          flex-basis: 55px;
          flex-shrink: 0;
        `;
      default:
        return css`
          flex: 1;
        `;
    }
  }}
`;
export const TableBodyEmpty = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  font-size: 14px;
`;

export const PaginationArea = styled.div`
  margin-top: 30px;
`;
