import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { orderAction } from './orderSlice';

import * as orderApiLib from '../lib/orderApi';
import { shopSelector } from './shopSlice';

function* orderList({ payload }) {
  try {
    let isNext: boolean = true;
    let orderList = [];

    while (isNext) {
      const resultList = yield call(orderApiLib.list, { ...payload });
      if(orderList.length > 0) {
        orderList = orderList.concat(resultList.Items);
      } else {
        orderList = resultList.Items;
      }

      if(resultList.LastEvaluatedKey) {
        payload.evaluatedKey = resultList.LastEvaluatedKey;
      } else {
        isNext = false;
      }
    }

    console.log(orderList);

    yield put(orderAction.orderListSuccess({ orderList }));
  } catch (err) {
    yield put(orderAction.orderListFailure('Order List Error'));
  }
}

function* orderListPage({ payload }) {
  try {
    let isNext: boolean = true;
    let orderList = [];

    while (isNext) {
      const resultList = yield call(orderApiLib.listPage, { ...payload });
      if(orderList.length > 0) {
        orderList = orderList.concat(resultList.Items);
      } else {
        orderList = resultList.Items;
      }

      if(resultList.LastEvaluatedKey) {
        payload.evaluatedKey = resultList.LastEvaluatedKey;
      } else {
        isNext = false;
      }
    }

    console.log(orderList);

    yield put(orderAction.orderListSuccess({ orderList }));
  } catch (err) {
    yield put(orderAction.orderListFailure('Order List Error'));
  }
}

function* updateOrderState({ payload }) {
  try {
    const { shopId } = yield select(shopSelector.shopInfo);

    yield call(orderApiLib.updateOrderState, payload);

    const orderList = yield call(orderApiLib.list, { shopId, orderStartDt: payload.orderStartDt, orderEndDt: payload.orderEndDt });

    yield put(orderAction.updateOrderStateSuccess({ orderIdList: payload.orderIdList, orderList }));
  } catch (err) {
    console.log(err);
    yield put(orderAction.updateOrderStateFailure({ orderIdList: payload.orderIdList, error: 'Update Order State Error' }));
  }
}

function* orderOpenList({ payload }) {
  try {
    const orderList = yield call(orderApiLib.openList, payload);

    yield put(orderAction.orderOpenListSuccess({ orderList }));
  } catch (err) {
    console.log(`orderOpenList Saga Err`, err);
    yield put(orderAction.orderOpenListFailure('order OpenList Error'));
  }
}

function* saleOrderList({ payload }) {
  try {
    yield call(orderApiLib.saleOrderList, payload);

    yield put(orderAction.saleOrderListSuccess());
  } catch (err) {
    yield put(orderAction.saleOrderListFailure('Sale Order List Error'));
  }
}

function* orderCancel({ payload }) {
  try {
    yield call(orderApiLib.orderCancel, payload);
    const orderList = yield call(orderApiLib.list, { shopId: payload.shopId, orderStartDt: payload.orderStartDt, orderEndDt: payload.orderEndDt });
    yield put(orderAction.orderCancelSuccess({ orderList }));
  } catch (err) {
    yield put(orderAction.orderCancelFailure('Order Cancel Error'));
  }
}

export function* watchOrderList() {
  yield takeLatest(orderAction.orderList, orderList);
}

export function* watchOrderListPage() {
  yield takeLatest(orderAction.orderListPage, orderListPage);
}

export function* watchUpdateOrderState() {
  yield takeLatest(orderAction.updateOrderState, updateOrderState);
}

export function* watchorderOpenList() {
  yield takeLatest(orderAction.orderOpenList, orderOpenList);
}

export function* watchSaleOrderList() {
  yield takeLatest(orderAction.saleOrderList, saleOrderList);
}

export function* watchOrderCancel() {
  yield takeLatest(orderAction.orderCancel, orderCancel);
}

function* rootSaga() {
  yield all([
      fork(watchOrderList),
    fork(watchUpdateOrderState),
    fork(watchorderOpenList),
    fork(watchSaleOrderList),
    fork(watchOrderCancel),
      fork(watchOrderListPage),
  ]);
}

export default rootSaga;
