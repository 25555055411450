import * as apiLib from './api';

export const list = async ({ accountId }) => {
  try {
    const data = {
      accountId: accountId,
    };

    const result = await apiLib.fetchStoreApi('/shop/list', data);
    if (result.resultFlag) {
      return {
        permissionInfo: result.permissionInfo,
        shopInfo: result.shopInfo,
        shopList: result.shopList,
      };
    } else {
      throw Object.assign(new Error('shop list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async ({ shopId }) => {
  try {
    const data = {
      shopId: shopId,
    };

    const result = await apiLib.fetchStoreApi('/shop/detail', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const sameChk = async ({ shopId, openState, autoOpenState }) => {
  try {
    const data = {
      shopId: shopId,
      openState: openState,
      autoOpenState: autoOpenState,
    };

    const result = await apiLib.fetchStoreApi('/shop/sameChk', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('shop detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async ({ accountId, brandId, shopNm }) => {
  try {
    const data = {
      accountId: accountId,
      brandId: brandId,
      shopNm: shopNm,
    };

    const result = await apiLib.fetchStoreApi('/shop/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async ({ shopId, shopNm, shopManagerNm, shopManagerTel, shopRemark, shopOwner, businessNumber, shopAddr }) => {
  try {
    const data = {
      shopId,
      shopNm,
      shopManagerNm,
      shopManagerTel,
      shopRemark,
      shopOwner,
      businessNumber,
      shopAddr,
    };

    const result = await apiLib.fetchStoreApi('/shop/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({ shopId }) => {
  try {
    const data = {
      shopId: shopId,
    };

    const result = await apiLib.fetchStoreApi('/shop/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('shop remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const change = async ({ accountId, shopId }) => {
  try {
    const data = {
      accountId,
      shopId,
    };

    const result = await apiLib.fetchStoreApi('/shop/change', data);
    if (result.resultFlag) {
      return {
        permissionInfo: result.permissionInfo,
        shopInfo: result.shopInfo,
      };
    } else {
      throw Object.assign(new Error('shop change error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ shopId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('shopId', shopId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchStoreMultipartApi('/shop/image-upload', uploadData);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop upload error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberList = async ({ shopId }) => {
  try {
    const data = {
      shopId: shopId,
    };

    const result = await apiLib.fetchStoreApi('/shop/member-list', data);
    if (result.resultFlag) {
      return { memberList: result.memberList, accountList: result.accountList };
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberDetail = async ({ shopId, accountId }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
    };

    const result = await apiLib.fetchStoreApi('/shop/member-detail', data);
    if (result.resultFlag) {
      return result.memberInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberAdd = async ({ shopId, accountEmail, permissionType }) => {
  try {
    const data = {
      shopId: shopId,
      accountEmail: accountEmail,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchStoreApi('/shop/member-add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberEdit = async ({ shopId, accountId, permissionType }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
      permissionType: permissionType,
    };

    const result = await apiLib.fetchStoreApi('/shop/member-edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const memberRemove = async ({ shopId, accountId }) => {
  try {
    const data = {
      shopId: shopId,
      accountId: accountId,
    };

    const result = await apiLib.fetchStoreApi('/shop/member-remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const player = async () => {
  try {
    const data = {};

    const result = await apiLib.fetchStoreApi('/shop/player', data);
    if (result.resultFlag) {
      return result.playerInfo;
    } else {
      throw Object.assign(new Error('member error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const totalUpdate = async ({ shopId, openState, openChange, beforeOpenDt, autoOpenState, autoOpenTime, autoEndTime, autoWorkDay }) => {
  try {
    const data = {
      shopId,
      openState,
      openChange,
      beforeOpenDt,
      autoOpenState,
      autoOpenTime,
      autoEndTime,
      autoWorkDay,
    };
    console.log('data', data);

    const result = await apiLib.fetchStoreApi('/shop/total-state-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoStateUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const autoOpenUpdate = async ({ shopId, autoOpenState }) => {
  try {
    const data = {
      shopId,
      autoOpenState,
    };

    const result = await apiLib.fetchStoreApi('/shop/auto-open-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoOpenUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deliveryOrderCntUpdate = async ({ shopId, deliveryOrderCnt }) => {
  try {
    const data = {
      shopId,
      deliveryOrderCnt,
    };

    const result = await apiLib.fetchStoreApi('/shop/delivery-order-cnt-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoOpenUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const robotDeliveryOrderCntUpdate = async ({ shopId, robotDeliveryOrderCnt }) => {
  try {
    const data = {
      shopId,
      robotDeliveryOrderCnt,
    };

    const result = await apiLib.fetchStoreApi('/robot/robot-delivery-order-cnt-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop autoOpenUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deliveryZoneUpdate = async ({ shopId, deliveryZoneList }) => {
  try {
    const data = {
      shopId,
      deliveryZoneList,
    };

    const result = await apiLib.fetchStoreApi('/shop/delivery-zone-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop deliveryZoneUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const robotDeliveryZoneUpdate = async ({ shopId, robotDeliveryZoneList }) => {
  try {
    const data = {
      shopId,
      robotDeliveryZoneList,
    };

    const result = await apiLib.fetchStoreApi('/robot/robot-delivery-zone-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop robotDeliveryZoneUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const robotDeliveryTimeoutUpdate = async ({ shopId, robotDeliveryTimeout }) => {
  try {
    const data = {
      shopId,
      robotDeliveryTimeout,
    };

    const result = await apiLib.fetchStoreApi('/robot/robot-delivery-timeout-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop robotDeliveryTimeoutUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const pickupTypeListUpdate = async ({ shopId, pickupTypeList }) => {
  try {
    const data = {
      shopId,
      pickupTypeList,
    };

    const result = await apiLib.fetchStoreApi('/robot/pickup-type-list-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop pickupTypeListUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deliveryTimeListUpdate = async ({ shopId, deliveryTimeList }) => {
  try {
    const data = {
      shopId,
      deliveryTimeList,
    };

    const result = await apiLib.fetchStoreApi('/shop/delivery-time-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop deliveryTimeListUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const alarmUpdate = async ({ shopId, updateInfo }) => {
  try {
    const data = {
      shopId,
      updateInfo,
    };
    console.log('shopId', shopId, 'updateInfo', updateInfo);
    const result = await apiLib.fetchStoreApi('/shop/alarm-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop deliveryTimeListUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const pickupLedUpdate = async ({ shopId, updateInfo }) => {
  try {
    const data = {
      shopId,
      updateInfo,
    };
    console.log('shopId', shopId, 'updateInfo', updateInfo);
    const result = await apiLib.fetchStoreApi('/shop/pickup-led-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop pickupLedUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const menuSurveyUpdate = async ({ shopId, updateInfo }) => {
  try {
    const data = {
      shopId,
      updateInfo,
    };
    console.log('shopId', shopId, 'updateInfo', updateInfo);
    const result = await apiLib.fetchStoreApi('/shop/menu-survey-update', data);
    if (result.resultFlag) {
      return result.shopInfo;
    } else {
      throw Object.assign(new Error('shop menuSurveyUpdate error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};