import {
    createSlice,
    createDraftSafeSelector,
    PayloadAction,
  } from '@reduxjs/toolkit';
  
  interface ReportPayload {
    reportId: string;
    startDt: string;
    endDt: string;
  }
  
  interface ReportInfo {
    reportId: string;
    reportDt: string;
  }

  
  interface FilterInfo {
    startDt: string;
    endDt: string;
    mainCategory: string;
    subCategory: string;
  }

  interface ReportState {
    reportInfo: ReportInfo;
    reportList: Array<ReportInfo>;
    filterInfo: FilterInfo;
    actionResult: string;
    isLoading: boolean;
    error: string | null;
  }
  
  const reportInitialState: ReportState = {
    reportInfo: {},
    reportList: [],
    filterInfo: {startDt: '', endDt: '', mainCategory: 'all', subCategory: 'all'},
    actionResult: '',
    isLoading: false,
    error: null,
  };
  
  const reducers = {
    initReportState: (state: ReportState, {payload}: PayloadAction<ReportState>) => {
      state.reportList = [];
      state.filterInfo = {startDt: '', endDt: '', mainCategory: 'all', subCategory: 'all'};
    },

    reportList: (state: ReportState, { payload }: PayloadAction<ReportPayload>) => {
      state.actionResult = 'LIST_REQ';                          // TODO 띠용?
      state.filterInfo.startDt = payload.startDt;
      state.filterInfo.endDt = payload.endDt;
      state.isLoading = true;
      state.error = null;
    },
    reportListSuccess: (
      state: ReportState,
      { payload }: PayloadAction<ReportState>,
    ) => {
      state.reportList = payload.reportList;
      state.actionResult = 'LIST_OK';                       // todo 띠용
      state.isLoading = false;
      state.error = null;
    },
    reportListFailure: (state: ReportState, action: PayloadAction<string>) => {
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = action.payload;
    },
    reportFilter: (state: ReportState, { payload: { key, value } }: PayloadAction<ReportState>) => {
      state.filterInfo[key] = value;
    },
    actionResultClear: (state: AuthState) => {
      state.actionResult = '';
    },
  };
  
  const slice = createSlice({
    name: 'report',
    initialState: reportInitialState,
    reducers: reducers,
  });
  
  const selectReportList = createDraftSafeSelector(
    (state: ReportState) => state.reportList,
    reportList => reportList,
  );

  const selectReportFilter = createDraftSafeSelector(
    (state: ReportState) => state.filterInfo,
    filterInfo => filterInfo,
  );


  const selectFilterReportList = createDraftSafeSelector(
    (state: any) => state.report.reportList,
    (state: any) => state.report.filterInfo,
    (state: any) => state.goods.categoryListAll,

    (reportList, filterInfo, categoryList) => {

      if (filterInfo.mainCategory === 'all' && filterInfo.subCategory === 'all') {
        return reportList;
      }
  
      let filterList = [];

      reportList.map(report => {

        if(filterInfo.mainCategory !== 'all') {   // 대분류 들어왔나?
          let pushFlag = false;
          categoryList.forEach(category => {

            if(pushFlag){
              return filterList;
            }
            for(var subCategory in report.categoryCalc){
              if (subCategory === category.categoryId && category.categoryPid === filterInfo.mainCategory) {
                pushFlag = true;
                filterList.push(report);
                break;
              } 
            }
          })
        } else { 
          filterList = reportList;
        }

        // 하루씩 데이터 가 들어와서 소분류는 orderLog 에서
        if (filterInfo.subCategory !== 'all') {
          filterList = filterList.filter(filtered => filtered.categoryCalc.hasOwnProperty(filterInfo.subCategory));
        }

        return filterList;
      });
      return filterList;

    }
  );



  
  const selectStatus = createDraftSafeSelector(
    (state: ReportState) => state.actionResult,
    (state: ReportState) => state.isLoading,
    (state: ReportState) => state.error,
    (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
  );
  
  export const reportSelector = {
    reportList: state => selectReportList(state[REPORT]),
    reportFilter: state => selectReportFilter(state[REPORT]),
    filteredReportList: state => selectFilterReportList(state),
    status: state => selectStatus(state[REPORT]),
  };
  
  export const REPORT = slice.name;
  export const reportReducer = slice.reducer;
  export const reportAction = slice.actions;
  