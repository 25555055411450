import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const developmentUrl = 'https://faas.eligaws.com/store';
const productionUrl = 'https://faas.eligaspace.com/store';
const localUrl = 'http://localhost:8005/store';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method = 'POST', data }) => {
    try {
      // const accessToken = localStorage.getItem('accessToken');
      const accessToken = sessionStorage.getItem('accessToken');

      const response = await axios({
        method: 'post',
        url: baseUrl + url,
        headers: {
          'X-Api-Key': 'bCAoIUYQ8N1CZ4rMDPdvJ54HqeZwfgeF96NCYJhQ',
          'content-type': 'application/json',
        },
        data: { ...data, accessToken },
      }).catch(err => {
        if (err.response.status === 401) {
          window.location.href = '/account/login';
        } else {
          throw err;
        }
      });

      if (response.status === 200) {
        if (response.data.resultFlag) {
          return { data: response.data };
        } else {
          console.log('api error: ', response);
          throw Object.assign(new Error('api error'), {
            errorMsg: response.resultMsg
          });
        }
      } else {
        throw Object.assign(new Error('api error'), {
          status: response.status,
        });
      }
    } catch (err) {
      throw err;
    }
  };

const baseQuery = axiosBaseQuery({
  baseUrl: window.location.href.includes('eligaws.com') ? developmentUrl : window.location.href.includes('eligaspace.com') ? productionUrl : window.location.href.includes('eliga.co.kr') ? productionUrl : localUrl,
});

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQuery,
  endpoints: () => ({}),
});

export const fetchUploadApi = async (url, file, onUploadProgress, i) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status < 400) {
        resolve(true);
      } else {
        const error = new Error(xhr.response);
        reject(error);
      }
    };
    xhr.upload.onprogress = evt => {
      if (evt.lengthComputable) {
        onUploadProgress && onUploadProgress(file, evt.loaded, evt.total, i);
      }
    };
    xhr.onerror = error => {
      reject(error);
    };
    xhr.onreadystatechange = evt => {
      if (xhr.readyState === 1) {
      } else if (xhr.readyState === 4) {
      }
    };

    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};
