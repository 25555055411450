
export const MANUAL_PROGRESS_STATE = {
  'FILE_UPLOAD': 'FILE_UPLOAD',
  'DATA_SETTING': 'DATA_SETTING',
  'CALC_STAY': 'CALC_STAY',
  'MATCHED_STAY': 'MATCHED_STAY',
  'MATCHING': 'MATCHING',
  'MATCHED': 'MATCHED',
  'MATCHED_ERROR': 'MATCHED_ERROR',
  'CALCULATING': 'CALCULATING',
  'CALC_COMPLETE': 'CALC_COMPLETE',
  'ERROR': 'ERROR',
  'PROCESS_STOP': 'PROCESS_STOP'
}

export const MANUAL_NETWORK_STATE = {
  'FILE_SAVING': 'FILE_SAVING',
  'FILE_SAVE_OK': 'FILE_SAVE_OK',
  'FILE_SAVE_ERROR': 'FILE_SAVE_ERROR',
  'EXCEL_MATCHING': 'EXCEL_MATCHING',
  'EXCEL_MATCHING_OK': 'EXCEL_MATCHING_OK',
  'EXCEL_MATCHING_ERROR': 'EXCEL_MATCHING_ERROR',
  'EXCEL_CALCULATING': 'EXCEL_CALCULATING',
  'EXCEL_CALCULATING_OK': 'EXCEL_CALCULATING_OK',
  'EXCEL_CALCULATING_ERROR': 'EXCEL_CALCULATING_ERROR',
  'EXCEL_STOP': 'EXCEL_STOP'
}