import { baseApi } from './baseApi';

const categoryGroupApi = baseApi.injectEndpoints({
  endpoints: build => ({
    categoryGroupList: build.query({
      query: data => ({ url: '/category-group/list', data }),
      transformResponse: (response, meta, arg) => {
        let categoryGroupList = response.categoryGroupList || [];

        if (arg.delYn === 'N') {
          categoryGroupList = categoryGroupList.filter(category => category.delYn === 'N');
        } else if (arg.delYn === 'Y') {
          categoryGroupList = categoryGroupList.filter(category => category.delYn === 'Y');
        }

        return [...categoryGroupList].sort((a, b) => a.categoryGroupOrder - b.categoryGroupOrder);
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
});

export const { endpoints, useCategoryGroupListQuery } = categoryGroupApi;
