import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface CalendarAppDataState {
  calendarName: string;
  dateCheck: string;
  iCalendarUrlYn: string;
  iCalendarUrl: string;
}

interface WeatherAppDataState {
  location: string;
  degreeCheck: string;
  timeForecastYn: string;
  dayForecastYn: string;
}

interface TextAppDataState {
  fontSize: string;
  text: string;
  frameLinkYn: string;
}

interface FrameNoAppDataState {
  frameNoYn: string;
}

interface MediaDataState {
  file: Array<any>;
  animation: string;
  zoom: string;
  fitOption: string;
}

interface LayerContentsState {
  playlistId: string;
  frameId: string;
  layerId: string;
  contentsId: string;
  contentsType: string;
  contentsData:
    CalendarAppDataState
    | WeatherAppDataState
    | TextAppDataState
    | FrameNoAppDataState
    | MediaDataState;
  contentsFileList: Array<any>;
  editingMode: boolean;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: LayerContentsState = {
  layerContentsList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layerContents',
  initialState,
  reducers: {
    stateClear: (state: LayerContentsState, { payload }: PayloadAction<LayerContentsState>) => {
      state.layerContentsList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = '';
    },
    setLayerContentsList(state: LayerContentsState, { payload: { layerContentsList }}: PayloadAction<LayerContentsState>) {
      state.layerContentsList = layerContentsList;
    },
    actionResultClear: (state: LayerContentsState) => {
      state.actionResult = '';
    },
    updateLayerContents(state: LayerContentsState, { payload: { key, index, updateKey, updateValue, layerId } }: PayloadAction<LayerContentsState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsData[key][index][updateKey] = updateValue;
    },
    updateLayerContentsObj(state: LayerContentsState, { payload: { key, value, layerId } }: PayloadAction<LayerContentsState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsData[key] = value;
    },
    updateContentsFileList(state: LayerContentsState, { payload: { contentsFileList, mediaList, layerId } }: PayloadAction<LayerContentsState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsFileList = contentsFileList;
      state.layerContentsList[idx].contentsData.mediaList = mediaList;
    },
    removeContentsFileList(state: LayerContentsState, { payload: { index, layerId } }: PayloadAction<LayerContentsState>) {
      const idx = state.layerContentsList.findIndex(contents => contents.layerId === layerId);
      state.layerContentsList[idx].contentsFileList.splice(index, 1);
      state.layerContentsList[idx].contentsData.mediaList.splice(index, 1);
    },
  },
});

const selectLayerContentsList = createDraftSafeSelector(
  (state: LayerContentsState) => state.layerContentsList,
  (layerContentsList) => layerContentsList,
);

const selectStatus = createDraftSafeSelector(
  (state: LayerContentsState) => state.editingMode,
  (state: LayerContentsState) => state.actionResult,
  (state: LayerContentsState) => state.isLoading,
  (state: LayerContentsState) => state.error,
  (editingMode, actionResult, isLoading, error) => ({ editingMode, actionResult, isLoading, error }),
);

export const layerContentsSelector = {
  layerContentsList: state => selectLayerContentsList(state[LAYERCONTENTS]),
  status: state => selectStatus(state[LAYERCONTENTS]),
};

export const LAYERCONTENTS = slice.name;
export const layerContentsReducer = slice.reducer;
export const layerContentsAction = slice.actions;
