import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface OptionInfo {
  optionId: string;
  spaceId?: string;
  brandId?: string;
  optionNm?: string;
  optionEngNm?: string;
  optionType?: string;
  subOptionList?: { subOptionNm?: string, subOptionEngNm?: string, subOptionPrice?: number }[];
  regId?: string;
  regDt?: string;
  modId?: string;
  modDt?: string;
  useYn?: string;
  delYn?: string;
  delDt?: string;
}

interface OptionPayload {
  optionId?: string;
  spaceId?: string;
  brandId?: string;
  optionNm?: string;
  optionEngNm?: string;
  optionType?: string;
  subOptionList?: { subOptionNm?: string, subOptionEngNm?: string, subOptionPrice?: number }[];
  regId?: string;
  regDt?: string;
  modId?: string;
  modDt?: string;
  useYn?: string;
  delYn?: string;
  delDt?: string;
}

interface OptionState {
  optionList: Array<OptionInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: OptionState = {
  optionList: [],
  optionInfo: {},
  filterInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: OptionState) => {
    state.actionResult = '';
  },
  optionStateClear: (state: OptionState) => {
    state.optionList = [];
    state.optionInfo = {};
    state.filterInfo = {};
    state.actionResult = '';
    state.isLoading = false;
    state.error = null;
  },
  optionFilter(state: OptionState, { payload: { useYn, optionNm, optionEngNm } }: PayloadAction<OptionPayload>) {
    state.filterInfo.useYn = useYn;
    state.filterInfo.optionNm = optionNm;
    state.filterInfo.optionEngNm = optionEngNm;
  },
  optionList: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionListSuccess: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.optionList = payload.optionList;
    state.actionResult = 'OPTION_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionListFailure: (state: OptionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'OPTION_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  optionInfo(state: OptionState) {
    state.actionResult = 'OPTION_INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionInfoSuccess(state: OptionState, { payload }: PayloadAction<OptionPayload>) {
    state.optionInfo = payload.optionInfo;
    state.actionResult = 'OPTION_INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionInfoFailure(state: OptionState, { payload }: PayloadAction<string>) {
    state.actionResult = 'OPTION_INFO_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  optionAdd: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionAddSuccess: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionAddFailure: (state: OptionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'OPTION_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  optionUpdate: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionUpdateSuccess: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.optionList= payload.optionList;
    state.actionResult = 'OPTION_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionUpdateFailure: (state: OptionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'OPTION_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  optionRemove: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionRemoveSuccess: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.optionList= payload.optionList;
    state.actionResult = 'OPTION_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionRemoveFailure: (state: OptionState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'OPTION_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  optionReorder: (state: OptionState, { payload }: PayloadAction<OptionPayload>) => {
    state.optionList = payload.updateInfoList;
    state.actionResult = 'OPTION_REORDER_REQ';
    state.isLoading = true;
    state.error = null;
  },
  optionReorderSuccess: (state: GoodsState, { payload }: PayloadAction<OptionPayload>) => {
    state.actionResult = 'OPTION_REORDER_OK';
    state.isLoading = false;
    state.error = null;
  },
  optionReorderFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'OPTION_REORDER_ERR';
    state.isLoading = false;
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'goodsOption',
  initialState,
  reducers,
});

const selectOptionList = createDraftSafeSelector(
  (state: OptionState) => state.filterInfo,
  (state: OptionState) => state.pageInfo,
  (state: OptionState) => state.optionList,

  (filterInfo, pageInfo, optionList) => {
    if (
      (!filterInfo.useYn || filterInfo.useYn.trim() === '') &&
      (!filterInfo.optionNm || filterInfo.optionNm.trim() === '') &&
      (!filterInfo.optionEngNm || filterInfo.optionEngNm.trim() === '')
    ) {
      let sliceList = [...optionList].sort((l, r) => {
        return l.optionOrder === r.optionOrder ? 0 : l.optionOrder < r.optionOrder ? -1 : 1;
      });
      return {
        optionList: sliceList,
      };
    }

    const filterOptionList = optionList.filter(option => {
      let useYnFilter = true;
      let optionNmFilter = true;
      let optionEngNmFilter = true;

      if (filterInfo.useYn && filterInfo.useYn !== '') {
        useYnFilter = option.useYn === filterInfo.useYn;
      }

      if (filterInfo.optionNm && filterInfo.optionNm !== '') {
        optionNmFilter = option.optionNm?.toLowerCase().indexOf(filterInfo.optionNm.toLowerCase()) >= 0;
      }

      if (filterInfo.optionEngNm && filterInfo.optionEngNm !== '') {
        optionEngNmFilter = option.optionEngNm?.toLowerCase().indexOf(filterInfo.optionEngNm.toLowerCase()) >= 0;
      }

      return useYnFilter && (optionNmFilter || optionEngNmFilter);
    });

    let sliceList = [...filterOptionList].sort((l, r) => {
      return l.optionOrder === r.optionOrder ? 0 : l.optionOrder < r.optionOrder ? -1 : 1;
    });

    return {
      optionList: sliceList,
    };
  }
);

const selectOptionInfo = createDraftSafeSelector(
  (state: OptionState) => state.optionInfo,
  (optionInfo) => optionInfo
);

const selectStatus = createDraftSafeSelector(
  (state: OptionState) => state.actionResult,
  (state: OptionState) => state.isLoading,
  (state: OptionState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const goodsOptionSelector = {
  optionInfo: state => selectOptionInfo(state[GOODSOPTION]),
  optionList: state => selectOptionList(state[GOODSOPTION]),
  status: state => selectStatus(state[GOODSOPTION]),
};

export const GOODSOPTION = slice.name;
export const goodsOptionReducer = slice.reducer;
export const goodsOptionAction = slice.actions;
