import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/playlist/list', data);
    if (result.resultFlag) {
      return result.playlistList;
    } else {
      throw Object.assign(new Error('playlist list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const detail = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/playlist/detail', data);
    if (result.resultFlag) {
      return result.playlistInfo;
    } else {
      throw Object.assign(new Error('playlist detail error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/playlist/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('playlist add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/playlist/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('playlist edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/playlist/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('playlist remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
