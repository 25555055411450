import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ReportPayload {
  reportStartDt: string;
  reportEndDt: string;
}

interface FilterInfo {
  startDt: string;
  endDt: string;
}

interface PageInfo {
  rowsPerPage: number;
  curPage: number;
}

interface ReportState {
  orderList: Array<>;
  limitOrderList: Array<>;
  formatOrderList: Array<>;
  monthLogList: Array<>;
  dailyLogList: Array<>;
  analysisLogList: Array<>;
  filterInfo: any;
  filterOrderDt: any;
  filterSearchInfo: any;
  filterSelectInfo: any;
  pageInfo: PageInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
  selectedFilterValue: Array<>;
}

const SalesReportInitialState: ReportState = {
  orderList: [],
  limitOrderList: [],
  formatOrderList: [],
  monthLogList: [],
  dailyLogList: [],
  analysisLogList: [],
  selectedFilterValue: [],
  openLogList: [],
  filterInfo: { startDt: '', endDt: '' },
  filterOrderDt: { orderStartDt: '', orderEndDt: '' },
  filterSearchInfo: { selectValue: 'billNo', searchValue: '' },
  filterSelectInfo: { selectValue: 'ALL', column: 'billType' },
  pageInfo: { rowsPerPage: 10, curPage: 1, endKey: '' },
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  clearSalesReportState: (state: ReportState) => {
    state.orderList = [];
    state.monthLogList = [];
    state.dailyLogList = [];
    state.analysisLogList = [];
    state.openLogList = [];
    state.filterInfo = { startDt: '', endDt: '' };
    state.filterOrderDt = { orderStartDt: '', orderEndDt: '' };
    state.filterSearchInfo = { selectValue: 'billNo', searchValue: '' };
    state.pageInfo = { rowsPerPage: 10, curPage: 1 };
  },
  orderList: (state: ReportState, { payload }: PayloadAction<ReportPayload>) => {
    // state.filterInfo.startDt = payload.reportStartDt;
    // state.filterInfo.endDt = payload.reportEndDt;
    state.actionResult = 'ORDER_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderListCancel: (state: ReportState, { payload }: PayloadAction<ReportPayload>) => {
    state.actionResult = 'ORDER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderListSuccess: (state: ReportState, { payload }: PayloadAction<ReportState>) => {
    state.orderList = payload.orderList;
    state.formatOrderList = payload.orderList;
    state.actionResult = 'ORDER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderListFailure: (state: ReportState, action: PayloadAction<string>) => {
    state.actionResult = 'ORDER_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  limitOrderList: (state: ReportState, { payload }: PayloadAction<ReportPayload>) => {
    // state.filterInfo.startDt = payload.reportStartDt;
    // state.filterInfo.endDt = payload.reportEndDt;
    state.actionResult = 'LIMIT_ORDER_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  limitOrderListSuccess: (state: ReportState, { payload }: PayloadAction<ReportState>) => {
    state.limitOrderList = payload.limitOrderList;
    state.pageInfo.lastKey = payload.lastKey;
    state.actionResult = 'LIMIT_ORDER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  limitOrderListFailure: (state: ReportState, action: PayloadAction<string>) => {
    state.actionResult = 'LIMIT_ORDER_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  monthLogList: (state: ReportState, { payload }: PayloadAction<SalesSelectPayload>) => {
    state.filterInfo.startDt = payload.startDt;
    state.filterInfo.endDt = payload.endDt;
    state.actionResult = 'MONTHLOG_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  monthLogListSuccess: (state: ReportState, { payload }: PayloadAction<SalesMonthState>) => {
    state.monthLogList = payload.monthLogList;
    state.actionResult = 'MONTHLOG_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  monthLogListFailure: (state: ReportState, action: PayloadAction<string>) => {
    state.actionResult = 'MONTHLOG_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  dailyLogList: (state: ReportState, { payload }: PayloadAction<SalesSelectPayload>) => {
    state.actionResult = 'DAILYLOG_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  dailyLogListSuccess: (state: ReportState, { payload }: PayloadAction<SalesDailyState>) => {
    state.dailyLogList = payload.dailyLogList;
    state.actionResult = 'DAILYLOG_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  dailyLogListFailure: (state: ReportState, action: PayloadAction<string>) => {
    state.actionResult = 'DAILYLOG_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  analysisLogList: (state: ReportState, { payload }: PayloadAction<SalesSelectPayload>) => {
    state.actionResult = 'ANALYSISLOG_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  analysisLogListSuccess: (state: ReportState, { payload }: PayloadAction<SalesDailyState>) => {
    state.analysisLogList = payload.analysisLogList;
    state.actionResult = 'ANALYSISLOG_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  analysisLogListFailure: (state: ReportState, action: PayloadAction<string>) => {
    state.actionResult = 'ANALYSISLOG_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  salesReportFilter: (state: ReportState, { payload }: PayloadAction<ReportState>) => {
    state.filterInfo.startDt = payload.startDt;
    state.filterInfo.endDt = payload.endDt;
  },
  openLogList: (state: OpenLogState, { payload }: PayloadAction<OpenLogSelectPayload>) => {
    state.actionResult = 'OPENLOG_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  openLogListSuccess: (state: OpenLogState, { payload }: PayloadAction<OpenLogState>) => {
    let sortOpenLogList = [];
    sortOpenLogList = [...payload.openLogList].sort((l, r) => (l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1));
    state.openLogList = sortOpenLogList;
    state.actionResult = 'OPENLOG_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  openLogListFailure: (state: OpenLogState, action: PayloadAction<string>) => {
    state.actionResult = 'OPENLOG_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  filterOrderDt: (state: ReportState, { payload: { orderStartDt, orderEndDt } }: PayloadAction<ReportState>) => {
    state.filterOrderDt = {
      orderStartDt,
      orderEndDt,
    };
  },
  filterSearchInfo: (state: ReportState, { payload: { selectValue, searchValue } }: PayloadAction<ReportState>) => {
    state.filterSearchInfo = {
      selectValue,
      searchValue,
    };
  },
  filterSelectInfo: (state: ReportState, { payload: { selectValue, column } }: PayloadAction<ReportState>) => {
    state.filterSelectInfo = {
      selectValue,
      column,
    };
  },
  orderSearchValue: (state: ReportState, { payload: { selectValue, column } }: PayloadAction<ReportState>) => {
    state.filterSelectInfo = {
      selectValue,
      column,
    };
  },
  salesReportPaging: (state: ReportState, { payload: { key, value } }: PayloadAction<ReportState>) => {
    state.pageInfo[key] = value;
  },
  selectedFilterValue: (state: ReportState, { payload: { columnName, value } }: PayloadAction<ReportState>) => {
    state.selectedFilterValue = { ...state.selectedFilterValue, [columnName]: value };
  },
  filterOrderList: (state: ReportState, { payload }: PayloadAction<ReportState>) => {
    state.formatOrderList = payload.orderList;
  },
  actionResultClear: (state: ReportState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'salesReport',
  initialState: SalesReportInitialState,
  reducers: reducers,
});

const selectOrderList = createDraftSafeSelector(
  (state: ReportState) => state.orderList,
  orderList => orderList,
);

const selectMonthLogList = createDraftSafeSelector(
  (state: ReportState) => state.monthLogList,
  monthLogList => monthLogList,
);

const selectDailyLogList = createDraftSafeSelector(
  (state: ReportState) => state.dailyLogList,
  dailyLogList => dailyLogList,
);

const selectAnalysisLogList = createDraftSafeSelector(
  (state: ReportState) => state.analysisLogList,
  analysisLogList => analysisLogList,
);

const selectOpenLogList = createDraftSafeSelector(
  (state: ReportState) => state.openLogList,
  openLogList => openLogList,
);

const selectFilterNewOrderList = createDraftSafeSelector(
  (state: ReportState) => state.formatOrderList,
  (state: ReportState) => state.filterSearchInfo,
  (state: ReportState) => state.pageInfo,
  (state: ReportState) => state.selectedFilterValue,
  (formatOrderList, filterSearchInfo, pageInfo, selectedFilterValue) => {
    const { selectValue, searchValue } = filterSearchInfo;

    let totalPageCnt;
    let startIndex;
    let endIndex;
    let sliceList;
    let resultOrderList = formatOrderList;
    const filterKey = Object.keys(selectedFilterValue);
    let originFilterList = formatOrderList;

    for (let i = 0; i < filterKey.length; i++) {
      if (selectedFilterValue[filterKey[i]] !== 'ALL') {
        resultOrderList = originFilterList.filter(obj => obj[filterKey[i]] === selectedFilterValue[filterKey[i]]);
      }
    }

    const correctValue = resultOrderList.filter(order => String(order[selectValue]) === searchValue);
    const exceptValue = resultOrderList.filter(order => String(order[selectValue]).includes(searchValue?.toLowerCase()) && String(order[selectValue]) !== searchValue);
    resultOrderList = correctValue.concat(exceptValue);

    totalPageCnt =
      resultOrderList.length % pageInfo.rowsPerPage > 0 ? Math.floor(resultOrderList.length / pageInfo.rowsPerPage) + 1 : resultOrderList.length / pageInfo.rowsPerPage;
    startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
    endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
    sliceList = resultOrderList.slice(startIndex, endIndex);

    return {
      totalPageCnt,
      filterOrderList: sliceList,
      startIndex,
    };
  },
);

const selectFilterOrderList = createDraftSafeSelector(
  (state: ReportState) => state.orderList,
  (state: ReportState) => state.filterSearchInfo,
  (state: ReportState) => state.pageInfo,
  (state: ReportState) => state.filterSelectInfo,
  (orderList, filterSearchInfo, pageInfo, filterSelectInfo) => {
    // 총 페이지 수
    // 한 페이지에 들어가는 list
    // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
    // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

    const { selectValue, searchValue } = filterSearchInfo;
    const { selectValue: filterOptionValue, column } = filterSelectInfo;
    let totalPageCnt;
    let startIndex;
    let endIndex;
    let sliceList;
    let resultOrderList = [];

    if (filterOptionValue === 'ALL') {
      //  orderList = orderList.filter(order=> order[column] === filterOptionValue);
    } else {
      orderList = orderList.filter(order => order[column] === filterOptionValue);
    }

    if (searchValue !== '') {
      let searchFilteredOrderList = [];
      if (selectValue === 'billNo') {
        //console.log("sortArray",sortArray);
        //첫번재
        const correctValue = orderList.filter(order => String(order[selectValue]) === searchValue);
        const exceptValue = orderList.filter(order => String(order[selectValue]).includes(searchValue) && String(order[selectValue]) !== searchValue);

        searchFilteredOrderList = correctValue.concat(exceptValue);
      } else if (selectValue === 'customerNm') {
        searchFilteredOrderList = orderList.filter(
          order =>
            order.customerEngNm?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            order.customerNm?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            order?.paymentList[0].paymentNm?.toLowerCase().includes(searchValue?.toLowerCase()),
        );
      }
      resultOrderList = searchFilteredOrderList;
    } else {
      resultOrderList = orderList;
    }

    totalPageCnt =
      resultOrderList.length % pageInfo.rowsPerPage > 0 ? Math.floor(resultOrderList.length / pageInfo.rowsPerPage) + 1 : resultOrderList.length / pageInfo.rowsPerPage;
    startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
    endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
    sliceList = resultOrderList.slice(startIndex, endIndex);

    return {
      totalPageCnt,
      orderList: sliceList,
      originOrderList: orderList,
      startIndex,
    };
  },
);

const selectFilterLimitOrderList = createDraftSafeSelector(
  (state: ReportState) => state.limitOrderList,
  (state: ReportState) => state.filterSearchInfo,
  (state: ReportState) => state.pageInfo,
  (limitOrderList, filterSearchInfo, pageInfo) => {
    // 총 페이지 수
    // 한 페이지에 들어가는 list
    // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
    // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

    const { selectValue, searchValue } = filterSearchInfo;
    let totalPageCnt;
    let startIndex;
    let endIndex;
    let sliceList;

    if (searchValue !== '') {
      let searchFilteredOrderList = [];
      if (selectValue === 'billNo') {
        searchFilteredOrderList = limitOrderList.filter(order => String(order[selectValue]).includes(searchValue));
      } else if (selectValue === 'customerNm') {
        searchFilteredOrderList = limitOrderList.filter(order => order[selectValue].includes(searchValue));
      }

      totalPageCnt =
        searchFilteredOrderList.length % pageInfo.rowsPerPage > 0
          ? Math.floor(searchFilteredOrderList.length / pageInfo.rowsPerPage) + 1
          : searchFilteredOrderList.length / pageInfo.rowsPerPage;
      startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
      endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
      sliceList = searchFilteredOrderList.slice(startIndex, endIndex);
    } else {
      totalPageCnt = limitOrderList.length % pageInfo.rowsPerPage > 0 ? Math.floor(limitOrderList.length / pageInfo.rowsPerPage) + 1 : limitOrderList.length / pageInfo.rowsPerPage;
      startIndex = (pageInfo.curPage - 1) * pageInfo.rowsPerPage;
      endIndex = pageInfo.curPage * pageInfo.rowsPerPage;
      sliceList = limitOrderList.slice(startIndex, endIndex);
    }

    return {
      totalPageCnt,
      limitOrderList: sliceList,
      originLimitOrderList: limitOrderList,
      startIndex,
    };
  },
);

const selectLimitOrderList = createDraftSafeSelector(
  (state: ReportState) => state.limitOrderList,
  limitOrderList => limitOrderList,
);

const selectFilterAnalysisList = createDraftSafeSelector(
  (state: ReportState) => state.analysisLogList,
  (state: ReportState) => state.pageInfo,

  (analysisLogList, pageInfo) => {
    // 총 페이지 수
    // 한 페이지에 들어가는 list
    // 전체list 중 페이지에 들어가는 첫번째 데이터의 인덱스
    // 전체list 중 페이지에 들어가는 마지막 데이터의 인덱스

    let arrangeList = [];
    analysisLogList?.map(item => {
      const goodsIdList = Object.keys(item.goodsInfoList);
      return goodsIdList.map((goodsId, idx) => {
        return item.goodsInfoList[goodsId].salesCnt !== 0
          ? arrangeList.push({
              goodsId: goodsId,
              logDt: item.logDt,
              itemNm: item.goodsInfoList[goodsId].itemNm, // goods 이름
              categoryNm: item.goodsInfoList[goodsId].categoryNm, // 카테고리 이름
              salesCnt: item.goodsInfoList[goodsId].salesCnt,
              salesAmt: item.goodsInfoList[goodsId].salesAmt,
              optionList: item.goodsInfoList[goodsId].optionList,
              goodsOpt: item.goodsInfoList[goodsId].goodsOpt,
            })
          : arrangeList;
      });
    });

    let totalPageCnt = arrangeList?.length % pageInfo?.rowsPerPage > 0 ? Math.floor(arrangeList?.length / pageInfo?.rowsPerPage) + 1 : arrangeList?.length / pageInfo?.rowsPerPage;
    let startIndex = (pageInfo?.curPage - 1) * pageInfo?.rowsPerPage;
    let endIndex = pageInfo?.curPage * pageInfo?.rowsPerPage;
    let sliceList = arrangeList?.slice(startIndex, endIndex);

    return {
      totalPageCnt,
      analysisList: sliceList,
      originAnalysisList: arrangeList,
      startIndex,
    };
  },
);

const selectFilterOpenLogAvg = createDraftSafeSelector(
  (state: any) => state.openLog.openLogList,
  openLogList => {
    let openTimeList = [];
    let endTimeList = [];

    openLogList?.forEach(openLog => {
      if (openLog.shopOpenState === 'Y') {
        if (openTimeList.findIndex(openT => openT.time === dayjs(openLog.regDt).format('A HH:mm')) >= 0) {
          openTimeList.map(openT => (openT.time === dayjs(openLog.regDt).format('A HH:mm') ? (openT.cnt += 1) : openT.cnt));
        } else {
          openTimeList = openTimeList.concat({ time: dayjs(openLog.regDt).format('A HH:mm'), cnt: 1 });
        }
      } else {
        if (endTimeList.findIndex(endT => endT.time === dayjs(openLog.regDt).format('A HH:mm')) >= 0) {
          endTimeList.map(endT => (endT.time === dayjs(openLog.regDt).format('A HH:mm') ? (endT.cnt += 1) : endT.cnt));
        } else {
          endTimeList = endTimeList.concat({ time: dayjs(openLog.regDt).format('A HH:mm'), cnt: 1 });
        }
      }
    });

    openTimeList.sort((a, b) => b.cnt - a.cnt);
    endTimeList.sort((a, b) => b.cnt - a.cnt);

    return {
      openAvg: openTimeList[0]?.time,
      endAvg: endTimeList[0]?.time,
    };
  },
);

const selectFilterInfo = createDraftSafeSelector(
  (state: ReportState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectFilterOrderDt = createDraftSafeSelector(
  (state: ReportState) => state.filterOrderDt,
  filterOrderDt => filterOrderDt,
);

const selectFilterSearchInfo = createDraftSafeSelector(
  (state: ReportState) => state.filterSearchInfo,
  filterSearchInfo => filterSearchInfo,
);

const selectPageInfo = createDraftSafeSelector(
  (state: ReportState) => state.pageInfo,
  pageInfo => pageInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: ReportState) => state.actionResult,
  (state: ReportState) => state.isLoading,
  (state: ReportState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const salesReportSelector = {
  orderList: state => selectOrderList(state[SALESREPORT]),
  limitOrderList: state => selectLimitOrderList(state[SALESREPORT]),
  monthLogList: state => selectMonthLogList(state[SALESREPORT]),
  dailyLogList: state => selectDailyLogList(state[SALESREPORT]),
  analysisLogList: state => selectAnalysisLogList(state[SALESREPORT]),
  openLogList: state => selectOpenLogList(state[SALESREPORT]),
  filterOrderList: state => selectFilterOrderList(state[SALESREPORT]),
  filterLimitOrderList: state => selectFilterLimitOrderList(state[SALESREPORT]),
  filterAnalysisList: state => selectFilterAnalysisList(state[SALESREPORT]),
  filterOpenLogAvg: state => selectFilterOpenLogAvg(state),
  filterInfo: state => selectFilterInfo(state[SALESREPORT]),
  filterOrderDt: state => selectFilterOrderDt(state[SALESREPORT]),
  filterSearchInfo: state => selectFilterSearchInfo(state[SALESREPORT]),
  filterNewListInfo: state => selectFilterNewOrderList(state[SALESREPORT]),
  pageInfo: state => selectPageInfo(state[SALESREPORT]),
  status: state => selectStatus(state[SALESREPORT]),
};

export const SALESREPORT = slice.name;
export const salesReportReducer = slice.reducer;
export const salesReportAction = slice.actions;
