import * as apiLib from './api';


export const excelAddPresignUpload = async ({ loginInfo, shopId, fileId, file }) => {
  try {
    const data = {
      loginInfo,
      shopId,
      fileId,
      fileName: file && file.name,
      fileType: file && file.type,
    };

    const result = await apiLib.fetchStoreApi('/excelFile/file-presign-upload', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('Presign Upload Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const getExcelData = async ({filePath, excelFileId}) => {
  try {
    const data = {
      filePath,
      excelFileId
    };

    const result = await apiLib.fetchStoreApi('/excelFile/get-excel-data', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('data-init Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
}

export const excelInitData = async ({excelFileId, excelDataList}) => {
  try {
    const data = {
      excelFileId,
      excelDataList
    };

    const result = await apiLib.fetchStoreApi('/excelFile/data-init', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('data-init Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const excelServerMatching = async ({shopId, excelFileId, excelDataList}) => {
  try {
    const data = {
      shopId,
      excelFileId,
      excelDataList
    };

    const result = await apiLib.fetchStoreApi('/excelFile/server-matching', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('server-matching Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const excelCalculating = async ({ shopId, excelFileId, excelDataList }) => {
  try {
    const data = {
      shopId,
      excelFileId,
      excelDataList
    };

    const result = await apiLib.fetchStoreApi('/excelFile/order-calc', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('Excel order calc Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const ExcelFileStateDirectApi = async ({ excelFileId, fileState, successCount, orderCalcCount }) => {
  try {
    const data = {
      excelFileId,
      fileState
    };

    if(successCount) {
      data['successCount'] = successCount;
    }

    if(orderCalcCount) {
      data['orderCalcCount'] = orderCalcCount;
    }

    const result = await apiLib.fetchStoreApi('/excelFile/excel-file-update', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('Excel List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};


export const ExcelFileListDirectApi = async ({ shopId, searchStartDt, searchEndDt, searchCalcState, searchType, searchValue }) => {
  try {
    const data = {
      shopId, searchStartDt, searchEndDt, searchCalcState, searchType, searchValue
    };
    // console.log('input Data : ', data);
    const result = await apiLib.fetchStoreApi('/excelFile/list', data);
    // console.log('Output Data: ', result);
    if (result.resultFlag) {
      return result.excelFileList;
    } else {
      throw Object.assign(new Error('Excel List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const ExcelFileDeleteDirectApi = async ({ shopId, excelFileId, excelFileNm }) => {
  try {
    const data = {
      shopId, excelFileId, excelFileNm,
    };
    // console.log('input Data : ', data);
    const result = await apiLib.fetchStoreApi('/excelFile/delete', data);
    //console.log('Output Data: ', result);
    if (result.resultFlag) {
      return result.excelFileNm;
    } else {
      throw Object.assign(new Error('Excel file delete Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const ExcelOrderListDirectApi = async ({ shopId, excelFileId }) => {
  try {
    const data = {
      shopId, excelFileId
    };
    // console.log('input Data : ', data);
    const result = await apiLib.fetchStoreApi('/excelFile/order-list', data);
    // console.log('Output Data: ', result);
    if (result.resultFlag) {
      // return result.excelOrderList;
      return result;
    } else {
      throw Object.assign(new Error('Excel order List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

