import { fork, call, put, takeLatest, all } from 'redux-saga/effects';
import * as packageApiLib from '../lib/packageApi';
import * as frameApiLib from '../lib/frameApi';
import * as layerApiLib from '../lib/layerApi';
import * as layerContentsApiLib from '../lib/layerContentsApi';
import * as overlayApiLib from '../lib/overlayApi';
import * as overlayContentsApiLib from '../lib/overlayContentsApi';

import { packageAction } from './packageSlice';
import { frameAction } from './frameSlice';
import { layerAction } from './layerSlice';
import { layerContentsAction } from './layerContentsSlice';
import { overlayAction } from './overlaySlice';
import { overlayContentsAction } from './overlayContentsSlice';

function* list({ payload: { packageType, spaceId } }) {
  try {
    const packageList = yield call(packageApiLib.list, { packageType });

    yield put(packageAction.listSuccess({ packageList, spaceId }));
  } catch (error) {
    console.log('error', error);
    yield put(packageAction.listFailure('list failure'));
  }
}

function* detail({ payload }) {
  try {
    const packageInfo = yield call(packageApiLib.detail, payload);
    const frameList = yield call(frameApiLib.list, { playlistId: payload.packageId });
    const layerList = yield call(layerApiLib.list, { playlistId: payload.packageId });
    const layerContentsList = yield call(layerContentsApiLib.list, { playlistId: payload.packageId });
    const overlayList = yield call(overlayApiLib.list, { playlistId: payload.packageId });
    const overlayContentsList = yield call(overlayContentsApiLib.list, { playlistId: payload.packageId });

    yield put(layerContentsAction.setLayerContentsList({ layerContentsList }));
    yield put(layerAction.setLayerList({ layerList }));
    yield put(frameAction.setFrameList({ frameList }));
    yield put(overlayContentsAction.setOverlayContentsList({ overlayContentsList }));
    yield put(overlayAction.setOverlayList({ overlayList }));
    yield put(packageAction.detailSuccess({ packageInfo }));
  } catch (error) {
    console.log('error', error);
    yield put(packageAction.listFailure('detail failure'));
  }
}

export function* watchList() {
  yield takeLatest(packageAction.list, list);
}

export function* watchDetail() {
  yield takeLatest(packageAction.detail, detail);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchDetail)]);
}

export default rootSaga;
