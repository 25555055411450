import { all, fork, call, put, select, takeLatest } from 'redux-saga/effects';
import { openLogAction } from './openLogSlice';
import { salesReportSelector } from './salesReportSlice';

import * as openLogApi from '../lib/openLogApi';


function* openLogList({ payload }) {
  try {
    const { startDt, endDt } = yield select(salesReportSelector.filterInfo);
    const data = {
      shopId: payload.shopId,
      openStartDt: startDt,
      openEndDt: endDt,
    };

    const openLogList = yield call(openLogApi.list, data);
    yield put(openLogAction.openLogListSuccess({ openLogList }));
  } catch (err) {
    yield put(openLogAction.openLogListFailure('OpenLog List Error'));
  }
}

  function* openLogLogAdd({ payload }) {
    console.log('payload',payload);
    try {
      
      const openLog = yield call(openLogApi.logAdd, payload);
      // const openLogList = yield call(openLogApi.list, payload);

      //yield put(openLogAction.openLogLogAddSuccess({ openLog,openLogList }));
      yield put(openLogAction.openLogLogAddSuccess({ openLog }));
    } catch (err) {
      yield put(openLogAction.openLogLogAddFailure('OpenLog List Error'));
    }
  }

  // function* openLogInfo({ payload }) {
    
  //   payload.openStartDt = dayjs().subtract(1,'month').format('YYYYMMDD');
  //   payload.openEndDt = dayjs().format('YYYYMMDD');

  //     try {
  //       const openLogList = yield call(openLogApi.list, payload);
  //       yield put(openLogAction.openLogInfoSuccess({ openLogList }));
  //     } catch (err) {
  //       yield put(openLogAction.openLogInfoFailure('openLogInfo Error'));
  //     }
  //   }




  
  export function* watchOpenLogList() {
    yield takeLatest(openLogAction.openLogList, openLogList);
  }

  export function* watchOpenLogLogAdd() {
    yield takeLatest(openLogAction.openLogLogAdd, openLogLogAdd);
  }
  // export function* watchOpenLogInfo() {
  //   yield takeLatest(openLogAction.openLogInfo, openLogInfo);
  // }

  
  
  function* rootSaga() {
    yield all([
      fork(watchOpenLogList),
      fork(watchOpenLogLogAdd),
      // fork(watchOpenLogInfo),
    ]);
  }
  
  export default rootSaga;
  