import React, {useEffect, useCallback, useMemo, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';

import { ReactComponent as LeftIcon } from '../assets/images/common/icon-chevron-left.svg';
import { ReactComponent as RightIcon } from '../assets/images/common/icon-chevron-right.svg';

import SelectInput from './SelectInput';
import useCustomSearchParams from './useCustomSearchParams';

const Pagination = ({ totalCount = 0, isNext = false, more = ()=>{} }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 567px)' });

  const [searchParams, setSearchParams] = useCustomSearchParams();

  const qsCurPage = Number(searchParams['curPage']) - 1 || 0;
  const qsRowsPerPage = Number(searchParams['rowsPerPage']) || 10;

  const totalPage = useMemo(() => Math.ceil(totalCount / qsRowsPerPage) || 0, [qsRowsPerPage, totalCount]);
  //const startPage = useMemo(() => Math.floor(qsCurPage / 10) * 10 || 0, [qsCurPage]);
  const startPage = useMemo(() => (qsCurPage > 3 ? qsCurPage - 3 : 0) || 0, [qsCurPage]);
  //const endPage = useMemo(() => (startPage + 10 > totalPage ? totalPage : startPage + 10), [startPage, totalPage]);
  const endPage = useMemo(() => (startPage + 7 > totalPage ? totalPage : startPage + 7), [startPage, totalPage]);

  useEffect(() => {
    if ((totalPage > 0 && qsCurPage > totalPage - 1) || qsCurPage < 0) {
      setSearchParams({ ...searchParams, curPage: 1 }, { replace: true });
    }
  }, [qsCurPage, searchParams, setSearchParams, totalPage]);

  const pagingView = useCallback(() => {
    let pageArr = [];

    for (let page = startPage; page < endPage; page++) {
      pageArr.push(
        <Page key={`page-${page}`} isSelected={page === qsCurPage} onClick={() => goPage(page + 1)}>
          {page + 1}
        </Page>,
      );
    }
    return pageArr;
  }, [startPage, endPage, qsCurPage, setSearchParams, searchParams]);

  const goPage = useCallback((page) => {
    setSearchParams({ ...searchParams, curPage: page });
    more();
  },[qsCurPage, searchParams])

  return (
    <Wrap>
      <PagenationDiv isMobile={isMobile}>
        <PagenationItem isMobile={isMobile}>
          View:
          <StyledSelectInput
            isMobile={isMobile}
            up
            defaultTitle={qsRowsPerPage || ''}
            optionList={[
              { title: 10, value: 10 },
              { title: 30, value: 30 },
              { title: 50, value: 50 },
              { title: 100, value: 100 },
            ]}
            selectedValue={qsRowsPerPage}
            onSelectChange={value => setSearchParams({ ...searchParams, curPage: 1, rowsPerPage: value })}
          />
        </PagenationItem>
        <PagenationItem isMobile={isMobile}>
          <ArrowBtn onClick={() => goPage(qsCurPage)} disabled={qsCurPage === 0}>
            <LeftIcon size="15px" />
          </ArrowBtn>
          {startPage > 0 ? '...' : ''}
          {isMobile ? `${qsCurPage + 1}  /  ${totalPage}` : pagingView()}
          {isNext || endPage < totalPage ? '...' : ''}
          <ArrowBtn onClick={() => goPage(qsCurPage + 2 )} disabled={qsCurPage >= totalPage - 1}>
            <RightIcon size="15px" />
          </ArrowBtn>
        </PagenationItem>
      </PagenationDiv>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PagenationDiv = styled.div`
  display: flex;
  width: auto;
  justify-content: space-around;
  padding: 0 10px;
  gap: 15px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      align-items: center;
    `}
`;

const PagenationItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${({ isMobile }) => (isMobile ? '11px' : '12px')};
  gap: 15px;
`;

const StyledSelectInput = styled(SelectInput)`
  width: ${({ isMobile }) => (isMobile ? '70px' : '80px')};
  height: 28px;
`;

const ArrowBtn = styled.button`
  display: flex;
  background: none;
  padding: 0;
  margin: 0;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill: #cccccc;
    }
  }
`;

const Page = styled.div`
  color: ${({ isSelected }) => (isSelected ? '#4b7eff' : '#cccccc')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : '500')};
  font-size: 13px;

  &:hover {
    color: #4b7eff;
  }

  cursor: pointer;
`;

export default React.memo(Pagination);
