import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { AUTH, authReducer } from './authSlice';
import { ACCOUNT, accountReducer } from './accountSlice';
import { SHOP, shopReducer } from './shopSlice';
import { GROUP, groupReducer } from './groupSlice';
import { DEVICE, deviceReducer } from './deviceSlice';
import { CUSTOMER, customerReducer } from './customerSlice';
import { PLAYLIST, playlistReducer } from './playlistSlice';
import { FRAME, frameReducer } from './frameSlice';
import { LAYER, layerReducer } from './layerSlice';
import { LAYERCONTENTS, layerContentsReducer } from './layerContentsSlice';
import { OVERLAY, overlayReducer } from './overlaySlice';
import { OVERLAYCONTENTS, overlayContentsReducer } from './overlayContentsSlice';
import { GOODS, goodsReducer } from './goodsSlice';
import { CATEGORYGROUP, categoryGroupReducer } from './categoryGroupSlice';
import { GOODSOPTION, goodsOptionReducer } from './goodsOptionSlice';
import { PACKAGE, packageReducer } from './packageSlice';
import { ORDER, orderReducer } from './orderSlice';
import { REPORT, reportReducer } from './reportSlice';
import { SALESREPORT, salesReportReducer } from './salesReportSlice';
import { OPENLOG, openLogReducer } from './openLogSlice';
import { SALESANALYSISLOG, salesAnalysisLogReducer } from './salesAnalysisLogSlice';
import { SALESDAILYLOG, salesDailyLogReducer } from './salesDailyLogSlice';
import { SALESMONTHLOG, salesMonthLogReducer } from './salesMonthLogSlice';
import { STORAGE, storageReducer } from './storageSlice';
import { EXCELFILE, excelFileReducer } from "./excelFileSlice";

import authSaga from './authSaga';
import accountSaga from './accountSaga';
import shopSaga from './shopSaga';
import groupSaga from './groupSaga';
import deviceSaga from './deviceSaga';
import customerSaga from './customerSaga';
import packageSaga from './packageSaga';
import playlistSaga from './playlistSaga';
import goodsSaga from './goodsSaga';
import categoryGroupSaga from './categoryGroupSaga';
import goodsOptionSaga from './goodsOptionSaga';
import orderSaga from './orderSaga';
import reportSaga from './reportSaga';
import salesReportSaga from './salesReportSaga';
import openLogSaga from './openLogSaga';
import salesAnalysisLogSaga from './salesAnalysisLogSaga';
import salesDailyLogSaga from './salesDailyLogSaga';
import salesMonthLogSaga from './salesMonthLogSaga';
import storageSaga from './storageSaga';
import { baseApi } from '../redux/baseApi';
import {SPACE, spaceReducer} from "./spaceSlice";
import spaceSaga from "./spaceSaga";
import excelFileSaga from "./excelFileSaga";

const createRootReducer = () =>
  combineReducers({
    [AUTH]: authReducer,
    [ACCOUNT]: accountReducer,
    [SHOP]: shopReducer,
    [GROUP]: groupReducer,
    [DEVICE]: deviceReducer,
    [CUSTOMER]: customerReducer,
    [PACKAGE]: packageReducer,
    [PLAYLIST]: playlistReducer,
    [FRAME]: frameReducer,
    [LAYER]: layerReducer,
    [LAYERCONTENTS]: layerContentsReducer,
    [OVERLAY]: overlayReducer,
    [OVERLAYCONTENTS]: overlayContentsReducer,
    [GOODS]: goodsReducer,
    [CATEGORYGROUP]: categoryGroupReducer,
    [GOODSOPTION]: goodsOptionReducer,
    [ORDER]: orderReducer,
    [REPORT]: reportReducer,
    [SALESREPORT]: salesReportReducer,
    [OPENLOG]: openLogReducer,
    [SALESANALYSISLOG]: salesAnalysisLogReducer,
    [SALESDAILYLOG]: salesDailyLogReducer,
    [SALESMONTHLOG]: salesMonthLogReducer,
    [STORAGE]: storageReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [SPACE]: spaceReducer,
    [EXCELFILE]: excelFileReducer,
  });

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    authSaga(),
    accountSaga(),
    shopSaga(),
    groupSaga(),
    deviceSaga(),
    customerSaga(),
    packageSaga(),
    playlistSaga(),
    goodsSaga(),
    goodsOptionSaga(),
    orderSaga(),
    reportSaga(),
    salesReportSaga(),
    openLogSaga(),
    salesAnalysisLogSaga(),
    salesDailyLogSaga(),
    categoryGroupSaga(),
    salesMonthLogSaga(),
    storageSaga(),
    spaceSaga(),
    excelFileSaga(),
  ]);
}

export default function createStore() {
  const store = configureStore({
    reducer: createRootReducer(),
    devTools: true,
    middleware: getDefaultMiddleware => [...getDefaultMiddleware({ serializableCheck: false }), baseApi.middleware, sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
