import { all, fork, call, put, takeLatest, select } from 'redux-saga/effects';

import * as apiLib from '../lib/api';
import * as goodsApiLib from '../lib/goodsApi';

import { goodsAction } from './goodsSlice';
import { SHOP, shopSelector } from './shopSlice';

function* categoryList({ payload: { brandId, categoryType } }) {
  try {
    const categoryList = yield call(goodsApiLib.categoryList, { brandId, categoryType });

    yield put(goodsAction.categoryListSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryListFailure('Category List Error'));
  }
}

function* categoryAdd({ payload }) {
  try {
    const { brandId } = yield select(shopSelector.shopInfo);

    yield call(goodsApiLib.categoryAdd, payload);

    const categoryList = yield call(goodsApiLib.categoryList, { brandId, categoryType: payload.category.categoryType });

    yield put(goodsAction.categoryAddSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryAddFailure('Category Add Error'));
  }
}

function* categoryListAdd({ payload }) {
  try {
    yield call(goodsApiLib.categoryListAdd, payload);

    yield put(goodsAction.categoryListAddSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.categoryListAddFailure('Category List Add Error'));
  }
}

function* categoryUpdate({ payload }) {
  try {
    const { brandId } = yield select(shopSelector.shopInfo);

    yield call(goodsApiLib.categoryUpdate, payload);

    const categoryList = yield call(goodsApiLib.categoryList, { brandId, categoryType: payload.categoryType });

    yield put(goodsAction.categoryUpdateSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryUpdateFailure('Category Update Error'));
  }
}

function* categoryRemove({ payload }) {
  try {
    const { brandId } = yield select(shopSelector.shopInfo);

    yield call(goodsApiLib.categoryRemove, payload);

    const categoryList = yield call(goodsApiLib.categoryList, { brandId, categoryType: payload.categoryType });

    yield put(goodsAction.categoryRemoveSuccess({ categoryList }));
  } catch (err) {
    yield put(goodsAction.categoryRemoveFailure({ error: err }));
  }
}

function* categoryChangeTrafficLight({ payload }) {
  try {
    yield call(goodsApiLib.categoryChangeTrafficLight, payload);

    yield put(goodsAction.categoryChangeTrafficLightSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.categoryChangeTrafficLightFailure('Category Change Traffic List Error'));
  }
}

function* categoryReorder({ payload: { updateInfoList } }) {
  try {
    yield call(goodsApiLib.categoryReorder, { updateInfoList });

    yield put(goodsAction.categoryReorderSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.categoryReorderFailure('Goods Update Error'));
  }
}

function* menuList({ payload }) {
  try {
    const menuList = yield call(goodsApiLib.menuList, payload);

    yield put(goodsAction.menuListSuccess({ menuList }));
  } catch (err) {
    yield put(goodsAction.menuListFailure('Menu List Error'));
  }
}

function* menuAdd({ payload }) {
  try {
    let imagePath = payload.menu.imagePath;
    if (payload.uploadFlag === 'UPLOAD') {
      imagePath = yield call(goodsApiLib.upload, payload);
    } else if (payload.uploadFlag === 'COPY') {
      imagePath = yield call(goodsApiLib.copy, payload);
    }

    yield call(goodsApiLib.menuAdd, { ...payload, imagePath });

    yield put(goodsAction.menuAddSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.menuAddFailure('Menu Add Error'));
  }
}

function* menuUpdate({ payload }) {
  try {
    let imagePath = payload.menu.imagePath;
    if (payload.file) {
      imagePath = yield call(goodsApiLib.upload, payload);
    }
    yield call(goodsApiLib.menuUpdate, { ...payload, imagePath });

    yield put(goodsAction.menuUpdateSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.menuUpdateFailure('Menu Update Error'));
  }
}

function* menuRemove({ payload }) {
  try {
    yield call(goodsApiLib.menuRemove, payload);

    yield put(goodsAction.menuRemoveSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.menuRemoveFailure('Menu Remove Error'));
  }
}

function* menuChangeSoldOut({ payload }) {
  try {
    yield call(goodsApiLib.menuChangeSoldOut, payload);

    yield put(goodsAction.menuChangeSoldOutSuccess());
  } catch (err) {
    yield put(goodsAction.menuChangeSoldOutFailure('Menu Change Sold Out Error'));
  }
}

function* goodsList({ payload }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType: payload.goodsType });

    yield put(goodsAction.goodsListSuccess({ goodsList }));
  } catch (err) {
    yield put(goodsAction.goodsListFailure('Goods List Error'));
  }
}

function* goodsAdd({ payload: { goodsType, goodsList, onUploadProgress } }) {
  try {
    const { brandId } = yield select(state => state[SHOP].shopInfo);

    if (goodsType === 'PACKAGE') {
      const result = yield call(goodsApiLib.goodsAddPresignUpload, { brandId, goods: goodsList[0] });
      const mainGoodsId = result.goodsId;

      for (let i = 1; i < goodsList.length; i++) {
        const file = goodsList[i].file;
        const result = yield call(goodsApiLib.goodsAddPresignUpload, { brandId, goods: { ...goodsList[i], goodsPid: mainGoodsId }, file });
        const presignUrl = result.presignUrl;
        if (presignUrl) {
          yield call(apiLib.fetchUploadApi, result.presignUrl, file, onUploadProgress);
          // yield call(apiLib.fetchUploadApi, result.presignUrl, file, onUploadProgress, i);
        }
      }
    } else if (goodsType === 'SINGLE' || goodsType === 'DOSIRAK') {
      const file = goodsList[0].file;
      const result = yield call(goodsApiLib.goodsAddPresignUpload, { brandId, goods: goodsList[0], file });
      const presignUrl = result.presignUrl;
      if (presignUrl) {
        yield call(apiLib.fetchUploadApi, result.presignUrl, file, onUploadProgress);
      }
    }

    yield put(goodsAction.goodsAddSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsAddFailure('Goods Add Error'));
  }
}

function* goodsDetail({ payload: { brandId, goodsId } }) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    const goodsInfo = yield call(goodsApiLib.goodsDetail, { brandId, shopId, goodsId });

    yield put(goodsAction.goodsDetailSuccess({ goodsInfo }));
  } catch (err) {
    yield put(goodsAction.goodsDetailFailure('Goods Detail Error'));
  }
}

function* goodsEdit({ payload: { goodsType, updateInfoList, removedGoodsId, onUploadProgress } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    if (goodsType === 'PACKAGE') {
      if (removedGoodsId) {
        yield call(goodsApiLib.subGoodsRemove, { removedGoodsId });
      }

      for (const updateInfo of updateInfoList) {
        if (!updateInfo.goodsId) {
          const file = updateInfo.file;
          const result = yield call(goodsApiLib.subGoodsAdd, { brandId, goods: updateInfo, file });
          const presignUrl = result.presignUrl;
          if (presignUrl) {
            yield call(apiLib.fetchUploadApi, result.presignUrl, file, onUploadProgress);
            // yield call(apiLib.fetchUploadApi, result.presignUrl, file, onUploadProgress, i);
          }
        } else {
          yield call(goodsApiLib.goodsEdit, { brandId, shopId, updateInfo });
          if (updateInfo.file) {
            yield call(goodsApiLib.imageUpdate, { brandId, goodsId: updateInfo.goodsId, file: updateInfo.file });
          }
        }
      }
    } else if (goodsType === 'SINGLE' || goodsType === 'DOSIRAK') {
      yield call(goodsApiLib.goodsEdit, { brandId, shopId, updateInfo: updateInfoList[0] });
      if (updateInfoList[0].file) {
        yield call(goodsApiLib.imageUpdate, { brandId, goodsId: updateInfoList[0].goodsId, file: updateInfoList[0].file });
      }
    }

    yield put(goodsAction.goodsEditSuccess());
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsEditFailure('Goods Edit Error'));
  }
}

function* goodsUpdate({ payload: { goodsType, updateInfo } }) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsEdit, { shopId, updateInfo });

    // const goodsList = yield call(goodsApiLib.goodsList, { brandId });

    yield put(goodsAction.goodsUpdateSuccess({}));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsUpdateFailure('Goods Update Error'));
  }
}
function* goodsRobotUpdate({ payload: { goodsId, updateInfo, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsRobotynUpdate, { shopId, brandId, goodsId, updateInfo });

    // const goodsList = yield call(goodsApiLib.goodsList, { brandId });
    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.goodsRobotUpdateSuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsRobotUpdateFailure('Goods Update Error'));
  }
}

function* stockUpdate({ payload: { stockId, updateInfo, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.stockUpdate, { shopId, stockId, updateInfo });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.stockUpdateSuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.stockUpdateFailure('Stock Update Error'));
  }
}

function* stockReset({ payload: { stockId, stockPid, updateInfo } }) {
  try {
    const { shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.stockReset, { shopId, stockId, stockPid, updateInfo });

    yield put(goodsAction.stockResetSuccess({ stockId }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.stockResetFailure('Stock Reset Error'));
  }
}

function* goodsRemove({ payload: { removeGoodsList, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsRemove, { brandId, shopId, removeGoodsList });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.goodsRemoveSuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsRemoveFailure('Goods Remove Error'));
  }
}

function* goodsReorder({ payload: { updateInfoList, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsReorder, { updateInfoList });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.goodsReorderSuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsReorderFailure('Goods Update Error'));
  }
}

function* goodsCopy({ payload: { copyGoodsList, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsCopy, { brandId, shopId, copyGoodsList });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.goodsCopySuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsCopyFailure('Goods Copy Error'));
  }
}

function* goodsMove({ payload: { moveGoodsList, moveCategoryId, goodsType } }) {
  try {
    const { brandId, shopId } = yield select(state => state[SHOP].shopInfo);

    yield call(goodsApiLib.goodsMove, { brandId, shopId, moveGoodsList, moveCategoryId });

    const goodsList = yield call(goodsApiLib.goodsList, { brandId, shopId, goodsType });

    yield put(goodsAction.goodsMoveSuccess({ goodsList }));
  } catch (err) {
    console.log(err);
    yield put(goodsAction.goodsMoveFailure('Goods Move Error'));
  }
}

export function* watchCategoryList() {
  yield takeLatest(goodsAction.categoryList, categoryList);
}

export function* watchCategoryAdd() {
  yield takeLatest(goodsAction.categoryAdd, categoryAdd);
}

export function* watchCategoryListAdd() {
  yield takeLatest(goodsAction.categoryListAdd, categoryListAdd);
}

export function* watchCategoryUpdate() {
  yield takeLatest(goodsAction.categoryUpdate, categoryUpdate);
}

export function* watchCategoryRemove() {
  yield takeLatest(goodsAction.categoryRemove, categoryRemove);
}

export function* watchCategoryChangeTrafficLight() {
  yield takeLatest(goodsAction.categoryChangeTrafficLight, categoryChangeTrafficLight);
}

export function* watchCategoryReorder() {
  yield takeLatest(goodsAction.categoryReorder, categoryReorder);
}

export function* watchMenuList() {
  yield takeLatest(goodsAction.menuList, menuList);
}

export function* watchMenuAdd() {
  yield takeLatest(goodsAction.menuAdd, menuAdd);
}

export function* watchMenuUpdate() {
  yield takeLatest(goodsAction.menuUpdate, menuUpdate);
}

export function* watchMenuRemove() {
  yield takeLatest(goodsAction.menuRemove, menuRemove);
}

export function* watchMenuChangeSoldOut() {
  yield takeLatest(goodsAction.menuChangeSoldOut, menuChangeSoldOut);
}

export function* watchGoodsList() {
  yield takeLatest(goodsAction.goodsList, goodsList);
}

export function* watchGoodsAdd() {
  yield takeLatest(goodsAction.goodsAdd, goodsAdd);
}

export function* watchGoodsDetail() {
  yield takeLatest(goodsAction.goodsDetail, goodsDetail);
}

export function* watchGoodsEdit() {
  yield takeLatest(goodsAction.goodsEdit, goodsEdit);
}

export function* watchGoodsUpdate() {
  yield takeLatest(goodsAction.goodsUpdate, goodsUpdate);
}
export function* watchGoodsRobotUpdate() {
  yield takeLatest(goodsAction.goodsRobotUpdate, goodsRobotUpdate);
}

export function* watchStockUpdate() {
  yield takeLatest(goodsAction.stockUpdate, stockUpdate);
}

export function* watchStockReset() {
  yield takeLatest(goodsAction.stockReset, stockReset);
}

export function* watchGoodsRemove() {
  yield takeLatest(goodsAction.goodsRemove, goodsRemove);
}

export function* watchGoodsReorder() {
  yield takeLatest(goodsAction.goodsReorder, goodsReorder);
}

export function* watchGoodsCopy() {
  yield takeLatest(goodsAction.goodsCopy, goodsCopy);
}

export function* watchGoodsMove() {
  yield takeLatest(goodsAction.goodsMove, goodsMove);
}

function* rootSaga() {
  yield all([
    fork(watchCategoryList),
    fork(watchCategoryAdd),
    fork(watchCategoryListAdd),
    fork(watchCategoryUpdate),
    fork(watchCategoryRemove),
    fork(watchCategoryChangeTrafficLight),
    fork(watchCategoryReorder),
    fork(watchMenuList),
    fork(watchMenuAdd),
    fork(watchMenuUpdate),
    fork(watchMenuRemove),
    fork(watchMenuChangeSoldOut),
    fork(watchGoodsList),
    fork(watchGoodsAdd),
    fork(watchGoodsDetail),
    fork(watchGoodsEdit),
    fork(watchGoodsUpdate),
    fork(watchGoodsRobotUpdate),
    fork(watchStockUpdate),
    fork(watchStockReset),
    fork(watchGoodsRemove),
    fork(watchGoodsReorder),
    fork(watchGoodsCopy),
    fork(watchGoodsMove),
  ]);
}

export default rootSaga;
