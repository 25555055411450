import React from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import AuthLayout from '../pages/layouts/Auth';
import ManagerLayout from '../pages/layouts/Manager';
import KraftonSso from '../pages/krafton/Sso';
import ErrorBoundary from './ErrorBoundary';
import FoodSurveyMenuList from '../pages/report/FoodSurveyMenuList';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

const DashboardDefault = React.lazy(() => import('../pages/dashboard/Default'));

const Profile = React.lazy(() => import('../pages/profile/Profile'));

const Playlist = React.lazy(() => import('../pages/content/Playlist'));
const PlaylistAdd = React.lazy(() => import('../pages/content/PlaylistAdd'));
const PlaylistDetail = React.lazy(() => import('../pages/content/PlaylistDetail'));
const Play = React.lazy(() => import('../pages/content/Play'));

const MenuList = React.lazy(() => import('../pages/menu/MenuList'));
const MenuCategoryList = React.lazy(() => import('../pages/menu/CategoryList'));
const MenuCategoryGroupList = React.lazy(() => import('../pages/menu/CategoryGroupList'));
const MenuGoodsTagList = React.lazy(() => import('../pages/menu/MenuGoodsTagList'));

const GoodsList = React.lazy(() => import('../pages/goods/GoodsList'));
const GoodsAdd = React.lazy(() => import('../pages/goods/GoodsAdd'));
const GoodsDetail = React.lazy(() => import('../pages/goods/GoodsDetail'));
const GoodsCategoryList = React.lazy(() => import('../pages/goods/CategoryList'));
const OptionList = React.lazy(() => import('../pages/goods/OptionList'));
const OptionAdd = React.lazy(() => import('../pages/goods/OptionAdd'));
const OptionDetail = React.lazy(() => import('../pages/goods/OptionDetail'));

const DosirakList = React.lazy(() => import('../pages/dosirak/GoodsList'));
const DosirakAdd = React.lazy(() => import('../pages/dosirak/GoodsAdd'));
const DosirakDetail = React.lazy(() => import('../pages/dosirak/GoodsDetail'));
const DosirakCategoryList = React.lazy(() => import('../pages/dosirak/CategoryList'));

const Report = React.lazy(() => import('../pages/report/Report'));
const CafeSalesCalendar = React.lazy(() => import('../pages/report/CafeSalesCalendar'));
const CafeSalesItem = React.lazy(() => import('../pages/report/CafeSalesItem'));
const CafeSalesList = React.lazy(() => import('../pages/report/CafeSalesList'));
const CafeBusiness = React.lazy(() => import('../pages/report/CafeBusiness'));
const CafeAnalysisRank = React.lazy(() => import('../pages/report/CafeAnalysisRank'));
const CafeAnalysisList = React.lazy(() => import('../pages/report/CafeAnalysisList'));
const FoodSalesCalendar = React.lazy(() => import('../pages/report/FoodSalesCalendar'));
const FoodSalesItem = React.lazy(() => import('../pages/report/FoodSalesItem'));
const FoodSalesList = React.lazy(() => import('../pages/report/FoodSalesList'));
const FoodOrderList = React.lazy(() => import('../pages/report/FoodOrderList'));
const FoodSalesKrafton = React.lazy(() => import('../pages/report/FoodSalesKrafton'));
const FoodSurveyMenu = React.lazy(() => import('../pages/report/FoodSurveyMenu'));
const FoodSurveyMenuRank = React.lazy(() => import('../pages/report/FoodSurveyMenuRank'));
const FoodRatingItem = React.lazy(() => import('../pages/report/FoodRatingItem'));
const FoodRatingList = React.lazy(() => import('../pages/report/FoodRatingList'));

const Survey = React.lazy(() => import('../pages/survey/Survey'));
const SurveyFoodList = React.lazy(() => import('../pages/survey/FoodList'));
const SurveyFoodAdd = React.lazy(() => import('../pages/survey/FoodAdd'));
const SurveyFoodEdit = React.lazy(() => import('../pages/survey/FoodEdit'));
const SurveyFoodProgress = React.lazy(() => import('../pages/survey/FoodProgress'));
const SurveyFoodResult = React.lazy(() => import('../pages/survey/FoodResult'));
const Order = React.lazy(() => import('../pages/order/Order'));

const Setting = React.lazy(() => import('../pages/system/Setting'));

const Routes = () => {
  const authRoutes: RouteObject = {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/auth/login" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  };

  const rootRoutes: RouteObject = {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard" />,
      },
    ],
  };

  const kraftonRoutes: RouteObject = {
    path: '/krafton/sso',
    element: <KraftonSso />,
  };

  const dashboardRoutes: RouteObject = {
    path: '/dashboard',
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <DashboardDefault />,
      },
    ],
  };

  const profileRoutes: RouteObject = {
    path: '/profile',
    element: <ManagerLayout />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  };

  const kioskRoutes: RouteObject = {
    path: '/kiosk',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        children: [
          {
            path: 'list',
            element: <Playlist playlistType="KIOSK" url="kiosk" />,
          },
          {
            path: 'add',
            element: <PlaylistAdd packageType="_KIOSK" url="kiosk" />,
          },
          {
            path: 'detail',
            element: <PlaylistDetail packageType="_KIOSK" url="kiosk" />,
          },
        ],
      },
      {
        path: 'play/list',
        element: <Play playlistType="KIOSK" packageType="_KIOSK" url="kiosk" productType="OK" />,
      },
    ],
  };

  const menuboardRoutes: RouteObject = {
    path: '/menu-board',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        children: [
          {
            path: 'list',
            element: <Playlist playlistType="MENUBOARD" url="menu-board" />,
          },
          {
            path: 'add',
            element: <PlaylistAdd packageType="_MENUBOARD" url="menu-board" />,
          },
          {
            path: 'detail',
            element: <PlaylistDetail packageType="_MENUBOARD" url="menu-board" />,
          },
        ],
      },
      {
        path: 'play/list',
        element: <Play playlistType="MENUBOARD" packageType="_MENUBOARD" url="menu-board" productType="MB" />,
      },
    ],
  };

  const pickupboardRoutes: RouteObject = {
    path: '/pickup-board',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        children: [
          {
            path: 'list',
            element: <Playlist playlistType="PICKUPBOARD" url="pickup-board" />,
          },
          {
            path: 'add',
            element: <PlaylistAdd packageType="_PICKUPBOARD" url="pickup-board" />,
          },
          {
            path: 'detail',
            element: <PlaylistDetail packageType="_PICKUPBOARD" url="pickup-board" />,
          },
        ],
      },
      {
        path: 'play/list',
        element: <Play playlistType="PICKUPBOARD" packageType="_PICKUPBOARD" url="pickup-board" productType="PB" />,
      },
    ],
  };

  const wayFindingRoutes: RouteObject = {
    path: '/way-finding',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        children: [
          {
            path: 'list',
            element: <Playlist playlistType="WAYFINDING" url="way-finding" />,
          },
          {
            path: 'add',
            element: <PlaylistAdd packageType="_WAYFINDING" url="way-finding" />,
          },
          {
            path: 'detail',
            element: <PlaylistDetail packageType="_WAYFINDING" url="way-finding" />,
          },
        ],
      },
      {
        path: 'play/list',
        element: <Play playlistType="WAYFINDING" packageType="_WAYFINDING" url="way-finding" productType="WF" />,
      },
    ],
  };

  const menuSurveyRoutes: RouteObject = {
    path: '/menu-survey',
    element: <ManagerLayout />,
    children: [
      {
        path: 'playlist',
        children: [
          {
            path: 'list',
            element: <Playlist playlistType="MENU_SURVEY" url="menu-survey" />,
          },
          {
            path: 'add',
            element: <PlaylistAdd packageType="_MENU_SURVEY" url="menu-survey" />,
          },
          {
            path: 'detail',
            element: <PlaylistDetail packageType="_MENU_SURVEY" url="menu-survey" />,
          },
        ],
      },
      {
        path: 'play/list',
        element: <Play playlistType="MENU_SURVEY" packageType="_MENU_SURVEY" url="menu-survey" productType="CS" />,
      },
    ],
  };

  const menuRoutes: RouteObject = {
    path: '/menu',
    element: <ManagerLayout />,
    children: [
      {
        path: 'menu/list',
        element: <MenuList />,
      },
      {
        path: 'category/list',
        element: <MenuCategoryList />,
      },
      {
        path: 'category-group/list',
        element: <MenuCategoryGroupList />,
      },
      {
        path: 'goods/tag',
        element: <MenuGoodsTagList />,
      },
    ],
  };

  const reportRoutes: RouteObject = {
    path: '/report',
    element: <ManagerLayout />,
    children: [
      {
        path: 'cafe',
        element: <Report />,
        children: [
          {
            path: 'sales',
            children: [
              { path: 'calendar', element: <CafeSalesCalendar /> },
              { path: 'item', element: <CafeSalesItem /> },
              { path: 'list', element: <CafeSalesList /> },
            ],
          },
          {
            path: 'business',
            element: <CafeBusiness />,
          },
          {
            path: 'analysis',
            children: [
              { path: 'rank', element: <CafeAnalysisRank /> },
              { path: 'list', element: <CafeAnalysisList /> },
            ],
          },
        ],
      },
      {
        path: 'food',
        element: <Report />,
        children: [
          {
            path: 'sales',
            children: [
              { path: 'calendar', element: <FoodSalesCalendar /> },
              { path: 'item', element: <FoodSalesItem /> },
              { path: 'list', element: <FoodSalesList /> },
              { path: 'krafton', element: <FoodSalesKrafton /> },
            ],
          },
          {
            path: 'order/list',
            element: <FoodOrderList />,
          },
          {
            path: 'survey',
            children: [
              { path: '', element: <FoodSurveyMenu /> },
              { path: 'list', element: <FoodSurveyMenuList /> },
              { path: 'rank', element: <FoodSurveyMenuRank /> },
            ],
          },
          {
            path: 'rating',
            children: [
              { path: 'item', element: <FoodRatingItem /> },
              { path: 'list', element: <FoodRatingList /> },
            ],
          },
        ],
      },
    ],
  };

  const surveyRoutes: RouteObject = {
    path: '/survey',
    element: <ManagerLayout />,
    children: [
      {
        path: 'food',
        element: <Survey />,
        children: [
          {
            path: 'list',
            element: <SurveyFoodList />,
          },
          {
            path: 'add',
            element: <SurveyFoodAdd />,
          },
          {
            path: 'edit',
            element: <SurveyFoodEdit />,
          },
          {
            path: 'progress',
            element: <SurveyFoodProgress />,
          },
          {
            path: 'result',
            element: <SurveyFoodResult />,
          },
        ],
      },
    ],
  };

  const systemRoutes: RouteObject = {
    path: '/system',
    element: <ManagerLayout />,
    children: [
      {
        path: 'setting',
        element: <Setting />,
      },
      {
        path: 'device/list',
        element: <Setting />,
      },
      {
        path: 'device/detail',
        element: <Setting />,
      },
      {
        path: 'member',
        element: <Setting />,
      },
      {
        path: 'storage',
        element: <Setting />,
      },
      {
        path: 'customer/list',
        element: <Setting />,
      },
      {
        path: 'customer/detail',
        element: <Setting />,
      },
      {
        path: 'customer/add',
        element: <Setting />,
      },
      {
        path: 'open',
        element: <Setting />,
      },
      {
        path: 'openRobot',
        element: <Setting />,
      },
      {
        path: 'alert',
        element: <Setting />,
      },
      {
        path: 'pickupled',
        element: <Setting />,
      },
      {
        path: 'excelFileList',
        children: [
          {
            path: 'list',
            element: <Setting />,
          },
          {
            path: 'detail',
            element: <Setting />,
          },
        ],
      },
      {
        path: 'menuSurvey/setting',
        element: <Setting />,
      },
    ],
  };

  const goodsRoutes: RouteObject = {
    path: '/goods',
    element: <ManagerLayout />,
    children: [
      {
        path: 'goods/list',
        element: <GoodsList />,
      },
      {
        path: 'goods/add',
        element: <GoodsAdd />,
      },
      {
        path: 'goods/detail',
        element: <GoodsDetail />,
      },
      {
        path: 'category/list',
        element: <GoodsCategoryList />,
      },
      {
        path: 'option/list',
        element: <OptionList />,
      },
      {
        path: 'option/add',
        element: <OptionAdd />,
      },
      {
        path: 'option/detail',
        element: <OptionDetail />,
      },
    ],
  };

  const dosirakRoutes: RouteObject = {
    path: '/dosirak',
    element: <ManagerLayout />,
    children: [
      {
        path: 'dosirak/list',
        element: <DosirakList />,
      },
      {
        path: 'dosirak/add',
        element: <DosirakAdd />,
      },
      {
        path: 'dosirak/detail',
        element: <DosirakDetail />,
      },
      {
        path: 'category/list',
        element: <DosirakCategoryList />,
      },
    ],
  };

  const orderRoutes: RouteObject = {
    path: '/order',
    element: <ManagerLayout />,
    children: [
      {
        path: 'order',
        element: <Order />,
      },
    ],
  };

  const noMatchRoutes: RouteObject = {
    path: '*',
    element: <Navigate to="/" />,
  };

  const routes: RouteObject[] = [
    rootRoutes,
    kraftonRoutes,
    noMatchRoutes,
    authRoutes,
    dashboardRoutes,
    profileRoutes,
    kioskRoutes,
    menuboardRoutes,
    pickupboardRoutes,
    wayFindingRoutes,
    menuSurveyRoutes,
    goodsRoutes,
    reportRoutes,
    surveyRoutes,
    systemRoutes,
    menuRoutes,
    dosirakRoutes,
    orderRoutes,
  ];

  return useRoutes(routes);
};

const Router = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default React.memo(Router);
