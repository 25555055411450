import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CategoryInfo {
  categoryId: string;
  categoryPid: string;
  spaceId: string;
  brandId: string;
  categoryNm: string;
  regId: string;
  regDt: string;
  modId: string;
  modDt: string;
  useYn: string;
  delYn: string;
  delDt: string;
}

interface GoodsState {
  categoryList: Array<CategoryInfo>;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: GoodsState = {
  categoryList: [],
  categoryListAll: [],
  categoryFilterInfo: {},
  menuList: [],
  goodsList: [],
  goodsInfo: {},
  goodsFilterInfo: {
    categoryId: '',
    searchValue: '',
  },
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  actionResultClear: (state: GoodsState) => {
    state.actionResult = '';
  },
  goodsFilterInfoClear: (state: GoodsState) => {
    state.goodsFilterInfo = {
      categoryId: '',
      searchValue: '',
    };
  },
  goodsStateClear: (state: GoodsState) => {
    state.categoryList = [];
    state.categoryListAll = [];
    state.categoryFilterInfo = {};
    state.menuList = [];
    state.goodsList = [];
    state.goodsInfo = {};
    state.goodsFilterInfo = {
      ...state.goodsFilterInfo, // 필터링 상태 유지
      searchValue: '',
    };
    state.actionResult = '';
  },
  changeGoodsFilter(state: GoodsState, { payload: { categoryId } }: PayloadAction<GoodsPayload>) {
    state.goodsFilterInfo = {
      ...state.goodsFilterInfo,
      categoryId: categoryId,
    };
  },
  categoryFilter(state: GoodsState, { payload: { useYn, categoryNm, categoryEngNm } }: PayloadAction<GoodsPayload>) {
    state.categoryFilterInfo.useYn = useYn;
    state.categoryFilterInfo.categoryNm = categoryNm;
    state.categoryFilterInfo.categoryEngNm = categoryEngNm;
  },
  categoryList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList.filter(category => category.delYn !== 'Y');
    state.categoryListAll = payload.categoryList;
    state.actionResult = 'CATEGORY_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryAdd: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryAddSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList.filter(category => category.delYn !== 'Y');
    state.categoryListAll = payload.categoryList;
    state.actionResult = 'CATEGORY_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryAddFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryListAdd: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_LIST_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryListAddSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'CATEGORY_LIST_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryListAddFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_LIST_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList.filter(category => category.delYn !== 'Y');
    state.categoryListAll = payload.categoryList;
    state.actionResult = 'CATEGORY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryRemove: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryRemoveSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.categoryList = payload.categoryList.filter(category => category.delYn !== 'Y');
    state.categoryListAll = payload.categoryList;
    state.actionResult = 'CATEGORY_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryRemoveFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload.error;
  },
  categoryChangeTrafficLight: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'CATEGORY_CHANGE_TRAFFIC_LIGHT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryChangeTrafficLightSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'CATEGORY_CHANGE_TRAFFIC_LIGHT_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryChangeTrafficLightFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_CHANGE_TRAFFIC_LIGHT_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  categoryReorder: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.categoryList = payload.updateInfoList;
    state.actionResult = 'CATEGORY_REORDER_REQ';
    state.isLoading = true;
    state.error = null;
  },
  categoryReorderSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'CATEGORY_REORDER_OK';
    state.isLoading = false;
    state.error = null;
  },
  categoryReorderFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'CATEGORY_REORDER_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  menuList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'MENU_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  menuListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.menuList = payload.menuList;
    state.actionResult = 'MENU_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  menuListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'MENU_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  menuAdd: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'MENU_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  menuAddSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'MENU_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  menuAddFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'MENU_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  menuUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'MENU_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  menuUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'MENU_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  menuUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'MENU_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  menuRemove: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'MENU_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  menuRemoveSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'MENU_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  menuRemoveFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'MENU_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  menuChangeSoldOut: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'MENU_CHANGE_SOLD_OUT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  menuChangeSoldOutSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'MENU_CHANGE_SOLD_OUT_OK';
    state.isLoading = false;
    state.error = null;
  },
  menuChangeSoldOutFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'MENU_CHANGE_SOLD_OUT_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsFilter(state: OptionState, { payload }: PayloadAction<OptionPayload>) {
    state.goodsFilterInfo.categoryId = payload.categoryId;
    state.goodsFilterInfo.searchValue = payload.searchValue;
  },
  goodsList: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsListSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsListFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_LIST_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsAdd: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsAddSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'GOODS_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsAddFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_ADD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsDetail: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsDetailSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsInfo = payload.goodsInfo;
    state.actionResult = 'GOODS_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsDetailFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_DETAIL_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsEdit: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsEditSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.actionResult = 'GOODS_EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsEditFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_EDIT_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    // state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsRobotUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_ROBOTYN_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsRobotUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_ROBOTYN_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsRobotUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_ROBOTYN_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  stockUpdate: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'STOCK_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  stockUpdateSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'STOCK_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  stockUpdateFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'STOCK_UPDATE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  stockReset: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'STOCK_RESET_REQ';
    state.isLoading = true;
    state.error = null;
  },
  stockResetSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    if (state.goodsInfo.subGoodsList.length > 0) {
      const targetIdx = state.goodsInfo.subGoodsList.findIndex(subGoods => subGoods.stockId === payload.stockId);
      state.goodsInfo.subGoodsList[targetIdx].orderCnt = 0;
    } else {
      state.goodsInfo.mainGoods.orderCnt = 0;
    }

    state.actionResult = 'STOCK_RESET_OK';
    state.isLoading = false;
    state.error = null;
  },
  stockResetFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'STOCK_RESET_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsRemove: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsRemoveSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsRemoveFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_REMOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsReorder: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_REORDER_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsReorderSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_REORDER_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsReorderFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_REORDER_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsCopy: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_COPY_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsCopySuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_COPY_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsCopyFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_COPY_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  goodsMove: (state: GoodsState, { payload }: PayloadAction<GoodsPayload>) => {
    state.actionResult = 'GOODS_MOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  goodsMoveSuccess: (state: GoodsState, { payload }: PayloadAction<GoodsState>) => {
    state.goodsList = payload.goodsList;
    state.actionResult = 'GOODS_MOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  goodsMoveFailure: (state: GoodsState, { payload }: PayloadAction<string>) => {
    state.actionResult = 'GOODS_MOVE_ERR';
    state.isLoading = false;
    state.error = payload;
  },
};

const slice = createSlice({
  name: 'goods',
  initialState,
  reducers,
});

const selectCategoryListAll = createDraftSafeSelector(
  (state: GoodsState) => state.categoryListAll,
  categoryListAll => {
    const orderedCategoryList = [...categoryListAll].sort(function (lhs, rhs) {
      return lhs.categoryOrder - rhs.categoryOrder;
    });

    return orderedCategoryList;
  },
);

const selectCategoryList = createDraftSafeSelector(
  (state: GoodsState) => state.categoryList,
  categoryList => {
    const orderedCategoryList = [...categoryList].sort(function (lhs, rhs) {
      return lhs.categoryOrder - rhs.categoryOrder;
    });

    return orderedCategoryList;
  },
);

const selectJoinCategoryList = createDraftSafeSelector(
  (state: any) => state.goods.categoryList,
  (state: any) => state.shop.shopInfo,
  (categoryList, shopInfo) => {
    const mainCategoryList = categoryList.filter(category => category.categoryPid === shopInfo.brandId);
    const subCategoryList = categoryList.filter(category => category.categoryPid !== shopInfo.brandId);

    let newCategoryList = [];
    for (let mainCategory of mainCategoryList) {
      newCategoryList.push({
        ...mainCategory,
        operatingInfo: [],
      });
    }

    for (let newCategory of newCategoryList) {
      newCategory.operatingInfo = subCategoryList.filter(subCategory => subCategory.categoryPid === newCategory.categoryId);
    }

    return newCategoryList;
  },
);

const selectSortedCategoryList = createDraftSafeSelector(
  (state: any) => state.goods.categoryList,
  (state: any) => state.shop.shopInfo,
  (categoryList, shopInfo) => {
    const mainCategoryList = categoryList.filter(category => category.categoryPid === shopInfo.brandId);
    const subCategoryList = categoryList.filter(category => category.categoryPid !== shopInfo.brandId && category.useYn === 'Y');

    return {
      mainCategoryList,
      subCategoryList,
    };
  },
);

const selectPageCategoryList = createDraftSafeSelector(
  (state: GoodsState) => state.categoryFilterInfo,
  (state: GoodsState) => state.categoryList,
  (state: GoodsState) => state.goodsList,

  (categoryFilterInfo, categoryList, goodsList) => {
    if (
      (!categoryFilterInfo.useYn || categoryFilterInfo.useYn.trim() === '') &&
      (!categoryFilterInfo.categoryNm || categoryFilterInfo.categoryNm.trim() === '') &&
      (!categoryFilterInfo.categoryEngNm || categoryFilterInfo.categoryEngNm.trim() === '')
    ) {
      let sliceList = [...categoryList].sort((l, r) => {
        return l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1;
      });
      // 카테고리 안에 등록상품 join
      if (goodsList.length > 0) {
        sliceList = sliceList.map(list => {
          let goodsCnt = 0;
          // let singleGoodsList = goodsList.filter(goods => goods.goodsType === 'SINGLE'); // 단일 상품들
          // goodsCnt += singleGoodsList.filter(goods => goods.categoryId === list.categoryId).length;

          // let packageMainGoodsList = goodsList.filter(goods => goods.goodsType === 'PACKAGE' && goods.goodsPid === goods.brandId); // 묶음상품 최상위
          // let packageSubGoodsList = []; // 묶음상품 하위들
          // for (const packageMainGoods of packageMainGoodsList) {
          //   packageSubGoodsList = [...packageSubGoodsList, ...goodsList.filter(goods => goods.goodsPid === packageMainGoods.goodsId)];
          // }
          // goodsCnt += packageSubGoodsList.filter(goods => goods.categoryId === list.categoryId).length;
          let parentGoodsList = goodsList.filter(goods => goods.goodsPid === goods.brandId); // 상품 최상위
          goodsCnt += parentGoodsList.filter(goods => goods.categoryId === list.categoryId).length;

          return { ...list, goodsCnt };
        });
      }

      return {
        categoryList: sliceList,
      };
    }

    const filterCategoryList = categoryList.filter(category => {
      let useYnFilter = true;
      let categoryNmFilter = true;
      let categoryEngNmFilter = true;

      if (categoryFilterInfo.useYn && categoryFilterInfo.useYn !== '') {
        useYnFilter = category.useYn === categoryFilterInfo.useYn;
      }

      if (categoryFilterInfo.categoryNm && categoryFilterInfo.categoryNm !== '') {
        categoryNmFilter = category.categoryNm?.toLowerCase().indexOf(categoryFilterInfo.categoryNm.toLowerCase()) >= 0;
      }

      if (categoryFilterInfo.categoryEngNm && categoryFilterInfo.categoryEngNm !== '') {
        categoryEngNmFilter = category.categoryEngNm?.toLowerCase().indexOf(categoryFilterInfo.categoryEngNm.toLowerCase()) >= 0;
      }

      return useYnFilter && (categoryNmFilter || categoryEngNmFilter);
    });

    let sliceList = [...filterCategoryList].sort((l, r) => {
      return l.categoryOrder === r.categoryOrder ? 0 : l.categoryOrder < r.categoryOrder ? -1 : 1;
    });

    // 카테고리 안에 등록상품 join
    if (goodsList.length > 0) {
      sliceList = sliceList.map(list => {
        let goodsCnt = 0;
        let singleGoodsList = goodsList.filter(goods => goods.goodsType === 'SINGLE'); // 단일 상품들
        goodsCnt += singleGoodsList.filter(goods => goods.categoryId === list.categoryId).length;

        let packageMainGoodsList = goodsList.filter(goods => goods.goodsType === 'PACKAGE' && goods.goodsPid === goods.brandId); // 묶음상품 최상위
        let packageSubGoodsList = []; // 묶음상품 하위들
        for (const packageMainGoods of packageMainGoodsList) {
          packageSubGoodsList = [...packageSubGoodsList, ...goodsList.filter(goods => goods.goodsPid === packageMainGoods.goodsId)];
        }
        goodsCnt += packageSubGoodsList.length;

        return { ...list, goodsCnt };
      });
    }

    return {
      categoryList: sliceList,
    };
  },
);

const selectMenuList = createDraftSafeSelector(
  (state: GoodsState) => state.menuList,
  menuList => menuList,
);

const selectMenuListByCategory = createDraftSafeSelector(
  (state: any) => state.shop.shopInfo,
  (state: any) => state.goods.categoryList,
  (state: any) => state.goods.menuList,
  (shopInfo, categoryList, menuList) => {
    const mainCategoryList = categoryList.filter(category => category.categoryPid === shopInfo.brandId);

    let newCategoryList = [];
    for (const mainCategory of mainCategoryList) {
      let newMenuList = [];
      const subCategoryList = categoryList.filter(subCategory => subCategory.categoryPid === mainCategory.categoryId && subCategory.useYn === 'Y');
      for (const subCategory of subCategoryList) {
        const filterMenuList = menuList.filter(menu => menu.categoryId === subCategory.categoryId);
        for (const filterMenu of filterMenuList) {
          newMenuList.push({
            ...subCategory,
            ...filterMenu,
          });
        }
      }

      newCategoryList.push({
        ...mainCategory,
        menuList: newMenuList,
      });
    }

    return newCategoryList;
  },
);

const selectGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goodsList,
  goodsList => goodsList,
);

const selectJoinGoodsList = createDraftSafeSelector(
  (state: GoodsState) => state.goods.goodsList,
  (state: any) => state.shop.shopInfo,
  (state: GoodsState) => state.goods.goodsFilterInfo,
  (goodsList, shopInfo, goodsFilterInfo) => {
    const mainGoodsList = goodsList.filter(goods => goods.goodsPid === shopInfo.brandId);
    const subGoodsList = goodsList.filter(goods => goods.goodsPid !== shopInfo.brandId);

    let joinGoodsList = [];
    for (let mainGoods of mainGoodsList) {
      joinGoodsList.push({
        ...mainGoods,
        goodsList: [],
      });
    }

    for (let joinGoods of joinGoodsList) {
      joinGoods.goodsList = subGoodsList.filter(subGoods => subGoods.goodsPid === joinGoods.goodsId);
    }

    let filteredGoodsList = joinGoodsList;
    if (goodsFilterInfo.categoryId || goodsFilterInfo.searchValue) {
      filteredGoodsList = joinGoodsList.filter(joinGoods => {
        let categoryIdFilter = true;
        let goodsNmFilter = true;
        let goodsEngNmFilter = true;

        if (goodsFilterInfo.categoryId) {
          categoryIdFilter = joinGoods.categoryId === goodsFilterInfo.categoryId;
        }

        if (goodsFilterInfo.searchValue) {
          goodsNmFilter = joinGoods.goodsNm?.toLowerCase().includes(goodsFilterInfo.searchValue.toLowerCase());
        }
        if (goodsFilterInfo.searchValue) {
          goodsEngNmFilter = joinGoods.goodsEngNm?.toLowerCase().includes(goodsFilterInfo.searchValue.toLowerCase());
        }

        return categoryIdFilter && (goodsNmFilter || goodsEngNmFilter);
      });
    }

    return filteredGoodsList;
  },
);

const selectGoodsInfo = createDraftSafeSelector(
  (state: GoodsState) => state.goodsInfo,
  goodsInfo => goodsInfo,
);

const selectGoodsFilterInfo = createDraftSafeSelector(
  (state: GoodsState) => state.goodsFilterInfo,
  goodsFilterInfo => goodsFilterInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: GoodsState) => state.actionResult,
  (state: GoodsState) => state.isLoading,
  (state: GoodsState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const goodsSelector = {
  categoryListAll: state => selectCategoryListAll(state[GOODS]),
  categoryList: state => selectCategoryList(state[GOODS]),
  pageCategoryList: state => selectPageCategoryList(state[GOODS]),
  joinCategoryList: state => selectJoinCategoryList(state),
  sortedCategoryList: state => selectSortedCategoryList(state),
  menuList: state => selectMenuList(state[GOODS]),
  menuListByCategory: state => selectMenuListByCategory(state),
  goodsList: state => selectGoodsList(state[GOODS]),
  joinGoodsList: state => selectJoinGoodsList(state),
  goodsInfo: state => selectGoodsInfo(state[GOODS]),
  goodsFilterInfo: state => selectGoodsFilterInfo(state[GOODS]),
  status: state => selectStatus(state[GOODS]),
};

export const GOODS = slice.name;
export const goodsReducer = slice.reducer;
export const goodsAction = slice.actions;
