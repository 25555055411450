import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HashLoader from 'react-spinners/HashLoader';
import qs from 'qs';

import { authAction, authSelector } from '../../module/authSlice';

const Token = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const navigate = useNavigate();
  const status = useSelector(authSelector.status);

  const [token, setToken] = useState('');

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const _token = params.t;
    setToken(_token);
  }, [search]);

  useEffect(() => {
    if (!token || token === '') {
      return;
    }

    dispatch(authAction.kraftonToken({ loginType: 'krafton-sso', ssoToken: token }));
  }, [token, dispatch]);

  useEffect(() => {
    if (status.actionResult === 'KRAFTON_TOKEN_OK') {
      dispatch(authAction.actionResultClear());
      navigate('/dashboard');
    } else if (status.actionResult === 'KRAFTON_TOKEN_ERR') {
      dispatch(authAction.actionResultClear());
      navigate('/auth/login');
    }
  }, [dispatch, navigate, status]);

  return (
    <Container>
      <HashLoader
        css={{
          display: 'block',
          width: '100%',
          height: '100%',
          margin: '0 auto',
        }}
        color={'#41a1ea'}
        loading={true}
        size={100}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  padding: 6.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.grayBackground};
`;

export default React.memo(Token);
