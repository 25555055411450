import * as apiLib from './api';

export const categoryList = async ({ brandId, categoryType }) => {
  try {
    const data = {
      brandId,
      categoryType,
    };

    const result = await apiLib.fetchStoreApi('/category/list', data);

    if (result.resultFlag) {
      return result.categoryList;
    } else {
      throw Object.assign(new Error('Category List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryAdd = async ({ category }) => {
  try {
    const data = {
      ...category,
    };

    const result = await apiLib.fetchStoreApi('/category/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryListAdd = async ({ submitCategoryList, removedCategoryIds }) => {
  try {
    const data = {
      categoryList: submitCategoryList,
      removedCategoryIds,
    };

    const result = await apiLib.fetchStoreApi('/category/list-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category List Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryUpdate = async ({ categoryId, updateInfo }) => {
  try {
    const data = {
      categoryId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/category/update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryRemove = async ({ categoryIdList }) => {
  try {
    const data = {
      categoryIdList,
    };

    const result = await apiLib.fetchStoreApi('/category/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Remove Api Error'), { status: 401, errorType: result.errorType });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryChangeTrafficLight = async ({ categoryId, trafficLight }) => {
  try {
    const data = {
      categoryId,
      trafficLight,
    };

    const result = await apiLib.fetchStoreApi('/category/change-traffic-light', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Category Change Traffic Light Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const categoryReorder = async ({ updateInfoList }) => {
  try {
    const data = {
      updateInfoList,
    };

    const result = await apiLib.fetchStoreApi('/category/reorder', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Reorder Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const menuList = async ({ shopId, orderDt, menuListType }) => {
  try {
    const data = {
      shopId,
      orderDt,
      menuListType,
    };

    const result = await apiLib.fetchStoreApi('/goods/menu-list', data);

    if (result.resultFlag) {
      return result.menuList;
    } else {
      throw Object.assign(new Error('Menu List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsAddPresignUpload = async ({ brandId, goods, file }) => {
  try {
    const data = {
      brandId,
      goods,
      fileNm: file && file.name,
      fileType: file && file.type,
    };

    const result = await apiLib.fetchStoreApi('/goods/goods-add-presign-upload', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('Presign Upload Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const upload = async ({ brandId, goodsId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('brandId', brandId);
    uploadData.append('goodsId', goodsId || '');
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchStoreMultipartApi('/goods/image-upload', uploadData);

    if (result.resultFlag) {
      return result.imagePath;
    } else {
      throw Object.assign(new Error('Goods Image Upload Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const copy = async ({ brandId, menu }) => {
  try {
    const data = {
      brandId,
      imagePath: menu.imagePath,
    };

    const result = await apiLib.fetchStoreApi('/goods/image-copy', data);

    if (result.resultFlag) {
      return result.imagePath;
    } else {
      throw Object.assign(new Error('Goods Image Copy Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const menuAdd = async ({ menu, imagePath }) => {
  try {
    const data = {
      menu,
      imagePath,
    };

    const result = await apiLib.fetchStoreApi('/goods/menu-add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Menu Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const menuUpdate = async ({ menu, imagePath }) => {
  try {
    const data = {
      menu,
      imagePath,
    };

    const result = await apiLib.fetchStoreApi('/goods/menu-update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Menu Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const menuRemove = async ({ goodsId, stockId }) => {
  try {
    const data = {
      goodsId,
      stockId,
    };

    const result = await apiLib.fetchStoreApi('/goods/menu-remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Menu Remove Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const menuChangeSoldOut = async ({ stockId, soldOutYn }) => {
  try {
    const data = {
      stockId,
      soldOutYn,
    };

    const result = await apiLib.fetchStoreApi('/goods/change-sold-out', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Menu Change Sold Out Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsList = async ({ brandId, shopId, goodsType }) => {
  try {
    const data = {
      brandId,
      shopId,
      goodsType,
    };

    let result = [];
    if (goodsType === 'DOSIRAK') {
      result = await apiLib.fetchStoreApi('/goods/dosirak-list', data);
    } else {
      result = await apiLib.fetchStoreApi('/goods/list', data);
    }

    if (result.resultFlag) {
      return result.goodsList;
    } else {
      throw Object.assign(new Error('Goods List Api error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsDetail = async ({ brandId, shopId, goodsId }) => {
  try {
    const data = {
      brandId,
      shopId,
      goodsId,
    };

    const result = await apiLib.fetchStoreApi('/goods/detail', data);
    if (result.resultFlag) {
      return result.goodsInfo;
    } else {
      throw Object.assign(new Error('Goods Detail Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsEdit = async ({ brandId, shopId, updateInfo }) => {
  try {
    const data = {
      brandId,
      shopId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/goods/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Edit Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsRobotynUpdate = async ({ shopId, brandId, goodsId, updateInfo }) => {
  try {
    const data = {
      shopId,
      brandId,
      goodsId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/goods/goods-robotyn-update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods RobotYn Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const stockUpdate = async ({ shopId, stockId, updateInfo }) => {
  try {
    const data = {
      shopId,
      stockId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/goods/stock-update', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Stock Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const stockReset = async ({ shopId, stockId, stockPid, updateInfo }) => {
  try {
    const data = {
      shopId,
      stockId,
      stockPid,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/goods/stock-reset', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Stock Reset Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const imageUpdate = async ({ brandId, goodsId, file }) => {
  try {
    const uploadData = new FormData();
    uploadData.append('brandId', brandId);
    uploadData.append('goodsId', goodsId);
    uploadData.append('uploadFile', file, file.name);

    const result = await apiLib.fetchStoreMultipartApi('/goods/image-update', uploadData);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Image Update Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsRemove = async ({ brandId, shopId, removeGoodsList }) => {
  try {
    const data = {
      brandId,
      shopId,
      removeGoodsList,
    };

    const result = await apiLib.fetchStoreApi('/goods/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Remove Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const subGoodsRemove = async ({ removedGoodsId }) => {
  try {
    const data = {
      removedGoodsId,
    };

    const result = await apiLib.fetchStoreApi('/goods/sub-goods-remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Sub Goods Remove Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const subGoodsAdd = async ({ brandId, goods, file }) => {
  try {
    const data = {
      brandId,
      goods,
      fileNm: file && file.name,
      fileType: file && file.type,
    };

    const result = await apiLib.fetchStoreApi('/goods/sub-goods-add', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('Presign Upload Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsReorder = async ({ updateInfoList }) => {
  try {
    const data = {
      updateInfoList,
    };

    const result = await apiLib.fetchStoreApi('/goods/reorder', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Reorder Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsCopy = async ({ brandId, shopId, copyGoodsList }) => {
  try {
    const data = {
      brandId,
      shopId,
      copyGoodsList,
    };

    const result = await apiLib.fetchStoreApi('/goods/copy', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Copy Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const goodsMove = async ({ brandId, shopId, moveGoodsList, moveCategoryId }) => {
  try {
    const data = {
      brandId,
      shopId,
      moveGoodsList,
      moveCategoryId,
    };

    const result = await apiLib.fetchStoreApi('/goods/move', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Goods Move Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};
