import React from 'react';
import styled from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';
import { BeatLoader, HashLoader } from 'react-spinners';

const Loading = ({ type = 'FADE', className, position, text, bacgroundColor }) => {
  return (
    <Background className={className} position={position} bacgroundColor={bacgroundColor}>
      {text && text}
      {
        {
          FADE: <FadeLoader color={'#41a1ea'} loading={true} height={25} width={8} margin={20} />,
          BEAT: <BeatLoader color={'#41a1ea'} loading={true} margin={10} size={20} />,
          HASH: <HashLoader color={'#41a1ea'} loading={true} size={100} />,
        }[type]
      }
    </Background>
  );
};

const Background = styled.div`
  position: ${({ position }) => (position ? position : 'relative')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  background: ${({ bacgroundColor }) => (bacgroundColor ? bacgroundColor : 'inherit')};
  font-weight: bold;
  font-size: 1.5625rem;
  color: #000000;
`;

export default Loading;
