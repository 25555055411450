import { Component } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

// import ErrorBackground from '../../assets/images/error/error-background.png';
import Button from '../components/Button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: '',
      showErrorMsgCnt: 0, // 20번 클릭 에러 메세지 보이기
    };

    this.plusShowErrorMsgCnt = this.plusShowErrorMsgCnt.bind(this);
  }

  componentDidCatch(error, info) {
    this.setState({
      error: true,
      errorMsg: error.message,
      showErrorMsgCnt: 0, // 20번 클릭 에러 메세지 보이기
    });
  }

  refresh() {
    window.location.reload();
  }

  goBack() {
    this.setState({
      error: false,
      errorMsg: '',
      showErrorMsgCnt: 0,
    });
  }

  plusShowErrorMsgCnt() {
    this.setState(state => ({
      ...state,
      showErrorMsgCnt: state.showErrorMsgCnt + 1,
    }));
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorComponent
          showErrorMsgCnt={this.state.showErrorMsgCnt}
          errorMsg={this.state.errorMsg}
          plusShowErrorMsgCnt={this.plusShowErrorMsgCnt}
          goBack={this.goBack.bind(this)}
          refresh={this.refresh.bind(this)}
        />
      );
    }
    return this.props.children;
  }
}

const ErrorComponent = ({ refresh, goBack, plusShowErrorMsgCnt, showErrorMsgCnt, errorMsg }) => {
  const navigate = useNavigate();

  const handleGoback = () => {
    navigate(-1);
    goBack();
  };

  return (
    <Error>
      <span className="comment-service-not">
        <span onClick={() => plusShowErrorMsgCnt()}>서비스</span>에 접속할 수 없습니다.
      </span>
      <span className="comment-sorry">
        페이지를 로드하는 도중에 오류가 발생하였습니다.
        <br />
        서비스 이용에 불편을 드려 죄송합니다.
      </span>
      <div className="error-msg">{showErrorMsgCnt > 20 && errorMsg}</div>
      <div className="button-area">
        <StyledButton color="black" outline onClick={() => handleGoback()}>
          뒤로가기
        </StyledButton>
        <StyledButton color="black" onClick={() => refresh()}>
          새로고침
        </StyledButton>
      </div>
    </Error>
  );
};

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-image: url(${({ errorBackground }) => errorBackground}); */
  font-size: 40px;

  .comment-service-not {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
  }

  .comment-sorry {
    font-weight: normal;
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  .error-msg {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  .button-area {
    margin-top: 30px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  line-height: 20px;

  & + & {
    margin-left: 20px;
  }
`;

export default ErrorBoundary;
