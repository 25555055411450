import {
    createSlice,
    createDraftSafeSelector,
    PayloadAction,
  } from '@reduxjs/toolkit';

  interface SpaceInfo {
    spaceNm: string;
    spaceId: string;
    spaceType: string;
  }

  interface SpaceState {
    spaceList: Array<SpaceInfo>;
    actionResult: string;
    isLoading: boolean;
    error: string | null;
  }

  const spaceInitialState: SpaceState = {
    spaceList: [],
    actionResult: '',
    isLoading: false,
    error: null,
  };

  const reducers = {
    initSpaceState: (state: SpaceState, {payload}: PayloadAction<SpaceState>) => {
      state.spaceList = [];
    },

    spaceList: (state: SpaceState, { payload }: PayloadAction<SpaceState>) => {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    spaceListSuccess: (
      state: SpaceState,
      { payload }: PayloadAction<SpaceState>,
    ) => {
      state.spaceList = payload.spaceList;
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    spaceListFailure: (state: SpaceState, action: PayloadAction<string>) => {
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = action.payload;
    },
    actionResultClear: (state: AuthState) => {
      state.actionResult = '';
    },
  };

  const slice = createSlice({
    name: 'space',
    initialState: spaceInitialState,
    reducers: reducers,
  });

  const selectSpaceList = createDraftSafeSelector(
    (state: SpaceState) => state.spaceList,
      spaceList => spaceList,
  );

  const selectStatus = createDraftSafeSelector(
    (state: SpaceState) => state.actionResult,
    (state: SpaceState) => state.isLoading,
    (state: SpaceState) => state.error,
    (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
  );

  export const spaceSelector = {
    spaceList: state => selectSpaceList(state[SPACE]),
    status: state => selectStatus(state[SPACE]),
  };

  export const SPACE = slice.name;
  export const spaceReducer = slice.reducer;
  export const spaceAction = slice.actions;
