import * as apiLib from './api';

export const list = async ({ shopId, orderStartDt, orderEndDt, evaluatedKey }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      orderEndDt,
      evaluatedKey
    };

    const result = await apiLib.fetchStoreApi('/order/list', data);
    if (result.resultFlag) {
      return result.orderList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const listPage = async (data) => {
  try {
    const result = await apiLib.fetchStoreApi('/order/list', data);

    if (result.resultFlag) {
      return result.orderList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const updateOrderState = async ({ orderIdList, updateInfo }) => {
  try {
    const data = {
      orderIdList,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/order/update-order-state', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('update order state error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const openList = async ({ shopId, orderStartDt, orderEndDt }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      orderEndDt,
    };

    const result = await apiLib.fetchStoreApi('/order/open-list', data);
    if (result.resultFlag) {
      return result.orderList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saleOrderList = async ({ shopId, orderIdList, billType }) => {
  try {
    const data = {
      shopId,
      orderIdList,
      billType,
    };

    const result = await apiLib.fetchStoreApi('/order/sale-list', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const orderCancel = async ({ shopId, orderId, deviceId }) => {
  try {
    const data = {
      shopId,
      orderId,
      deviceId,
    };

    const result = await apiLib.fetchStoreApi('/order/order-cancel', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
