import { all, fork, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import dayjs from 'dayjs';

import { salesReportAction, salesReportSelector} from './salesReportSlice';

import * as salesReportApiLib from '../lib/salesReportApi';

function* orderList({ payload }) {
  try {
    if (payload.CANCEL) { // 요청취소
      yield put(salesReportAction.orderListCancel());
    } else {
      const { orderStartDt, orderEndDt } = yield select(salesReportSelector.filterOrderDt);
      const shopId = payload.shopId;

      const dataOrderStartDt = Number(dayjs(payload.orderStartDt !== undefined ? payload.orderStartDt : orderStartDt).format('YYYYMMDD'));
      const dataOrderEndDt = Number(dayjs(payload.orderEndDt !== undefined ? payload.orderEndDt : orderEndDt).format('YYYYMMDD'));

      let dataList = [];

      let searchDay = dataOrderEndDt;
      while(searchDay >= dataOrderStartDt) {
        let strSearchDay = String(searchDay);

        const data = {
          shopId,
          orderStartDt: dayjs(strSearchDay).startOf('day').toISOString(),
          orderEndDt:  dayjs(strSearchDay).endOf('day').toISOString(),
        };

        dataList.push(data);

        searchDay = Number(dayjs(strSearchDay).subtract(1,'day').format('YYYYMMDD'));
      };

      const callList = dataList.map(data => {
        const callApi = call(salesReportApiLib.orderlist, data);
        return callApi;
      });

      const orderList = yield all([...callList]); //call 메서드 병렬로 실행

      // const { orderStartDt, orderEndDt } = yield select(salesReportSelector.filterOrderDt);
      // const shopId = payload.shopId;

      // const dataOrderStartDt = Number(dayjs(payload.orderStartDt !== undefined ? payload.orderStartDt : orderStartDt).format('YYYYMMDD'));
      // const dataOrderEndDt = Number(dayjs(payload.orderEndDt !== undefined ? payload.orderEndDt : orderEndDt).format('YYYYMMDD'));

      // let orderList = [];

      // let searchDay = dataOrderEndDt;
      // while(searchDay >= dataOrderStartDt) {
      //   let strSearchDay = String(searchDay);

      //   const data = {
      //     shopId,
      //     orderStartDt: dayjs(strSearchDay).startOf('day').toISOString(),
      //     orderEndDt:  dayjs(strSearchDay).endOf('day').toISOString(),
      //   };

      //   const dayOrderList = yield call(salesReportApiLib.orderlist, data);

      //   orderList.push(...dayOrderList);

            //   searchDay = Number(dayjs(strSearchDay).subtract(1,'day').format('YYYYMMDD'));
            // };
            const filterOrderList = orderList.flat().filter(item => item?.delYn !== 'Y' && item?.useYn !== 'N');
            yield put(salesReportAction.orderListSuccess({ orderList: filterOrderList }));
    }
  } catch (err) {
    console.log(`orderList Saga Err`, err);
    yield put(salesReportAction.orderListFailure('Order List Error'));
  }
}

function* limitOrderList({ payload }) {
  try {
    const { orderStartDt, orderEndDt } = yield select(salesReportSelector.filterOrderDt);
    const { curPage, rowsPerPage, endKey } = yield select(salesReportSelector.pageInfo);
    const data = {
      shopId: payload.shopId,
      orderStartDt: payload.orderStartDt !== undefined ? payload.orderStartDt : orderStartDt,
      orderEndDt: payload.orderEndDt !== undefined ? payload.orderEndDt : orderEndDt,
      startKey: curPage === 1 ? null : endKey,
      limit: 100,
    }

    const { limitOrderList, lastKey } = yield call(salesReportApiLib.limitOrderList, data);

    yield put(salesReportAction.limitOrderListSuccess({ limitOrderList, lastKey }));
  } catch (err) {
    console.log(`orderList Saga Err`, err);
    yield put(salesReportAction.limitOrderListFailure('Order List Error'));
  }
}

function* monthLogList({ payload }) {
  try {
    const monthLogList = yield call(salesReportApiLib.monthLogList, payload);
    yield put(salesReportAction.monthLogListSuccess({ monthLogList }));
  } catch (err) {
    yield put(salesReportAction.monthLogListFailure('monthLogList List Error'));
  }
}

function* dailyLogList({ payload }) {
  try {
    const { startDt, endDt } = yield select(salesReportSelector.filterInfo);
    const data = {
      shopId: payload.shopId,
      startDt: payload.startDt !== undefined ? payload.startDt : startDt,
      endDt: payload.endDt !== undefined ? payload.endDt : endDt,
    }

    const dailyLogList = yield call(salesReportApiLib.dailyLogList, data);
    yield put(salesReportAction.dailyLogListSuccess({ dailyLogList }));
  } catch (err) {
    yield put(salesReportAction.dailyLogListFailure('salesDailyLogList List Error'));
  }
}

function* analysisLogList({ payload: { shopId } }) {
  try {
    const { startDt, endDt } = yield select(salesReportSelector.filterInfo);
    const data = {
      shopId: shopId,
      startDt: startDt,
      endDt: endDt,
    };

    const analysisLogList = yield call(salesReportApiLib.analysisList, data);
    yield put(salesReportAction.analysisLogListSuccess({ analysisLogList }));
  } catch (err) {
    yield put(salesReportAction.analysisLogListFailure('salesAnalysisLogList List Error'));
  }
};

function* openLogList({ payload: { shopId } }) {
  try {
    const { startDt, endDt } = yield select(salesReportSelector.filterInfo);
    const data = {
      shopId: shopId,
      startDt: startDt,
      endDt: endDt,
    };

    const openLogList = yield call(salesReportApiLib.openLogList, data);
    yield put(salesReportAction.openLogListSuccess({ openLogList }));
  } catch (err) {
    yield put(salesReportAction.openLogListFailure('OpenLog List Error'));
  }
}


export function* watchOrderList() {
  yield takeLatest(salesReportAction.orderList, orderList);
}

export function* watchLimitOrderList() {
  yield takeEvery(salesReportAction.limitOrderList, limitOrderList);
}

export function* watchMonthLogList() {
  yield takeEvery(salesReportAction.monthLogList, monthLogList);
}

export function* watchDailyLogList() {
  yield takeEvery(salesReportAction.dailyLogList, dailyLogList);
}

export function* watchAnalysisLogList() {
  yield takeEvery(salesReportAction.analysisLogList, analysisLogList);
}

export function* watchOpenLogList() {
  yield takeEvery(salesReportAction.openLogList, openLogList);
}

function* rootSaga() {
  yield all([
    fork(watchOrderList),
    fork(watchLimitOrderList),
    fork(watchMonthLogList),
    fork(watchDailyLogList),
    fork(watchAnalysisLogList),
    fork(watchOpenLogList),
  ]);
}

export default rootSaga;