import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ShopPayload {
  shopId: string;
  shopNm: string;
  accountId: string;
  pricing: string;
  licenseSeat: string;
}

interface MemberPayload {
  shopId: string;
  accountId: string;
  accountEmail: string;
  permissionType: string;
}

interface ImagePayload {
  shopId: string;
  file: Array<any>;
}

interface ShopInfo {
  shopId: string;
  shopNm: string;
}

interface MemberInfo {
  shopId: string;
  permissionType: string;
  permissionNm: string;
  regDt: string;
}

interface AccountInfo {
  shopId: string;
  accountId: string;
  accountNm: string;
  accountEmail: string;
  imagePath: string;
  imageSize: string;
  lastLoginDt: string;
}

interface PlayerInfo {
  path: string;
  version: string;
}

interface ShopState {
  permissionInfo: MemberInfo;
  shopInfo: ShopInfo;
  shopList: Array<ShopInfo>;
  memberInfo: MemberInfo;
  memberList: Array<MemberInfo>;
  accountList: Array<AccountInfo>;
  playerInfo: PlayerInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const shopInitialState: ShopState = {
  permissionInfo: {},
  shopInfo: {},
  shopList: [],
  memberInfo: {},
  memberList: [],
  accountList: [],
  playerInfo: {},
  actionResult: '',
  isLoading: false,
  error: null,
  sameFlag: '',
};

const reducers = {
  shopInfo: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopInfoSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopInfoFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopSameChk: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'SAMECHK_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopSameChkSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.result.shopInfo;
    state.sameFlag = payload.result.sameFlag;
    state.actionResult = 'SAMECHK_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopSameChkFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'SAMECHK_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  shopList: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopListSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.permissionInfo = payload.permissionInfo;
    state.shopInfo = payload.shopInfo;
    state.shopList = payload.shopList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopListFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopAdd: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopAddSuccess: (state: ShopState) => {
    state.actionResult = 'ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopAddFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopEdit: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopEditSuccess: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.shopInfo.shopNm = payload.shopNm;
    for (let shop of state.shopList) {
      if (shop.shopId === payload.shopId) {
        shop.shopNm = payload.shopNm;
      }
    }
    state.actionResult = 'EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopEditFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopRemove: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopRemoveSuccess: (state: ShopState) => {
    state.actionResult = 'REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopRemoveFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopChange: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'CHANGE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopChangeSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.permissionInfo = payload.permissionInfo;
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'CHANGE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopChangeFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'CHANGE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopImageUpload: (state: ShopState, { payload }: PayloadAction<ImagePayload>) => {
    state.actionResult = 'UPLOAD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopImageUploadSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'UPLOAD_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopImageUploadFailure: (state: ShopState, { payload }: PayloadAction<String>) => {
    state.actionResult = 'UPLOAD_ERR';
    state.isLoading = false;
    state.error = payload;
  },
  memberList: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberListSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.memberList = payload.memberList;
    state.accountList = payload.accountList;
    state.actionResult = 'MEMBER_LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberListFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberDetail: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_DETAIL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberDetailSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.memberInfo = payload.memberInfo;
    state.actionResult = 'MEMBER_DETAIL_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberDetailFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_DETAIL_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberAdd: (state: ShopState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_ADD_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberAddSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_ADD_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberAddFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_ADD_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberEdit: (state: ShopState, { payload }: PayloadAction<MemberPayload>) => {
    state.actionResult = 'MEMBER_EDIT_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberEditSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_EDIT_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberEditFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_EDIT_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  memberRemove: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.actionResult = 'MEMBER_REMOVE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  memberRemoveSuccess: (state: ShopState) => {
    state.actionResult = 'MEMBER_REMOVE_OK';
    state.isLoading = false;
    state.error = null;
  },
  memberRemoveFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MEMBER_REMOVE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  playerInfo: (state: ShopState) => {
    state.actionResult = 'PLAYER_INFO_REQ';
    state.isLoading = true;
    state.error = null;
  },
  playerInfoSuccess: (state: ShopState, { payload }: PayloadAction<ShopPayload>) => {
    state.playerInfo = payload.playerInfo;
    state.actionResult = 'PLAYER_INFO_OK';
    state.isLoading = false;
    state.error = null;
  },
  playerInfoFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'PLAYER_INFO_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },

  shopTotalUpdate: (state: ShopState, { payload }: PayloadAction<OpenUpdatePayload>) => {
    state.shopInfo.openState = payload.openState;
    state.shopInfo.autoOpenState = payload.autoOpenState;
    state.shopInfo.autoOpenTime = payload.autoOpenTime;
    state.shopInfo.autoEndTime = payload.autoEndTime;
    state.shopInfo.autoWorkDay = payload.autoWorkDay;
    state.actionResult = 'TOTAL_OPEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopTotalUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'TOTAL_OPEN_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopTotalUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'TOTAL_OPEN_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopAutoOpenUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.autoOpenState = payload.autoOpenState;
    state.actionResult = 'AUTO_OPEN_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopAutoOpenUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'OPEN_AUTO_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopAutoOpenUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'OPEN_AUTO_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  shopDeliveryOrderCntUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.deliveryOrderCnt = payload.deliveryOrderCnt;
    state.actionResult = 'DELIVERY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopDeliveryOrderCntUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'DELIVERY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopDeliveryOrderCntUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'DELIVERY_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopRobotDeliveryOrderCntUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.robotDeliveryOrderCnt = payload.robotDeliveryOrderCnt;
    state.actionResult = 'ROBOT_DELIVERY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopRobotDeliveryOrderCntUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'ROBOT_DELIVERY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopRobotDeliveryOrderCntUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ROBOT_DELIVERY_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopRobotDeliveryTimeoutUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.robotDeliveryTimeout = payload.robotDeliveryTimeout;
    state.actionResult = 'ROBOT_DELIVERY_TIMEOUT_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopRobotDeliveryTimeoutUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'ROBOT_DELIVERY_TIMEOUT_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopRobotDeliveryTimeoutUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ROBOT_DELIVERY_TIMEOUT_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopPickupTypeListUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.pickupTypeList = payload.pickupTypeList;
    state.actionResult = 'PICKUP_TYPE_LIST_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopPickupTypeListUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'PICKUP_TYPE_LIST_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopPickupTypeListUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'PICKUP_TYPE_LIST_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  shopDeliveryZoneUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.deliveryZone = payload.deliveryZone;
    state.actionResult = 'DELIVERY_ZONE_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopDeliveryZoneUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'DELIVERY_ZONE_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopDeliveryZoneUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'DELIVERY_ZONE_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopRobotDeliveryZoneUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.robotDeliveryZone = payload.robotDeliveryZone;
    state.actionResult = 'ROBOT_DELIVERY_ZONE_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopRobotDeliveryZoneUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'ROBOT_DELIVERY_ZONE_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopRobotDeliveryZoneUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ROBOT_DELIVERY_ZONE_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  shopDeliveryTimeListUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.shopInfo.deliveryTime = payload.deliveryTime;
    state.actionResult = 'DELIVERY_TIME_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopDeliveryTimeListUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'DELIVERY_TIME_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopDeliveryTimeListUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'DELIVERY_TIME_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopAlarmUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.actionResult = 'ALARM_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },

  shopAlarmUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'ALARM_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopAlarmFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'ALARM_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopPickupLedUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.actionResult = 'PICKUP_LED_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopPickupLedUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'PICKUP_LED_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopPickupLedFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'PICKUP_LED_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  shopMenuSurveyUpdate: (state: ShopState, { payload }: PayloadAction<AutoOpenUpdatePayload>) => {
    state.actionResult = 'MENU_SURVEY_UPDATE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  shopMenuSurveyUpdateSuccess: (state: ShopState, { payload }: PayloadAction<ShopState>) => {
    state.shopInfo = payload.shopInfo;
    state.actionResult = 'MENU_SURVEY_UPDATE_OK';
    state.isLoading = false;
    state.error = null;
  },
  shopMenuSurveyUpdateFailure: (state: ShopState, action: PayloadAction<string>) => {
    state.actionResult = 'MENU_SURVEY_UPDATE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
};

const slice = createSlice({
  name: 'shop',
  initialState: shopInitialState,
  reducers: reducers,
});

const selectPermissionInfo = createDraftSafeSelector(
  (state: ShopState) => state.permissionInfo,
  permissionInfo => permissionInfo,
);

const selectShopInfo = createDraftSafeSelector(
  (state: ShopState) => state.shopInfo,
  shopInfo => shopInfo,
);

const selectShopList = createDraftSafeSelector(
  (state: ShopState) => state.shopList,
  shopList => shopList,
);

const selectPricingInfo = createDraftSafeSelector(
  (state: ShopState) => state.pricingInfo,
  pricingInfo => pricingInfo,
);

const selectMemberInfo = createDraftSafeSelector(
  (state: ShopState) => state.memberInfo,
  (state: ShopState) => state.accountList,
  (memberInfo, accountList) => {
    if (accountList && accountList.length > 0) {
      let accountFilter = accountList.filter(account => account.accountId === memberInfo.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        return { ...memberInfo, ...account };
      } else {
        return memberInfo;
      }
    } else {
      return memberInfo;
    }
  },
);

const selectMemberList = createDraftSafeSelector(
  (state: ShopState) => state.memberList,
  (state: ShopState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(account => account.accountId === member.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }
    return newMemberList;
  },
);

const selectLastLoginMemberList = createDraftSafeSelector(
  (state: ShopState) => state.memberList,
  (state: ShopState) => state.accountList,
  (memberList, accountList) => {
    let newMemberList = [];
    for (let member of memberList) {
      let accountFilter = accountList.filter(account => account.accountId === member.accountId);
      if (accountFilter.length > 0) {
        let account = accountFilter[0];
        newMemberList.push({ ...member, ...account });
      }
    }

    newMemberList.sort(function (lhs, rhs) {
      const lhsDate = dayjs(lhs.lastLoginDt);
      const rhsDate = dayjs(rhs.lastLoginDt);

      return rhsDate.diff(lhsDate);
    });

    newMemberList.splice(3);

    return newMemberList;
  },
);

const selectPlayerInfo = createDraftSafeSelector(
  (state: ShopState) => state.playerInfo,
  playerInfo => playerInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: ShopState) => state.actionResult,
  (state: ShopState) => state.isLoading,
  (state: ShopState) => state.error,
  (state: ShopState) => state.sameFlag,
  (actionResult, isLoading, error, sameFlag) => ({ actionResult, isLoading, error, sameFlag }),
);

export const shopSelector = {
  permissionInfo: state => selectPermissionInfo(state[SHOP]),
  shopInfo: state => selectShopInfo(state[SHOP]),
  shopList: state => selectShopList(state[SHOP]),
  pricingInfo: state => selectPricingInfo(state[SHOP]),
  memberInfo: state => selectMemberInfo(state[SHOP]),
  memberList: state => selectMemberList(state[SHOP]),
  lastLoginMemberList: state => selectLastLoginMemberList(state[SHOP]),
  playerInfo: state => selectPlayerInfo(state[SHOP]),
  status: state => selectStatus(state[SHOP]),
};

export const SHOP = slice.name;
export const shopReducer = slice.reducer;
export const shopAction = slice.actions;
