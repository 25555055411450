import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface OrderPayload {
  orderId: string;
  orderStartDt: string;
  orderEndDt: string;
}

interface OrderInfo {
  orderId: string;
  orderDt: string;
}

interface FilterInfo {
  startDt: string;
  endDt: string;
  mainCategory: string;
  subCategory: string;
}

interface OrderState {
  orderInfo: OrderInfo;
  orderList: Array<OrderInfo>;
  orderFilterList: Array<OrderInfo>;
  filterInfo: FilterInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
  orderEndInfo : OrderEndInfo;
  orderAnalysisInfo : orderAnalysisInfo;
}

const orderInitialState: OrderState = {
  orderInfo: {},
  orderList: [],
  orderFilterList: [],
  selectedOrderId: '',
  selectedOrderIdList: [],
  loadingOrderIdList: [],
  filterInfo: { orderSearch: { type: 'all', value: '' } },
  actionResult: '',
  isLoading: false,
  error: null,
  payloadOrderDt: { orderStartDt: '', orderEndDt: '' },
  orderEndInfo : {shopId : '', logDt : '', totalAmt :'', totalEnr : '', totalReturnAmt : '', totalCnt :'', managerId :  '', newFlag : '', beforeDaily : {}},
  orderAnalysisInfo : {shopId : '', logDt : '', categoryInfoList : {}, goodsInfoList : {}, newFlag : '', beforeDaily : {}},
};

const reducers = {
  orderFilter: (state: OrderState, { payload: { key, value } }: PayloadAction<OrderState>) => {
    state.filterInfo[key] = value;
  },
  actionResultClear: (state: AuthState) => {
    state.actionResult = '';
  },
  initOrderState: (state: OrderState, {payload}: PayloadAction<OrderState>) => {
    console.log('order init 실행');
    state.orderInfo = {};
    state.orderList = [];
    state.orderFilterList = [];
    state.selectedOrderId = '';
    state.selectedOrderIdList = [];
    state.loadingOrderIdList = [];
    state.actionResult = '';
    state.filterInfo = { orderSearch: { type: 'all', value: '' } };
    state.orderEndInfo = {shopId : '' , logDt : '', totalAmt :'', totalEnr : '', totalReturnAmt : '', totalCnt :'', managerId :  '', newFlag : '', beforeDaily : {}};
    state.orderAnalysisInfo =  {shopId : '', logDt : '', categoryInfoList : {}, goodsInfoList : {}, newFlag : '', beforeDaily : {}};
  },
  orderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderListPage: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  orderFilterList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.orderFilterList = payload;
  },
  selectOrder: (state: OrderState, { payload }: PayloadAction<string>) => {
    state.selectedOrderId = payload.orderId;
  },
  setSelectedOrderIdList: (state: OrderState, { payload }: PayloadAction<string>) => {
    state.selectedOrderIdList.push(payload.orderId);
  },
  removeSelectedOrderIdList: (state: OrderState, { payload }: PayloadAction<string>) => {
    state.selectedOrderIdList = state.selectedOrderIdList.filter(selectedOrderId => selectedOrderId !== payload.orderId);
  },
  initSelectedOrderIdList: (state: OrderState, { payload }: PayloadAction<string>) => {
    state.selectedOrderIdList = [];
  },
  updateOrderState: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'UPDATE_ORDER_REQ';
    for (const orderId of payload.orderIdList) {
      if (!state.loadingOrderIdList.find(loadingOrderId => loadingOrderId === orderId)) {
        state.loadingOrderIdList.push(orderId);
      }
    }
    state.isLoading = true;
    state.error = null;
  },
  updateOrderStateSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'UPDATE_ORDER_OK';
    // const orderIndex = state.orderList.findIndex(order => order.orderId === payload.orderId);
    // state.orderList[orderIndex].billType = payload.updateInfo.billType;

    for (const orderId of payload.orderIdList) {
      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = null;
  },
  updateOrderStateFailure: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'UPDATE_ORDER_ERR';
    for (const orderId of payload.orderIdList) {
      const index = state.loadingOrderIdList.findIndex(loadingOrderId => loadingOrderId === orderId);
      if (index > -1) {
        state.loadingOrderIdList.splice(index, 1);
      }
    }
    state.isLoading = false;
    state.error = payload.error;
  },
  orderOpenList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'LIST_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderOpenListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.orderList = payload.orderList;
    state.actionResult = 'LIST_OK';
    state.isLoading = false;
    state.error = null;
  },
  orderOpenListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'LIST_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },

  saleOrderList: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'SALE_REQ';
    state.isLoading = true;
    state.error = null;
  },
  saleOrderListSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'SALE_OK';
    state.isLoading = false;
    state.error = null;
  },
  saleOrderListFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'SALE_ERR';
    state.isLoading = false;
    state.error = action.payload;
  },
  orderCancel: (state: OrderState, { payload }: PayloadAction<OrderPayload>) => {
    state.actionResult = 'ORDER_CANCEL_REQ';
    state.isLoading = true;
    state.error = null;
  },
  orderCancelSuccess: (state: OrderState, { payload }: PayloadAction<OrderState>) => {
    state.actionResult = 'ORDER_CANCEL_OK';
    state.isLoading = false;
    state.error = null;
    state.orderList = payload.orderList;
  },
  orderCancelFailure: (state: OrderState, action: PayloadAction<string>) => {
    state.actionResult = 'ORDER_CANCEL_REQ';
    state.isLoading = false;
    state.error = action.payload;
  },
  orderEndInfo: (state : OrderEndInfo, {payload} :PayloadAction<OrderState> ) => {
    state.orderEndInfo = payload.orderEndInfo;
  },
  orderAnalysisInfo: (state : orderAnalysisInfo, {payload} :PayloadAction<OrderState> ) => {
    state.orderAnalysisInfo = payload.orderAnalysisInfo;
    state.actionResult = 'INFO_OK'
  },

};

const slice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: reducers,
});

const selectOrderList = createDraftSafeSelector(
  (state: OrderState) => state.orderList,
  orderList => orderList,
);

const selectOrderFilterList = createDraftSafeSelector(
    (state: OrderState) => state.orderFilterList,
    orderFilterList => orderFilterList,
);

const selectOrderFilter = createDraftSafeSelector(
  (state: OrderState) => state.filterInfo,
  filterInfo => filterInfo,
);

const selectFilteredOrderList = createDraftSafeSelector(
  (state: any) => state.orderList,
  (state: any) => state.filterInfo,
  (orderList, filterInfo) => {
    // 정렬
    let sortOrderList = [...orderList].sort((l, r) => l.regDt === r.regDt ? 0 : l.regDt > r.regDt ? -1 : 1);

    // 검색창 필터링
    let filterdOrderList = sortOrderList;
    if (filterInfo.orderSearch.value) {
      if (filterInfo.orderSearch.type === 'waitNo') {
        filterdOrderList = filterdOrderList.filter(order => order.waitNo === Number(filterInfo.orderSearch.value));
      } else if (filterInfo.orderSearch.type === 'customerNm') {
        filterdOrderList = filterdOrderList.filter(order =>
          order.paymentList[0]?.customerNm?.toLowerCase().indexOf(filterInfo.orderSearch.value?.toLowerCase()) >= 0
        );
      } else {
        filterdOrderList = filterdOrderList.filter(order =>
          order.waitNo === Number(filterInfo.orderSearch.value) || order.paymentList[0]?.customerNm?.toLowerCase().indexOf(filterInfo.orderSearch.value?.toLowerCase()) >= 0
        );
      }
    }

    return filterdOrderList;
  }
);

const selectSelectedOrder = createDraftSafeSelector(
  (state: OrderState) => state.orderList,
  (state: OrderState) => state.selectedOrderId,
  (orderList, selectedOrderId) => {
    if (orderList.length > 0) {
      return orderList.find(order => order.orderId === selectedOrderId);
    } else {
      return undefined;
    }
  },
);
const selectSelectedOrderIdList = createDraftSafeSelector(
  (state: OrderState) => state.selectedOrderIdList,
  selectedOrderIdList => selectedOrderIdList
);

const selectLoadingOrderIdList = createDraftSafeSelector(
  (state: OrderState) => state.loadingOrderIdList,
  (loadingOrderIdList) => (loadingOrderIdList),
);

const selectPayloadOrderDt = createDraftSafeSelector(
  (state: OrderState) => state.payloadOrderDt,
  payloadOrderDt => payloadOrderDt,
);

const selectStatus = createDraftSafeSelector(
  (state: OrderState) => state.actionResult,
  (state: OrderState) => state.isLoading,
  (state: OrderState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

const selectOrderEndInfo = createDraftSafeSelector(
  (state: OrderState) => state.orderEndInfo,
  orderEndInfo => orderEndInfo,
);

const selecctOrderAnalysisInfo = createDraftSafeSelector(
  (state: OrderState) => state.orderAnalysisInfo,
  orderAnalysisInfo => orderAnalysisInfo,
);


export const orderSelector = {
  orderList: state => selectOrderList(state[ORDER]),
  orderFilterList: state => selectOrderFilterList(state[ORDER]),
  orderFilter: state => selectOrderFilter(state[ORDER]),
  filteredOrderList: state => selectFilteredOrderList(state[ORDER]),
  selectedOrder: state => selectSelectedOrder(state[ORDER]),
  selectedOrderIdList: state => selectSelectedOrderIdList(state[ORDER]),
  loadingOrderIdList: state => selectLoadingOrderIdList(state[ORDER]),
  payloadOrderDt:  state => selectPayloadOrderDt(state[ORDER]),
  status: state => selectStatus(state[ORDER]),
  orderEndInfo : state => selectOrderEndInfo(state[ORDER]),
  orderAnalysisInfo : state => selecctOrderAnalysisInfo(state[ORDER]),
};

export const ORDER = slice.name;
export const orderReducer = slice.reducer;
export const orderAction = slice.actions;
