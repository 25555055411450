import { baseApi } from './baseApi';

const surveyMenuApi = baseApi.injectEndpoints({
  endpoints: build => ({
    surveyMenuDailyList: build.query({
      query: data => ({ url: '/survey-menu/daily-list', data }),
      transformResponse: response => {
        const surveyMenuDailyList = response.data.map(ele => ({
          ...ele,
          totalCnt: ele.totalCnt || 0,
          avgRatingValue: ele.avgRatingValue || 0,
          totalGoodCnt: ele.totalGoodCnt || 0,
          totalGoodRate: ((ele.totalGoodCnt / ele.totalCnt) * 100).toFixed(1),
          totalNormalCnt: ele.totalNormalCnt || 0,
          totalNormalRate: ((ele.totalNormalCnt / ele.totalCnt) * 100).toFixed(1),
          totalBadCnt: ele.totalBadCnt || 0,
          totalBadRate: ((ele.totalBadCnt / ele.totalCnt) * 100).toFixed(1),
        }));
        return surveyMenuDailyList;
      },
      providesTags: (result, error, payload) => [{ type: 'SURVEY-MENU-DAILY', id: 'SURVEY-MENU-DAILY-LIST' }],
      keepUnusedDataFor: 0,
    }),
    surveyMenuList: build.query({
      query: data => ({ url: '/survey-menu/list', data }),
      transformResponse: response => {
        const customerSurveyDailyLogList =
          response.data.map(ele => ({
            ...ele,
            ratingType: getRattingType(ele.ratingValue),
          })) || [];

        return customerSurveyDailyLogList;
      },
      providesTags: (result, error, payload) => [{ type: 'SURVEY-MENU', id: 'SURVEY-MENU-LIST' }],
      keepUnusedDataFor: 0,
    }),
    surveyMenuRank: build.query({
      query: data => ({ url: '/survey-menu/rank', data }),
      transformResponse: response => {
        const customerSurveyDailyLogList =
          response.data.map(ele => ({
            ...ele,
            ratingType: getRattingType(ele.ratingValue),
          })) || [];

        return customerSurveyDailyLogList;
      },
      providesTags: (result, error, payload) => [{ type: 'SURVEY-MENU', id: 'SURVEY-MENU-RANK' }],
      keepUnusedDataFor: 0,
    }),
    surveyMenuRecount: build.mutation({
      query: data => ({ url: '/survey-menu/recount', data }),
      invalidatesTags: (result, error, payload) => [{ type: 'SURVEY-MENU-DAILY', id: 'SURVEY-MENU-DAILY-LIST' }],
    }),
  }),
  overrideExisting: false,
});

const getRattingType = value => {
  if (value === 1) {
    return '불만족';
  } else if (value === 3) {
    return '보통';
  } else if (value === 5) {
    return '만족';
  } else {
    return '';
  }
};

export const { endpoints, useSurveyMenuDailyListQuery, useSurveyMenuListQuery, useSurveyMenuRecountMutation, useSurveyMenuRankQuery } = surveyMenuApi;
