import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import { watchExcelFileSave } from './excelFileSaga';
import { MANUAL_NETWORK_STATE, MANUAL_PROGRESS_STATE } from '../constants/types';


interface ExcelFileSavePayload {
  filePath: string;
  excelFileId: string;
}

interface ExcelProcessPayload {
  shopId: string;
  excelFileId: string;
  excelDataList: Array<any>;
}

interface ExcelDeletePayload {
  shopId: string;
  excelFileId: string;
  excelFileNm: string;
}

interface ExcelFileState {
  actionResult: string;
  isLoading: boolean;
  error: string | null;
};

const excelFileInitialState: ExcelFileState = {
  actionResult: '',
  isLoading: false,
  error: null,
};

const reducers = {
  initExcelFileInfo: (
      state: ExcelFileState,
      { payload }: PayloadAction<ExcelFileSavePayload>,
  ) => {
    state.actionResult = '';
  },
  excelFileSave: (state: ExcelFileState, { payload }: PayloadAction<ExcelFileSavePayload>) => {
    state.actionResult = MANUAL_NETWORK_STATE.FILE_SAVING;
    state.isLoading = true;
    state.error = null;
  },
  excelFileSaveSuccess: (state: ExcelFileState, { payload }: PayloadAction<ExcelFileState>) => {
    state.actionResult = MANUAL_NETWORK_STATE.FILE_SAVE_OK;
    state.isLoading = false;
    state.error = null;
  },
  excelFileSaveFailure: (state: ExcelFileState, { payload }: PayloadAction<string>) => {
    // state.actionResult = MANUAL_NETWORK_STATE.FILE_SAVE_ERROR;
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_STOP;
    state.isLoading = false;
    state.error = payload;
  },
  excelFileSaveStop: (state: ExcelFileState) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_STOP;
  },
  excelMatching: (state: ExcelFileState, { payload }: PayloadAction<ExcelProcessPayload>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_MATCHING;
    state.isLoading = true;
    state.error = null;
  },
  excelMatchingSuccess: (state: ExcelFileState, { payload }: PayloadAction<ExcelFileState>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_MATCHING_OK;
    state.isLoading = false;
    state.error = null;
  },
  excelMatchingFailure: (state: ExcelFileState, { payload }: PayloadAction<string>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_MATCHING_ERROR;
    state.isLoading = false;
    state.error = payload;
  },
  excelCalculating: (state: ExcelFileState, { payload }: PayloadAction<ExcelProcessPayload>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_CALCULATING;
    state.isLoading = true;
    state.error = null;
  },
  excelCalculatingSuccess: (state: ExcelFileState, { payload }: PayloadAction<ExcelFileState>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_CALCULATING_OK;
    state.isLoading = false;
    state.error = null;
  },
  excelCalculatingFailure: (state: ExcelFileState, { payload }: PayloadAction<string>) => {
    state.actionResult = MANUAL_NETWORK_STATE.EXCEL_CALCULATING_ERROR;
    state.isLoading = false;
    state.error = payload;
  },
  excelProcessStop: (state: ExcelFileState) => {
    state.actionResult = MANUAL_PROGRESS_STATE.PROCESS_STOP;
    state.isLoading = false;
    state.error = null;
  },
  actionResultClear: (state: ExcelFileState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'excelFile',
  initialState: excelFileInitialState,
  reducers: reducers,
});

const selectStatus = createDraftSafeSelector(
  (state: ExcelFileState) => state.actionResult,
  (state: ExcelFileState) => state.isLoading,
  (state: ExcelFileState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const excelFileSelector = {
  status: state => selectStatus(state[EXCELFILE]),
};

export const EXCELFILE = slice.name;
export const excelFileReducer = slice.reducer;
export const excelFileAction = slice.actions;
