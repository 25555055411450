import * as apiLib from './api';

export const monthLogList = async ({ shopId, startDt, endDt }) => {
  try {
    const data = {
      shopId,
      startDt,
      endDt,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/month-list', data);
    if (result.resultFlag) {
      return result.salesMonthList;
    } else {
      throw Object.assign(new Error('sales-month list error'), { status: 401 });
    }
  } catch (err) {
      console.log(err);
    throw err;
  }
};

export const dailyLogList = async ({ shopId, startDt, endDt }) => {
  try {
    const data = {
      shopId,
      startDt,
      endDt,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/daily-list', data);
    if (result.resultFlag) {
      return result.salesDailyList;
    } else {
      throw Object.assign(new Error('sales-daily list error'), { status: 401 });
    }
  } catch (err) {
      console.log(err);
    throw err;
  }
};

export const analysisList = async ({ shopId, startDt, endDt }) => {

  try {
    const data = {
      shopId,
      startDt,
      endDt,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/analysis-list', data);
    if (result.resultFlag) {
      return result.salesAnalysisList;
    } else {
      throw Object.assign(new Error('sales-analysis list error'), { status: 401 });
    }
  } catch (err) {
      console.log(err);
    throw err;
  }
};

export const openLogList = async ({ shopId, startDt, endDt }) => {
  try {
    const data = {
      shopId,
      startDt,
      endDt,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/open-list', data);
    if (result.resultFlag) {
      return result.openLogList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const orderlist = async ({ shopId, orderStartDt, orderEndDt }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      orderEndDt,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/order-list', data);
    if (result.resultFlag) {
      return result.orderList;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const limitOrderList = async ({ shopId, orderStartDt, orderEndDt, startKey, endIndex, limit }) => {
  try {
    const data = {
      shopId,
      orderStartDt,
      orderEndDt,
      startKey,
      endIndex,
      limit,
    };

    const result = await apiLib.fetchStoreApi('/sales-report/order-limit-list', data);
    if (result.resultFlag) {
      return result;
    } else {
      throw Object.assign(new Error('order error'), { status: 401 });
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};