import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface CalendarAppDataState {
  calendarName: string;
  dateCheck: string;
  iCalendarUrlYn: string;
  iCalendarUrl: string;
}

interface WeatherAppDataState {
  location: string;
  degreeCheck: string;
  timeForecastYn: string;
  dayForecastYn: string;
}

interface TextAppDataState {
  fontSize: string;
  text: string;
  frameLinkYn: string;
}

interface FrameNoAppDataState {
  frameNoYn: string;
}

interface MediaDataState {
  file: Array<any>;
  animation: string;
  zoom: string;
  fitOption: string;
}

interface OverlayContentsState {
  playlistId: string;
  overlayId: string;
  contentsId: string;
  contentsType: string;
  contentsData: CalendarAppDataState | WeatherAppDataState | TextAppDataState | FrameNoAppDataState | MediaDataState;
  contentsFileList: Array<any>;
  editingMode: boolean;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const initialState: OverlayContentsState = {
  overlayContentsList: [],
  addFile: {},
  removeFile: {},
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'overlayContents',
  initialState,
  reducers: {
    stateClear: (state: OverlayContentsList, { payload }: PayloadAction<OverlayContentsList>) => {
      state.overlayContentsList = [];
      state.addFile = {};
      state.removeFile = {};
      state.actionResult = '';
      state.isLoading = false;
      state.error = '';
    },
    setOverlayContentsList(state: OverlayContentsState, { payload: { overlayContentsList } }: PayloadAction<OverlayContentsState>) {
      state.overlayContentsList = overlayContentsList;
    },
    actionResultClear: (state: OverlayContentsList) => {
      state.actionResult = '';
    },
    updateOverlayContents(state: OverlayContentsState, { payload: { key, index, updateKey, updateValue, overlayId } }: PayloadAction<OverlayContentsState>) {
      const idx = state.overlayContentsList.findIndex(contents => contents.overlayId === overlayId);
      state.overlayContentsList[idx].contentsData[key][index][updateKey] = updateValue;
    },
    updateOverlayContentsObj(state: OverlayContentsState, { payload: { key, value, overlayId } }: PayloadAction<OverlayContentsState>) {
      const idx = state.overlayContentsList.findIndex(contents => contents.overlayId === overlayId);
      state.overlayContentsList[idx].contentsData[key] = value;
    },
    addContentsFileList(state: OverlayContentsState, { payload: { contentsFileList, mediaList, overlayId } }: PayloadAction<OverlayContentsState>) {
      const idx = state.overlayContentsList.findIndex(contents => contents.overlayId === overlayId);
      state.overlayContentsList[idx].contentsFileList.push(...contentsFileList);
      state.overlayContentsList[idx].contentsData.mediaList.push(...mediaList);
    },
    updateContentsFileList(state: OverlayContentsState, { payload: { contentsFileList, mediaList, overlayId } }: PayloadAction<OverlayContentsState>) {
      const idx = state.overlayContentsList.findIndex(contents => contents.overlayId === overlayId);
      state.overlayContentsList[idx].contentsFileList = contentsFileList;
      state.overlayContentsList[idx].contentsData.mediaList = mediaList;
    },
    removeContentsFileList(state: OverlayContentsState, { payload: { index, overlayId } }: PayloadAction<OverlayContentsState>) {
      const idx = state.overlayContentsList.findIndex(contents => contents.overlayId === overlayId);
      state.overlayContentsList[idx].contentsFileList.splice(index, 1);
      state.overlayContentsList[idx].contentsData.mediaList.splice(index, 1);
    },
  },
});

const selectOverlayContentsList = createDraftSafeSelector(
  (state: OverlayState) => state.overlayContentsList,
  overlayContentsList => overlayContentsList,
);

const selectStatus = createDraftSafeSelector(
  (state: OverlayContentsState) => state.editingMode,
  (state: OverlayContentsState) => state.actionResult,
  (state: OverlayContentsState) => state.isLoading,
  (state: OverlayContentsState) => state.error,
  (editingMode, actionResult, isLoading, error) => ({ editingMode, actionResult, isLoading, error }),
);

export const overlayContentsSelector = {
  overlayContentsList: state => selectOverlayContentsList(state[OVERLAYCONTENTS]),
  status: state => selectStatus(state[OVERLAYCONTENTS]),
};

export const OVERLAYCONTENTS = slice.name;
export const overlayContentsReducer = slice.reducer;
export const overlayContentsAction = slice.actions;
