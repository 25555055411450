import {
  createSlice,
  createDraftSafeSelector,
  PayloadAction,
} from '@reduxjs/toolkit';

interface LayerDataState {
  layerId: string;
  layerOrder: number;
  width: number;
  height: number;
  x: number;
  y: number;
  title: number;
  color: string;
  baseYn: string;
  muteYn: string;
  frameLinkId: string;
  lockYn: string;
}

interface LayerState {
  layerList: Array<LayerDataState>;
  isLoading: boolean;
  error: string | null;
}

const initialState: LayerState = {
  layerList: [],
  actionResult: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    stateClear(state: LayerState, { payload }: PayloadAction<String>) {
      state.layerList = [];
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    setLayerList(state: LayerState, { payload: { layerList }}: PayloadAction<LayerState>) {
      state.layerList = layerList;
    },
  }
});

const selectLayerList = createDraftSafeSelector(
  (state: LayerState) => state.layerList,
  (layerList) => layerList,
);

const selectStatus = createDraftSafeSelector(
  (state: LayerState) => state.actionResult,
  (state: LayerState) => state.isLoading,
  (state: LayerState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const layerSelector = {
  layerList: state => selectLayerList(state[LAYER]),
  status: state => selectStatus(state[LAYER]),
};

export const LAYER = slice.name;
export const layerReducer = slice.reducer;
export const layerAction = slice.actions;
