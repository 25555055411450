import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface PlaylistInfoState {
  playlistId: string;
  placeId: string;
  folderId: string;
  defaultPlaylistYn: string;
  playlistNm: string;
  horizonResolution: string;
  verticalResolution: string;
  changeAnimation: string;
  frameRandomYn: string;
}

interface PlaylistInitState {
  playlistList: PlaylistInfoState[];
  playlistInfo: PlaylistInfoState;
  isLoading: boolean;
  error: string | null;
}

const playlistInitialState: PlaylistInitState = {
  playlistList: [],
  playlistInfo: {},
  actionResult: '',
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'playlist',
  initialState: playlistInitialState,
  reducers: {
    stateClear(state: PlaylistInitState) {
      state.playlistList = [];
      state.playlistInfo = {};
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    actionResultClear(state: PlaylistInitState) {
      state.actionResult = '';
    },
    playlistInfoClear(state: PlaylistInitState) {
      state.playlistInfo = {};
      state.actionResult = '';
      state.isLoading = false;
      state.error = null;
    },
    list(state: PlaylistInitState) {
      state.actionResult = 'LIST_REQ';
      state.isLoading = true;
      state.error = null;
    },
    listSuccess(state: PlaylistInitState, { payload }: PayloadAction<PlaylistInitState>) {
      state.playlistList = payload.playlistList;
      state.actionResult = 'LIST_OK';
      state.isLoading = false;
      state.error = null;
    },
    listFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'LIST_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    detail(state: PlaylistInitState) {
      state.actionResult = 'DETAIL_REQ';
      state.isLoading = true;
      state.error = null;
    },
    detailSuccess(state: PlaylistInitState, { payload }: PayloadAction<PlaylistInitState>) {
      state.playlistInfo = payload.playlistInfo;
      state.actionResult = 'DETAIL_OK';
      state.isLoading = false;
      state.error = null;
    },
    detailFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'DETAIL_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    add(state: PlaylistInitState) {
      state.actionResult = 'ADD_REQ';
      state.isLoading = true;
      state.error = null;
    },
    addSuccess(state: PlaylistInitState, { payload }: PayloadAction<PlaylistInitState>) {
      state.actionResult = 'ADD_OK';
      state.isLoading = false;
      state.error = null;
    },
    addFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'ADD_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    remove(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'REMOVE_REQ';
      state.isLoading = true;
      state.error = null;
    },
    removeSuccess(state: PlaylistInitState, { payload: playlistList }: PayloadAction<string>) {
      state.playlistList = playlistList;
      state.actionResult = 'REMOVE_OK';
      state.isLoading = false;
      state.error = null;
    },
    removeFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'REMOVE_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    edit(state: PlaylistInitState) {
      state.actionResult = 'EDIT_REQ';
      state.isLoading = true;
      state.error = null;
    },
    editSuccess(state: PlaylistInitState, { payload }: PayloadAction<PlaylistInfoState>) {
      state.actionResult = 'EDIT_OK';
      state.isLoading = false;
      state.error = null;
    },
    editFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.actionResult = 'EDIT_ERR';
      state.isLoading = false;
      state.error = payload;
    },
    removeFrame(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.error = null;
    },
    removeFrameSuccess(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = null;
    },
    removeFrameFailure(state: PlaylistInitState, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const selectPlaylistList = createDraftSafeSelector(
  (state: any) => state.playlistList,
  playlistList => playlistList,
);

const selectPlaylistInfo = createDraftSafeSelector(
  (state: PlaylistInitState) => state.playlistInfo,
  playlistInfo => playlistInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PlaylistState) => state.actionResult,
  (state: PlaylistState) => state.isLoading,
  (state: PlaylistState) => state.error,
  (actionResult, isLoading, error) => ({ actionResult, isLoading, error }),
);

export const playlistSelector = {
  playlistList: state => selectPlaylistList(state[PLAYLIST]),
  playlistInfo: state => selectPlaylistInfo(state[PLAYLIST]),
  status: state => selectStatus(state[PLAYLIST]),
};

export const PLAYLIST = slice.name;
export const playlistReducer = slice.reducer;
export const playlistAction = slice.actions;
