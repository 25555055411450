import * as apiLib from './api';

export const list = async ({ shopId, startDt, endDt }) => {

    try {
      const data = {
        shopId,
        startDt,
        endDt,
      };
  
      const result = await apiLib.fetchStoreApi('/sales-daily/list', data);
      if (result.resultFlag) {
        return result.salesDailyList;
      } else {
        throw Object.assign(new Error('sales-daily list error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };

  
  
export const logAdd = async ({ shopId,logDt,totalAmt,totalEnr,totalReturnAmt,totalCnt,managerId,deviceId,deviceNm,totalReturnCnt,ticketTotalAmt,ticketTotalEnr,ticketTotalReturnAmt,ticketTotalCnt,ticketTotalReturnCnt}) => {

    try {
      const data = {
        shopId,
        logDt,
        totalAmt,
        totalEnr,
        totalReturnAmt,
        totalCnt,
        totalReturnCnt,
        ticketTotalAmt,
        ticketTotalEnr,
        ticketTotalReturnAmt,
        ticketTotalCnt,
        ticketTotalReturnCnt,
        deviceId,
        deviceNm,
        // managerId
      };

      console.log('data', data);
  
      const result = await apiLib.fetchStoreApi('/sales-daily/log-add', data);
      if (result.resultFlag) {

        return result;
      } else {
        throw Object.assign(new Error('sales-daily log-add error'), { status: 401 });
      }
    } catch (err) {
        console.log(err);
      throw err;
    }
  };




