import * as apiLib from './api';

export const add = async ({
  deviceList,
  brandId,
  placeId,
  shopId,
  spaceId,
  playlistId,
  playNm,
}) => {
  try {
    const data = {
      deviceList,
      brandId,
      placeId,
      shopId,
      spaceId,
      playlistId,
      playNm,
    };
    const result = await apiLib.fetchStoreApi('/play/add', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('play add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async ({
  deviceList,
}) => {
  try {
    const data = {
      deviceList,
    };
    const result = await apiLib.fetchStoreApi('/play/remove', data);

    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('play remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};