import { createThumbnailFile, getDuration } from '../utils/file';
import * as apiLib from './api';

export const list = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/storage/list', data);
    if (result.resultFlag) {
      return result.fileList;
    } else {
      throw Object.assign(new Error(' list error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const add = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/storage/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error(' add error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const edit = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/storage/edit', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error(' edit error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const remove = async payload => {
  try {
    const data = { ...payload };

    const result = await apiLib.fetchStoreApi('/storage/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error(' remove error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const s3Upload = async ({ fileList, placeId, handleUploadProgress }) => {
  try {
    const resultFileList = [];
    const uploadThumbFileList = [];
    const uploadFileList = [];

    for (let index = 0; index < fileList.length; index++) {
      const file = fileList[index];
      const fileDuration = await getDuration(file.fileData);
      const thumbFile = await createThumbnailFile(file.fileData);

      let thumbFileId = '';
      let thumbFilePath = '';
      let thumbFileSize = '';
      let thumbFileHash = '';
      if (thumbFile) {
        thumbFileId = file.fileId + '-thumb';
        const thumbPresignPayload = {
          placeId,
          thumbFileId,
          thumbFileNm: thumbFile.name,
          thumbFileType: thumbFile.type,
          thumbFileSize: thumbFile.size,
        };
        const { url, thumbFileInfo } = await apiLib.fetchStoreApi('/storage/thumb-presign', thumbPresignPayload);

        uploadFileList.push({ url, file: thumbFile });

        // thumbFileHash = await getFileHash(thumbFile);
        thumbFilePath = thumbFileInfo.thumbFilePath;
        thumbFileSize = thumbFileInfo.thumbFileSize;
      }

      // const fileHash = await getFileHash(file.fileData);
      const fileHash = '';
      const presignPayload = {
        placeId,
        fileId: file.fileId,
        fileNm: file.fileNm,
        fileSize: file.fileSize,
        fileType: file.fileType,
        // fileOrder: file.fileOrder,
        fileHash,
        fileDuration,
        thumbFileId,
        thumbFilePath,
        thumbFileSize,
        thumbFileHash,
      };

      const { url, fileInfo } = await apiLib.fetchStoreApi('/storage/presign', presignPayload);
      uploadFileList.push({ url, file: file.fileData, onUploadProgress: handleUploadProgress, id: fileInfo.fileId });

      resultFileList.push(fileInfo);
    }

    if (fileList.length > 0) {
      await Promise.all([
        ...uploadThumbFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file)),
        ...uploadFileList.map(upload => apiLib.fetchUploadApi(upload.url, upload.file, upload.onUploadProgress, upload.id)),
      ]);
    }

    return resultFileList;
  } catch (err) {
    throw err;
  }
};

export const s3Remove = async ({ filePathList }) => {
  try {
    const data = {
      filePathList,
    };
    const result = await apiLib.fetchStoreApi('/storage/s3-remove', data);

    if (result.resultFlag) {
      return result;
    } else {
      throw new Error('s3-remove error');
    }
  } catch (err) {
    throw err;
  }
};

export const s3UploadAndS3Remove = async ({ fileList, addFileList, removeFileList, placeId, handleUploadProgress }) => {
  try {
    let returnFileList = fileList;

    if (removeFileList.length > 0) {
      const filePathList = [];
      for (const removeFile of removeFileList) {
        filePathList.push(removeFile.filePath);

        if (removeFile.thumbFilePath) {
          filePathList.push(removeFile.thumbFilePath);
        }
      }
      await remove({ filePathList });
    }

    if (addFileList.length > 0) {
      const resultFileList = await s3Upload({ fileList: addFileList, placeId, handleUploadProgress });
      let i = 0;
      returnFileList = returnFileList.map(file => {
        if (addFileList.find(addFile => addFile.fileId === file.fileId)) {
          const returnFile = resultFileList[i];
          i += 1;
          return returnFile;
        } else {
          return file;
        }
      });
    }

    return returnFileList;
  } catch (err) {
    throw err;
  }
};