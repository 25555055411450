import * as apiLib from './api';

export const optionList = async ({ brandId }) => {
  try {
    const data = {
      brandId,
    };

    const result = await apiLib.fetchStoreApi('/goods-option/list', data);
    if (result.resultFlag) {
      return result.optionList;
    } else {
      throw Object.assign(new Error('Option List Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const optionInfo = async ({ optionId }) => {
  try {
    const data = {
      optionId,
    };

    const result = await apiLib.fetchStoreApi('/goods-option/info', data);
    if (result.resultFlag) {
      return result.optionInfo;
    } else {
      throw Object.assign(new Error('Option Info Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const optionAdd = async ({ option }) => {
  try {
    const data = {
      ...option,
    };

    const result = await apiLib.fetchStoreApi('/goods-option/add', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Option Add Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const optionUpdate = async ({ optionId, updateInfo }) => {
  try {
    const data = {
      optionId,
      updateInfo,
    };

    const result = await apiLib.fetchStoreApi('/goods-option/update', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Option Update Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const optionRemove = async ({ optionIdList }) => {
  try {
    const data = {
      optionIdList,
    };

    const result = await apiLib.fetchStoreApi('/goods-option/remove', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Option Remove Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};

export const optionReorder = async ({ updateInfoList }) => {
  try {
    const data = {
      updateInfoList
    };

    const result = await apiLib.fetchStoreApi('/goods-option/reorder', data);
    if (result.resultFlag) {
      return true;
    } else {
      throw Object.assign(new Error('Option Reorder Api Error'), { status: 401 });
    }
  } catch (err) {
    throw err;
  }
};