import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IFileInfo {
  fileId: string;
}

interface StorageState {
  fileList: IFileInfo[];
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: StorageState = {
  fileList: [],
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.fileList = [];
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.fileList = payload.fileList;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  add: (state: StorageState, { payload }: PayloadAction<CsutomerStorageState>) => {
    state.isLoading = true;
    state.actionResult = 'ADD_REQ';
    state.error = '';
  },
  addSuccess: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_OK';
    state.error = '';
  },
  addFailure: (state: StorageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'ADD_ERR';
    state.error = action.payload;
  },
  edit: (state: StorageState, { payload }: PayloadAction<CsutomerStorageState>) => {
    state.isLoading = true;
    state.actionResult = 'EDIT_REQ';
    state.error = '';
  },
  editSuccess: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_OK';
    state.error = '';
  },
  editFailure: (state: StorageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'EDIT_ERR';
    state.error = action.payload;
  },
  remove: (state: StorageState, { payload }: PayloadAction<CsutomerStorageState>) => {
    state.isLoading = true;
    state.actionResult = 'REMOVE_REQ';
    state.error = '';
  },
  removeSuccess: (state: StorageState, { payload }: PayloadAction<StorageState>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_OK';
    state.error = '';
  },
  removeFailure: (state: StorageState, action: PayloadAction<string>) => {
    state.isLoading = false;
    state.actionResult = 'REMOVE_ERR';
    state.error = action.payload;
  },
  actionResultClear: (state: StorageState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'storage',
  initialState: InitialState,
  reducers: reducers,
});

const selectFileList = createDraftSafeSelector(
  (state: StorageState) => state.fileList,
  (fileList) => fileList
);

const selectStatus = createDraftSafeSelector(
  (state: StorageState) => state.actionResult,
  (state: StorageState) => state.isLoading,
  (state: StorageState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const storageSelector = {
  fileList: state => selectFileList(state[STORAGE]),
  status: state => selectStatus(state[STORAGE]),
};

export const STORAGE = slice.name;
export const storageReducer = slice.reducer;
export const storageAction = slice.actions;
