export const kakaoTumblerOptionIdData = [ //카카오전용 텀플러 옵션 생생후 등록
    '16278695654a41db40' //SQI 옵션코드
    ,'1710382644d13ae553' //SQI 옵션코드
    ,'17156627050df8d537'// 3F 옵션코드
    ,'1715662661d1037af0'// 5F 옵션코드
    ,'17156626086cc6ed28'// 5F B 옵션코드
]

/**
 * 0 채워넣기
 * @author Dongseek Son
 * @param {string|number} value - 값
 * @param {number} digit - 자릿수
 */
export const zeroPadding = (num, digit) => {
    var zero = '';
    for(var i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
};

/**
 * 반올림
 * @author Dongseek Son
 * @param {string|number} num - 값
 * @param {number} digit - 자릿수 default=0
 */
export const roundOff = (num, digit = 0) => {
    return Number.parseFloat(num).toFixed(digit);
};

export const isEmptyObject = (param) => {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

export const arraySlice = (data: [], size = 1) => {
  const arr = [];

  for(let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }

  return arr;
}

/**
 * 카카오 텀블러 옵션 리스트
 * @param obj
 */

export const countItemsWithTumblerOptionCount = (obj) => {
    const count = (obj.itemList).reduce((acc, item) => {
        const tumblerOption = item.optionList?.find(option =>
            kakaoTumblerOptionIdData.includes(option.optId));
        return acc + (tumblerOption ? item.itemQty : 0);
    }, 0);

    return count;
}
