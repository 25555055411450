import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';

interface IPackageInfo {
  packageId: string;
}

interface PackageState {
  packageList: IPackageInfo[];
  packageInfo: IPackageInfo;
  actionResult: string;
  isLoading: boolean;
  error: string | null;
}

const InitialState: PackageState = {
  packageList: [],
  packageInfo: {},
  spaceId: '',
  actionResult: '',
  isLoading: false,
  error: '',
};

const reducers = {
  stateClear: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.packageList = [];
    state.packageInfo = {};
    state.spaceId = '';
    state.actionResult = '';
    state.isLoading = false;
    state.error = '';
  },
  list: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.isLoading = true;
    state.actionResult = 'LIST_REQ';
    state.error = '';
  },
  listSuccess: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.packageList = payload.packageList;
    state.spaceId = payload.spaceId;
    state.isLoading = false;
    state.actionResult = 'LIST_OK';
    state.error = '';
  },
  listFailure: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.isLoading = false;
    state.actionResult = 'LIST_ERR';
    state.error = payload.error;
  },
  detail: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.isLoading = true;
    state.actionResult = 'DETAIL_REQ';
    state.error = '';
  },
  detailSuccess: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.packageInfo = payload.packageInfo;
    state.isLoading = false;
    state.actionResult = 'DETAIL_OK';
    state.error = '';
  },
  detailFailure: (state: PackageState, { payload }: PayloadAction<PackageState>) => {
    state.isLoading = false;
    state.actionResult = 'DETAIL_ERR';
    state.error = payload.error;
  },
  actionResultClear: (state: PackageState) => {
    state.actionResult = '';
  },
};

const slice = createSlice({
  name: 'package',
  initialState: InitialState,
  reducers: reducers,
});

const selectPackageList = createDraftSafeSelector(
  (state: PackageInitState) => state.packageList,
  (state: PackageInitState) => state.spaceId,
  (packageList, spaceId) => {
    const URL = window.location.href;
    let resultList = [];
    packageList?.forEach(obj => {
      if (obj?.spaceIdList !== undefined) {
        obj?.spaceIdList.forEach(space => {
          if (space.spaceId === spaceId) {
            resultList.push(obj);
          }
        });
      }
    });

    if (URL.indexOf('localhost') > 0 || URL.indexOf('eligaws') > 0) {
      return resultList;
    } else {
      return resultList.filter(packageInfo => packageInfo.devYn === 'N');
    }
  },
);

const selectPackageInfo = createDraftSafeSelector(
  (state: PackageState) => state.packageInfo,
  packageInfo => packageInfo,
);

const selectStatus = createDraftSafeSelector(
  (state: PackageState) => state.actionResult,
  (state: PackageState) => state.isLoading,
  (state: PackageState) => state.error,
  (actionResult, isLoading, error) => ({
    actionResult,
    isLoading,
    error,
  }),
);

export const packageSelector = {
  packageList: state => selectPackageList(state[PACKAGE]),
  packageInfo: state => selectPackageInfo(state[PACKAGE]),
  status: state => selectStatus(state[PACKAGE]),
};

export const PACKAGE = slice.name;
export const packageReducer = slice.reducer;
export const packageAction = slice.actions;
