import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { categoryGroupAction } from './categoryGroupSlice';

import * as categoryGroupApiLib from '../lib/categoryGroupApi';

function* list({ payload }) {
  try {
    const updateInfo = {
      ...payload,
      delYn: payload.delYn || 'N',
    };

    const categoryGroupList = yield call(categoryGroupApiLib.list, updateInfo);

    yield put(categoryGroupAction.listSuccess({ categoryGroupList }));
  } catch (error) {
    yield put(categoryGroupAction.listFailure({ error }));
  }
}

function* listEdit({ payload }) {
  try {
    yield call(categoryGroupApiLib.listEdit, payload);

    yield put(categoryGroupAction.listEditSuccess());
  } catch (error) {
    yield put(categoryGroupAction.listEditFailure({ error }));
  }
}

export function* watchList() {
  yield takeLatest(categoryGroupAction.list, list);
}

export function* watchListEdit() {
  yield takeLatest(categoryGroupAction.listEdit, listEdit);
}

function* rootSaga() {
  yield all([fork(watchList), fork(watchListEdit)]);
}

export default rootSaga;
