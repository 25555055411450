import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { reportAction } from './reportSlice';

import * as reportApiLib from '../lib/reportApi';

function* reportList({ payload }) {

    try {
      const reportList = yield call(reportApiLib.getDailyLogList, payload);
  
      yield put(reportAction.reportListSuccess({ reportList }));
    } catch (err) {
      yield put(reportAction.reportListFailure('report List Error'));
    }
  }
  
  export function* watchReportList() {
    yield takeLatest(reportAction.reportList, reportList);
  }
  
  function* rootSaga() {
    yield all([fork(watchReportList)]);
  }
  
  export default rootSaga;
  